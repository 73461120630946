import './css/custom.css'
import * as React from 'react';
import * as Models from './models/Models';
import { useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import authService from './components/api-authorization/AuthorizeService';
import { ApplicationPaths, LoginActions, LogoutActions } from './components/api-authorization/ApiAuthorizationConstants';
import { UserInfo } from './models/Models';

import ApiInterface from './components/ApiInterface';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Login } from './components/api-authorization/Login'
import { Logout } from './components/api-authorization/Logout'
import { Home } from './components/Home';
import FetchUsers from './components/FetchUsers';
import EditUser from './components/EditUser';
import FetchCompanies from './components/FetchCompanies';
import EditCompany from './components/EditCompany';
import FetchBundles from './components/FetchBundles';
import EditBundle from './components/EditBundle';
import FetchCategories from './components/FetchCategories';
import EditCategory from './components/EditCategory';
import FetchProducts from './components/FetchProducts';
import EditProduct from './components/EditProduct';
import FetchReviews from './components/FetchReviews';
import FetchOrders from './components/FetchOrders';
import EditOrder from './components/EditOrder';
import FetchTransactions from './components/FetchTransactions';
import Reports from './components/Reports';

import { setError } from './store/ApiInterface';
import * as utils from './utils/Utils';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import theme from './theme';
import DashboardLayout from './components/dashboard/DashboardLayout';
import Navigation from './components/Navigation';
import { checkTokenValidTryRefreshIfNot, parseAxiosError, useAxiosUtil } from './utils/axiosUtils';
import { useAppDispatch } from './store/configureStore';



export const App : React.FC = (props) => {    
    const _subscription = useRef<number>(0);

    const dispatch = useAppDispatch();
    
    const location = useLocation();
    const { axios_get } = useAxiosUtil();
    
    const [state, setState] = useState<Models.AppState>({
        isAuthenticated: false,
        full_name: "",
        work_place: "",
        profile_picture_base64: "",
        roles: [],
    });

    useEffect(() => {
        _subscription.current = authService.subscribe(() => { populateState('populateState/subscribe'); });
        populateState('populateState/useEffect');
        
        return () => {
            // cleanun state mapped to props            
            authService.unsubscribe(_subscription.current);
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    //https://stackoverflow.com/questions/951483/how-to-check-if-a-json-response-element-is-an-array
    const isArray = (what: any) => {
        return Object.prototype.toString.call(what) === '[object Array]';
    }

    const populateState = (event: string) => {

        if (location.pathname !== ApplicationPaths.LoginCallback) {            

            Promise.all([
                authService.isAuthenticated(),
                authService.getUser(),
            ])
                .then(([isAuthenticated, user]) => {

                    if (isAuthenticated) {                        
                        
                        checkTokenValidTryRefreshIfNot().then(token_valid => {

                            if (!token_valid) {
                                // daca token-ul este invalid apelam totusi API-ul; 
                                // acopera cazul in care aplicatia este offline si nu se poate reinnoi token-ul
                                // daca o sa returneze 401 atunci facem redirect la logout prin "parseAxiosError(e)"
                                console.log('token not valid (2)');
                            }

                            Promise.all([
                                axios_get('api/UsersInfo')
                                    .then(result => {
                                        return result;
                                    }),
                                authService.getAccessToken()
                                    .then(token => {
                                        return token;
                                    })
                            ]).then(([result, token]) => {
                                const userInfo = result as UserInfo;

                                var roles = [];

                                if (token != null) {
                                    var jsonObject = utils.decodeTokenAsJson(token);
                                    var jsonRolesObject = jsonObject['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

                                    if (isArray(jsonRolesObject))
                                        for (const role of jsonRolesObject) {
                                            roles.push(role)
                                        }
                                    else
                                        roles.push(jsonRolesObject)
                                }

                                setState({
                                    isAuthenticated: isAuthenticated,
                                    full_name: userInfo && userInfo.full_name,
                                    work_place: userInfo && userInfo.work_place,
                                    profile_picture_base64: userInfo && userInfo.profile_picture,
                                    roles: roles,                                        
                                });
                            })
                            .catch(function (e) {
                                //inlocuim navigate to logout cu parseAxiosError - doar daca e eroare 401 facem redirect la logout
                                //pt ca se poate intampla ca aplicatia sa fie offline (de ex la o actualizare de aplicatie) la momentul cand se apeleaza UserInfo - caz in care returneaza 502 - in acest caz nu trebuie redirect la logout ci asteptat pana aplicatia revine online
                                //navigate(ApplicationPaths.LogOut);
                                parseAxiosError(e);
                                dispatch(setError(e))
                                console.log(e);
                            });                               
                            
                        })
                    }
                    else {
                        setState({
                            isAuthenticated: false,
                            full_name: "",
                            work_place: "",
                            profile_picture_base64: "",
                            roles: [],
                        });
                    }
                });

        }
    }


    
    return (
        <React.Fragment>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <ApiInterface />
                    <Navigation />
                    <ErrorBoundary>

                        <Routes>
                            <Route path='/' element={<DashboardLayout auth={state} />}>

                                <Route path={ApplicationPaths.Login} element={loginAction(LoginActions.Login)} />
                                <Route path={ApplicationPaths.LoginFailed} element={loginAction(LoginActions.LoginFailed)} />
                                <Route path={ApplicationPaths.LoginCallback} element={loginAction(LoginActions.LoginCallback)} />
                                <Route path={ApplicationPaths.Profile} element={loginAction(LoginActions.Profile)} />
                                <Route path={ApplicationPaths.Register} element={loginAction(LoginActions.Register)} />
                                <Route path={ApplicationPaths.LogOut} element={logoutAction(LogoutActions.Logout)} />
                                <Route path={ApplicationPaths.LogOutCallback} element={logoutAction(LogoutActions.LogoutCallback)} />
                                <Route path={ApplicationPaths.LoggedOut} element={logoutAction(LogoutActions.LoggedOut)} />

                                <Route path="/" element={
                                    <Home />
                                } />

                                <Route path="/bundles" element={
                                    <AuthorizeRoute component={<FetchBundles />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/bundles/edit/:id" element={
                                    <AuthorizeRoute component={<EditBundle />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/categories" element={
                                    <AuthorizeRoute component={<FetchCategories />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/categories/edit/:id" element={
                                    <AuthorizeRoute component={<EditCategory />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/products" element={
                                    <AuthorizeRoute component={<FetchProducts />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/products/edit/:id" element={
                                    <AuthorizeRoute component={<EditProduct />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/companies" element={
                                    <AuthorizeRoute component={<FetchCompanies />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/company/edit/:id" element={
                                    <AuthorizeRoute component={<EditCompany />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/fetchusers" element={
                                    <AuthorizeRoute component={<FetchUsers />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/user/edit/:id" element={
                                    <AuthorizeRoute component={<EditUser />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/reviews" element={
                                    <AuthorizeRoute component={<FetchReviews />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/orders" element={
                                    <AuthorizeRoute component={<FetchOrders />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/orders/edit/:id" element={
                                    <AuthorizeRoute component={<EditOrder />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/transactions" element={
                                    <AuthorizeRoute component={<FetchTransactions />}>
                                    </AuthorizeRoute>
                                } />                                    

                                <Route path="/reports" element={
                                    <AuthorizeRoute component={<Reports />}>
                                    </AuthorizeRoute>
                                } />
                            </Route>
                        </Routes>
                    </ErrorBoundary>
                </ThemeProvider>
            </StyledEngineProvider>
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="consent"
                style={{ background: "#2B373B", zIndex: "2000" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.{" "}
            </CookieConsent>

        </React.Fragment>
    );    
}

function loginAction(name: string) {
    return (<Login action={name}></Login>);
}

function logoutAction(name: string) {
    return (<Logout action={name}></Logout>);
}

export default App;