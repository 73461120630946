import * as Models from "../models/Models";
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  FetchUsersState {
    formData: FormData;
}

export class FormData extends Models.SearchPaginationInput {
    FirstName: string = "";
    LastName: string = "";
    UserName: string = "";    
    Type: string = "0";
    HospitalId: number = 0;
    CompanyId: number = 0;
    Enabled: string = "0";
}


const unloadedState: FetchUsersState = {
    formData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        FirstName: "",
        LastName: "",
        UserName: "",
        Type: "0",
        HospitalId: 0,
        CompanyId: 0,
        Enabled: "0",
    }
};


/* new reducer */

const fetchUsersSlice = createSlice({
    name: 'FetchUsers',
    initialState: unloadedState,
    reducers: {
      setFetchUsersFormData(state, action: PayloadAction<FormData>) {
        state.formData = action.payload
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = fetchUsersSlice
  // Extract and export each action creator by name
  export const { setFetchUsersFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer