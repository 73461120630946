import * as React from 'react';
import { useEffect, useState } from 'react';
import * as Models from '../models/Models';
import {    
    Grid,    
    Button,    
    ListItemAvatar    
} from '@mui/material';
import * as utils from '../utils/Utils';


var missingImage = require('../icons/missing-image.jpg');


interface ImageUploadProps {
    onUploadImage?: (icon_file?: File) => void;
    onDeleteImage?: () => void; //todo
    width: number;
    disabled?: boolean;
    image: Models.Attachment;
}

interface ImageUploadState {
    image_file?: File;
    image_new_base_64: string | null;
    image: Models.Attachment;    
}

export const ImageUpload : React.FC<ImageUploadProps> = (props) => {

    const [state, setState] = useState<ImageUploadState>({
        image_new_base_64: null,
        image: props.image,
    });

    useEffect(() => {
        setState({
            ...state,
            image: props.image
        });
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.image]);

    const handleChangeImage = (event: any) => {
        event.preventDefault();
        if (props.onUploadImage) {
            props.onUploadImage(state.image_file)
        }
    }

    const displayImage = async (file: File | null) => {

        if (file != null) {
            var image_base64 = await utils.toBase64(file) as string;
            setState({
                ...state,
                image_file: file,
                image_new_base_64: image_base64,
            });
        }
    }

    const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event != null && event.target != null && event.target.files != null && event.target.files[0] != null) {
            var file = event.target.files[0];
            utils.checkJpegPngSignature(file).then(result => {
                if (result) {
                    displayImage(file);
                }
                else {
                    alert("File content is invalid");
                }
            });
        }
    }

    return (
        <div>                
            <Grid container spacing={5}>                                                
                <Grid item xs={12}>
                    <ListItemAvatar>                                                            
                        <img
                            src={state.image_new_base_64 != null && state.image_new_base_64 !== '' ? state.image_new_base_64 : (state.image.picture_url == null || state.image.picture_url === '' ? missingImage : state.image.picture_url)}
                            alt=''
                            style={{
                                width: props.width,
                                height: 'auto',
                            }}
                        />
                    </ListItemAvatar>
                </Grid>
                {state.image_new_base_64 != null ?
                    <Grid item xs={12}>
                        <div className="form-group">
                            <Button variant="contained" color="primary" onClick={handleChangeImage} disabled = {props.disabled}>
                                Change image
                            </Button>
                        </div>
                    </Grid> : ""
                }
                <Grid item xs={12}>
                    Upload image: <input type="file" accept="image/png, image/jpeg, image/jpg, .svg" onChange={handleUploadImage} disabled = {props.disabled}/>
                </Grid>
            </Grid>
        </div>
    );
}


export default ImageUpload;