import * as Models from "../models/Models";
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  FetchBundlesState {
    formData: FormData;    
}

export class FormData extends Models.SearchPaginationInput {
    title: string = "";
    company_id: number = 0;
    enabled: string = "0";
}


const unloadedState: FetchBundlesState = {
    formData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        title: "",
        company_id: 0,
        enabled: "0",
    }
};

/* new reducer */

const fetchBundlesSlice = createSlice({
    name: 'FetchBundles',
    initialState: unloadedState,
    reducers: {
      setFetchBundlesFormData(state, action: PayloadAction<FormData>) {
        state.formData = action.payload
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = fetchBundlesSlice
  // Extract and export each action creator by name
  export const { setFetchBundlesFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer