import * as Models from "../models/Models";
import * as utils from '../utils/Utils';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.


export interface  FetchTransactionsState {
    formData: FormData;
}

export class FormData extends Models.SearchPaginationInput {
    company_id: number = 0;
    product_id: number = 0;
    type: string = "ALL";
    start_date: string = "";
    end_date: string = "";
    order_id: string = "";
    status: number = 0;
    variation_id: number = 0;
    user_name: string = "";

    show_transaction_modal: boolean = false;
    transaction: Models.Transaction | null = null;
}

const unloadedState: FetchTransactionsState = {
    formData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        company_id: 0,
        product_id: 0,
        type: "ALL",
        start_date: utils.getDateISO(),
        end_date: utils.getDateISO(),
        order_id: "",
        status: 0,
        variation_id: 0,
        user_name: "",

        show_transaction_modal: false,
        transaction: null,
    }
};


/* new reducer */

const fetchTransactionsSlice = createSlice({
    name: 'FetchTransactions',
    initialState: unloadedState,
    reducers: {
      setFetchTransactionsFormData(state, action: PayloadAction<FormData>) {
        state.formData = action.payload
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = fetchTransactionsSlice
  // Extract and export each action creator by name
  export const { setFetchTransactionsFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer