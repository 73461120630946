import * as Models from "../models/Models";
import * as utils from '../utils/Utils';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  FetchOrdersState {
    formData: FormData;
}

export class FormData extends Models.SearchPaginationInput {
    order_id: string = "";
    order_uuid: string = "";
    status: number = 0;
    user_name: string = "";
    start_date: string = "";
    end_date: string = "";
        
}



const unloadedState: FetchOrdersState = {
    formData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        order_id: "",
        order_uuid: "",
        status: 0,
        user_name: "",
        start_date: utils.getDateISO(),
        end_date: utils.getDateISO(),
    }
};

/* new reducer */

const fetchOrdersSlice = createSlice({
    name: 'FetchOrders',
    initialState: unloadedState,
    reducers: {
      setFetchOrdersFormData(state, action: PayloadAction<FormData>) {
        state.formData = action.payload
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = fetchOrdersSlice
  // Extract and export each action creator by name
  export const { setFetchOrdersFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer