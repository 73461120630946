import * as Models from "../models/Models";
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.



export interface  FetchProductsState {
    formData: FormData;
}

export class FormData extends Models.SearchPaginationInput {
    name: string = "";
    company_id: number = 0;
    enabled: string = "0";
    selected_product_ids: number[] = [];
}


const unloadedState: FetchProductsState = {    
    formData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        company_id: 0,
        name: "",
        enabled: "0",
        selected_product_ids: []
    }
};


/* new reducer */

const fetchProductsSlice = createSlice({
    name: 'FetchProducts',
    initialState: unloadedState,
    reducers: {
      setFetchProductsFormData(state, action: PayloadAction<FormData>) {
        state.formData = action.payload
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = fetchProductsSlice
  // Extract and export each action creator by name
  export const { setFetchProductsFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer