import * as React from 'react';
import * as Models from '../models/Models';
import {
    Box,
    Tab,
    Grid,
    Popover,
    Button,
    IconButton,
    Tooltip,
    ListItemAvatar,
    Select,
    Input,
    MenuItem,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Card,
    CardHeader,
    CardContent,
    Divider,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Download as DownloadIcon, PictureAsPdf as PictureAsPdfIcon } from '@mui/icons-material';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import moment from 'moment';
import missingImage from '../icons/missing-image.jpg'; // with import
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useChangeState } from '../utils/useChangeState';
import { useGetListOrderStatusQuery, useGetOrderQuery, useSaveOrderStatusMutation } from '../store/apiSlice';
import { useAppDispatch } from '../store/configureStore';
import { setError } from '../store/ApiInterface';
import { useAxiosUtil } from '../utils/axiosUtils';


interface EmailPopoverProps {
    document_id: number;
    subject: string;
    body: string;
  }

  const EmailPopover: React.FC<EmailPopoverProps> = ({
    document_id,
    subject,
    body,
  }) => {
    const { download } = useAxiosUtil();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleView = (document_id: number, type: string) => {
        download('/api/Document/' + document_id, type, document_id.toString() + '.' + type);
    }
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    return (
      <div>
        <Button aria-describedby={id} onClick={handleClick}>
          View email
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
            <Grid container spacing={5} sx={{ borderColor: 'secondary.main', padding: 5 }}>
                <Grid item xs={2}>
                    <strong>Subject:</strong>
                </Grid>
                <Grid item xs={10}>
                    {subject}
                </Grid>

                <Grid item xs={12}>
                    <div
                        dangerouslySetInnerHTML={{__html: body}}
                    />
                </Grid>
                    
                <Grid item xs={2}>
                    <strong>Attachment:</strong>
                </Grid>
                <Grid item xs={10}>
                    <Button 
                        disabled={document_id <= 0}
                        onClick={(e) => handleView(document_id, 'pdf')}
                        size="large"
                        endIcon={<DownloadIcon />}>
                        Download
                    </Button>
                </Grid>
            </Grid>
        </Popover>
      </div>
    );
  }


export const EditOrder : React.FC = (props) => {

    const params = useParams();
    const dispatch = useAppDispatch();
    const { download } = useAxiosUtil();
    const navigate = useNavigate();
    

    const id = params.id != null ? parseInt(params.id) : 0;
    const [state, setState] = useState({
        ...Models.getOrderDefaultValues(),

        //campuri in afara modelului, necesare pt functionarea interfetei
        tab_value: "1" as string,        
    });
    
    const changeState = useChangeState(state, setState);

    const { data: orderStatus } = useGetListOrderStatusQuery({ default_value: '0', default_description: 'All'});
    const { data: orderData } = useGetOrderQuery(id);
    const [ saveOrderStatus ] = useSaveOrderStatusMutation();


    useEffect(() => {
        if (orderData){
            setState((prevState) => ({
                ...prevState, // ca sa pastreze valorile din alte obiecte ale state-ului decat "product"
                ...orderData,
                status: {
                    ...orderData.status
                }
            }));
        }
    }, [orderData]);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setState({ ...state, tab_value: newValue });
    };

    const handleView = (document_id: number, type: string) => {
        download('/api/Document/' + document_id, type, document_id.toString() + '.' + type);
    }

    const handleSaveStatus = (event: any) => {
        event.preventDefault();
        if (handleValidation()){
            saveOrderStatus({ order_id: state.order_id, status: state.status.status });
        }
    }
    
    const handleClose = (e: any) => {
        e.preventDefault();
        navigate("/orders");
    }
    
    const handleValidation = () => {
        let result = true;
        let err = '';

        const status = state.status.status;

        if (!status) {
            err += "Select status!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    const renderCreateForm = () => {
        return (
            <div>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={state.tab_value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                                <Tab label="Order data" value="1" />
                                <Tab label="Documents" value="2" />
                                <Tab label="Emails" value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <form name="formOrder" id="formOrder">
                                <Grid container spacing={5}>
                                    <Grid item xs={2}>
                                        <strong>{'Order Id: ' + state.order_id}</strong>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>{'Uuid: ' + state.id}</strong>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Grid container spacing={5}>                                
                                            <Grid item xs={4}>
                                                <strong>Status:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Select
                                                    name="status"                                            
                                                    value={orderStatus? state.status.status : ''}
                                                    input={<Input />}
                                                    MenuProps={utils.MenuProps}
                                                    onChange={(e) => changeState(e, 'status')}
                                                >
                                                    {orderStatus?.map(o =>
                                                        <MenuItem key={o.status} value={o.status}>{o.name}</MenuItem>
                                                    )}
                                                </Select>
                                                <Button variant="contained" color="primary" onClick={handleSaveStatus}>
                                                    Save Status
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <strong>{'Total: ' + utils.formatPrice({amount: state.total, currencyCode: state.ccy, locale: 'ro'})}</strong>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <strong>{'Subtotal: ' + utils.formatPrice({amount: state.subtotal, currencyCode: state.ccy, locale: 'ro'})}</strong>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <strong>{'Delivery fee: ' + utils.formatPrice({amount: state.delivery_fee, currencyCode: state.ccy, locale: 'ro'})}</strong>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <strong>{'Discount: ' + utils.formatPrice({amount: state.discount, currencyCode: state.ccy, locale: 'ro'})}</strong>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Grid container spacing={5}>                                
                                            <Grid item xs={4}>
                                                <strong>Contact person:</strong>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {state.checkout_details?.contact?.contact_person}
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Phone:</strong>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {state.checkout_details?.contact?.phone}
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Email:</strong>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {state.checkout_details?.contact?.email}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Grid container spacing={5}>                                
                                            <Grid item xs={4}>
                                                <strong>Address Line 1:</strong>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {state.checkout_details?.address?.line_1}
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Address Line 2:</strong>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {state.checkout_details?.address?.line_2}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <strong>Delivery option:</strong>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {state.checkout_details?.deliveryInstructions?.delivery_option.name}
                                    </Grid>
                                    
                                    <Grid item xs={2}>
                                        <strong>Delivery notes:</strong>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {state.checkout_details?.deliveryInstructions?.notes}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Table>
                                            <TableHead>
                                                <TableRow key={0}>                                        
                                                    <TableCell>
                                                        Product
                                                    </TableCell>                                                
                                                    <TableCell>
                                                        Quantity
                                                    </TableCell>
                                                    <TableCell>
                                                        Price
                                                    </TableCell>
                                                    <TableCell>
                                                        Sale Price
                                                    </TableCell>
                                                    <TableCell>
                                                        Catalog Price
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {state.products.map((item) =>(
                                                    <TableRow
                                                    hover
                                                    key={item.product_id}>
                                                        <TableCell>
                                                            <Box
                                                                sx={{
                                                                    alignItems: 'center',
                                                                    display: 'flex'
                                                                }}
                                                            >
                                                                <ListItemAvatar>
                                                                    <img
                                                                        src={item.image?.picture_url == null ? missingImage : item.image.picture_url}
                                                                        alt=''
                                                                        style={{
                                                                            height: 48,
                                                                            width: 48
                                                                        }}
                                                                    />
                                                                </ListItemAvatar>
                                                                <Typography
                                                                    color="textPrimary"
                                                                    variant="body1"
                                                                >
                                                                    {item.name}
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.quantity}
                                                        </TableCell>
                                                        <TableCell>
                                                            {utils.formatPrice({amount: item.price, currencyCode: item.ccy, locale: 'ro'})}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.sale_price ? utils.formatPrice({amount: item.sale_price, currencyCode: item.ccy, locale: 'ro'}) : ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            {utils.formatPrice({amount: item.catalog_price, currencyCode: item.ccy, locale: 'ro'})}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                        </Table>
                                    </Grid>


                                    <Grid item xs={3}>
                                        {state.ins_date !== "" ?
                                            <strong>{'Created at: ' + moment(state.ins_date).format('DD.MM.YYYY HH:mm:ss')}</strong>
                                            : ""
                                        }
                                    </Grid>

                                    <Grid item xs={3}>
                                        {state.ins_user !== "" ?                                
                                            <strong>{'Created by: ' + state.ins_user}</strong>
                                            : ""
                                        }
                                    </Grid>

                                    <Grid item xs={3}>
                                        {state.upd_date !== "" ?
                                            <strong>{'Modified at: ' + moment(state.upd_date).format('DD.MM.YYYY HH:mm:ss')}</strong>
                                            : ""
                                        }
                                    </Grid>

                                    <Grid item xs={3}>
                                        {state.upd_user !== "" ?
                                            <strong>{'Modified by: ' + state.upd_user}</strong>
                                            : ""
                                        }
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box sx={styled.buttonsBox}>
                                            <Button variant="contained" color="primary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </form>
                        </TabPanel>
                        <TabPanel value="2">
                            <Card>
                                <CardHeader title="Banners" />
                                <Divider />
                                {state.documents.length > 0 ?
                                    <CardContent>
                                        <Box>
                                            <Table>
                                                <TableHead>
                                                    <TableRow key={0}>
                                                        <TableCell>
                                                            Document Id
                                                        </TableCell>
                                                        <TableCell>
                                                            Type
                                                        </TableCell>
                                                        <TableCell>
                                                            Description
                                                        </TableCell>
                                                        <TableCell>
                                                            Age
                                                        </TableCell>                                                        
                                                        <TableCell style={{ minWidth: "100px" }}>
                                                            View
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {state.documents.map((item) => (
                                                        <TableRow
                                                            hover
                                                            key={item.document_id}
                                                        >
                                                            <TableCell>
                                                                {item.document_id}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.type}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.description}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Tooltip title={moment(item.ins_date).format('DD.MM.YYYY HH:mm:ss')}>
                                                                    <span>
                                                                        {item.age}
                                                                    </span>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box sx={styled.buttonsBox}>
                                                                    <IconButton 
                                                                        disabled={!item.has_pdf}
                                                                        onClick={(e) => handleView(item.document_id, 'pdf')}
                                                                        size="large">
                                                                        <PictureAsPdfIcon />
                                                                    </IconButton>
                                                                    

                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </CardContent>
                                    :
                                    "Documents does not exists on this order"
                                }
                            </Card>
                        </TabPanel>
                        <TabPanel value="3">
                            <Card>
                                <CardHeader title="Emails" />
                                <Divider />
                                {state.emails.length > 0 ?
                                    <CardContent>
                                        <Box>
                                            <Table>
                                                <TableHead>
                                                    <TableRow key={0}>
                                                        <TableCell>
                                                            Email Id
                                                        </TableCell>
                                                        <TableCell>
                                                            To
                                                        </TableCell>
                                                        <TableCell>
                                                            From
                                                        </TableCell>
                                                        <TableCell>
                                                            Cc
                                                        </TableCell>                                                        
                                                        <TableCell>
                                                            Error
                                                        </TableCell>
                                                        <TableCell>
                                                            Error Message
                                                        </TableCell>
                                                        <TableCell>
                                                            Date
                                                        </TableCell>                                                        
                                                        <TableCell style={{ minWidth: "100px" }}>
                                                            View Email
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {state.emails.map((item) => (
                                                        <TableRow
                                                            hover
                                                            key={item.email_id}
                                                        >
                                                            <TableCell>
                                                                {item.email_id}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.to}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.from}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.cc}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.error ? "Yes" : "No"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.error_message}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(item.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                                            </TableCell>
                                                            <TableCell>                                                            
                                                                <EmailPopover 
                                                                    document_id={item.document_id}
                                                                    subject={item.subject}
                                                                    body={item.body}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </CardContent>
                                    :
                                    "Emails does not exists on this order"
                                }
                            </Card>
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>
        )
    }

    return (
        <div>
            <h3>Order</h3>
            <hr />
            {renderCreateForm()}
        </div>
    );
}

export default EditOrder;