import * as React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
//import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store/configureStore';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
//import registerServiceWorker from './registerServiceWorker';


const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
    <Provider store={store}>        
        {/*<ConnectedRouter history={history}>*/}
        {/*    <App />*/}
        {/*</ConnectedRouter>*/}
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>);

//registerServiceWorker();
