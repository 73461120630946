import * as React from 'react';
import { withNavigation } from '../hocs'
import { NavigateFunction } from 'react-router';

var navigate: NavigateFunction;


//pentru ca nu am solutie de navigat intre pagini fara hooks (necesar in ApiInterface.ts) atunci am facut aceasta clasa unde exportam functia de navigae ca sa poata fi utilizata unde nu avem functional components
export class Navigation extends React.PureComponent {    

    constructor(props: any) {
        super(props);
        navigate = props.navigate;
    }

    componentDidMount() {
        
    }

    componentWillUnmount() {
        
    }

    componentDidUpdate(prevProps: any) {
        
    }

    render() {
        return (
            <React.Fragment/>
        );
    }
}

export function navigateToUrl(url: string) {
    if (navigate)
        navigate(url, { replace: true, state: url });
}

export default withNavigation(Navigation as any);