import * as React from 'react';
import * as Validator from "../validator/Validator";
import * as Models from '../models/Models';
import {
    Grid,
    TextField,    
    Button,
    Switch,
    ListItemAvatar,    
    Select,
    Input,
    MenuItem,
    Dialog,
    Box,
    Tab,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { DialogTitle, DialogContent } from './StyledComponents'
import FetchProducts from './FetchProducts'
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import ImageUpload from './ImageUpload'
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useChangeState } from '../utils/useChangeState';
import { useAppDispatch } from '../store/configureStore';
import { 
    useGetListCompaniesQuery,
    useGetBundleQuery,
    useSaveBundleMutation,
    useSaveBundleProductsMutation,
    useDeleteBundleProductMutation,
    useSaveAttachmentMutation
} from '../store/apiSlice';
import { setError } from '../store/ApiInterface';
//import missingImage from '../icons/missing-image.jpg'; // with import
var missingImage = require('../icons/missing-image.jpg');



export const EditBundle : React.FC = (props) => {

    const params = useParams();

    const dispatch = useAppDispatch();
    
    const navigate = useNavigate();

    const id = params.id != null ? parseInt(params.id) : 0;
    const [state, setState] = useState({
        ...Models.getBundleDefaultValues(),

        //campuri in afara modelului, necesare pt functionarea interfetei
        selected_product_ids: [] as number[],
        show_products_modal: false as boolean,
        tab_value: "1" as string,
    });
    
    const changeState = useChangeState(state, setState);    

    const { data: companies } = useGetListCompaniesQuery({ default_value: '0', default_description: 'Select company'});
    const { data: bundle } = useGetBundleQuery(id);
    const [ saveBundle ] = useSaveBundleMutation();
    const [ saveBundleProducts, { isSuccess, reset } ] = useSaveBundleProductsMutation();
    const [ deleteBundleProduct ] = useDeleteBundleProductMutation();
    const [ saveAttachment ] = useSaveAttachmentMutation();

    useEffect(() => {
        if (bundle){
            setState((prevState) => ({
                ...prevState,
                ...bundle
            }))
        }
    }, [bundle]);

    useEffect(() => {
        if (isSuccess){
            reset();
            if (state.show_products_modal){
                setState((prevState) => ({
                    ...prevState,
                    show_products_modal: false,
                    selected_product_ids: [],
                }));
            }
        }
    }, [isSuccess, state.show_products_modal, reset]);


    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setState({ ...state, tab_value: newValue });
    };

    // This will handle the submit form event.  
    const handleSave = (event: any) => {
        event.preventDefault();
        if (handleValidation()) {
            saveBundle(state);
        }
    }

    // This will handle Cancel button click event.  
    const handleClose = (e: any) => {
        e.preventDefault();
        navigate("/bundles");
    }

    const handleChangePicture = (image_file: File | undefined) => {
        
        if (handleValidationChangePicture(image_file) && image_file !== undefined) {
            const formData = new FormData();
            formData.append("related_id", state.bundle_id.toString());
            formData.append("file", image_file);
            formData.append("type", "bundle");
            formData.append("company_id", state.company_id.toString());
            saveAttachment({ picture: formData, tag: 'EditBundle', tag_id: id });
        }
    }

    const handleDeleteProduct = (product_id: number, title: string) => {
        if (!window.confirm("Do you want to delete the related product: " + title))
            return;
        else {
            deleteBundleProduct({ id: product_id, bundle_id: id });
        }
    }

    const handleEditRelatedProducts = () => {
        setState({
            ...state, 
            show_products_modal: true,
            selected_product_ids: state.products.map(x => x.product_id)
        });
    }

    const handleSaveProductsModal = (selected_product_ids: number[]) => {
        if (selected_product_ids.length === 0) {
            dispatch(setError("Select related products"));
            return;
        }        

        saveBundleProducts({
            bundle_id: state.bundle_id,
            product_ids: selected_product_ids
        });
    }

    const handleCloseProductsModal = () => {
        setState({
            ...state, 
            show_products_modal: false,
            selected_product_ids: [],
        });
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var title = state.title;
        var description = state.description;
        var company_id = state.company_id;

        if (!Validator.isAlphaNumericAndSpaceUnderscore(title)) {
            err += "Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (title.length <= 0 || title.length > 100) {
            err += "Name should be between 1 and 100 characters!\n";
            result = false;
        }

        if (!Validator.isAlphaNumericAndSpaceUnderscore(description)) {
            err += "Description should be alpha numeric or underscore!\n";
            result = false;
        }
        if (description.length <= 0 || description.length > 100) {
            err += "Description should be between 1 and 100 characters!\n";
            result = false;
        }

        if (company_id <= 0) {
            err += "Select the company!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    const handleValidationChangePicture = (image_file: File | undefined) => {
        let result = true;
        let err = '';

        if (state.bundle_id <= 0) {
            err += "First save the bundle then change the picture!\n";
            result = false;
        }

        if (image_file === undefined) {
            err += "Picture is not uploaded!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    // Returns the HTML Form to the render() method.  
    const renderCreateForm = () => {


        return (
            <div>
                <Dialog
                    fullScreen
                    onClose={handleCloseProductsModal}
                    aria-labelledby="customized-dialog-related-products"
                    open={state.show_products_modal}
                >
                    <DialogTitle id="customized-dialog-related-products" onClose={handleCloseProductsModal}>
                        Products
                    </DialogTitle>
                    <DialogContent dividers>
                        <FetchProducts
                            selectable={true}
                            selected_company_id={state.company_id}
                            selected_product_ids={state.selected_product_ids}
                            onCloseCallback={handleCloseProductsModal}
                            onSaveCallback={handleSaveProductsModal}
                        />
                    </DialogContent>
                </Dialog>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={state.tab_value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                                <Tab label="Basic data" value="1" />
                                <Tab label="Products" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <form name="formEditBundle" id="formEditBundle" onSubmit={handleSave}>
                                <Grid container spacing={5}>
                                    <Grid item xs={6}>
                                        <Grid container spacing={5}>

                                            <Grid item xs={4}>
                                                <strong>Comapany:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Select
                                                    name="company_id"                                            
                                                    value={companies? state.company_id : ''}
                                                    input={<Input />}
                                                    MenuProps={utils.MenuProps}
                                                    onChange={(e) => changeState(e)}
                                                >
                                                    {companies?.map(o =>
                                                        <MenuItem key={o.company_id} value={o.company_id}>{o.name}</MenuItem>
                                                    )}
                                                </Select>
                                            </Grid>


                                            <Grid item xs={4}>
                                                <strong>Title:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="title"
                                                    variant="standard"
                                                    value={state.title}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Description:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="description"
                                                    variant="standard"
                                                    value={state.description}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Background color:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                            <input type="color"
                                                    name="background_color" 
                                                    value={state.background_color} 
                                                    onChange={changeState}/>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Enabled:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Switch
                                                    name="enabled"
                                                    checked={state.enabled}
                                                    onChange={(e) => changeState(e)}
                                                    color="primary"
                                                />
                                            </Grid>
                                            {state.ins_date !== "" ?
                                                <>
                                                    <Grid item xs={4}>
                                                        <strong>Created at:</strong>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {moment(state.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                                    </Grid></> : ""
                                            }
                                            {state.ins_user !== "" ?
                                                <>
                                                    <Grid item xs={4}>
                                                        <strong>Created by:</strong>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {state.ins_user}
                                                    </Grid></> : ""
                                            }
                                            {state.upd_date !== "" ?
                                                <>
                                                    <Grid item xs={4}>
                                                        <strong>Modified at:</strong>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {moment(state.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                                    </Grid></> : ""
                                            }
                                            {state.upd_user !== "" ?
                                                <>
                                                    <Grid item xs={4}>
                                                        <strong>Modified by:</strong>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {state.upd_user}
                                                    </Grid></> : ""
                                            }
                                            <Grid item xs={12}>
                                                <Box sx={styled.buttonsBox}>
                                                    <Button variant="contained" color="primary" type="submit">
                                                        Save
                                                    </Button>
                                                    <Button variant="contained" color="primary" onClick={handleClose}>
                                                        Close
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ImageUpload
                                            onUploadImage={(image) => handleChangePicture(image)}
                                            width={100}
                                            image={state.image}
                                        />
                                    </Grid>                        
                                </Grid>
                            </form>
                        </TabPanel>
                        <TabPanel value="2">
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Products" />
                                        <Divider />
                                        {state.products.length > 0 ?
                                            <CardContent>
                                                <Box>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow key={0}>
                                                                <TableCell>
                                                                    Image
                                                                </TableCell>
                                                                <TableCell>
                                                                    Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    Company
                                                                </TableCell>
                                                                <TableCell style={{ minWidth: "100px" }}>
                                                                    Delete
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {state.products.map((item) => (
                                                                <TableRow
                                                                    hover
                                                                    key={item.id}
                                                                >
                                                                    <TableCell>
                                                                        <Box
                                                                            sx={{
                                                                                alignItems: 'center',
                                                                                display: 'flex'
                                                                            }}
                                                                        >
                                                                            <ListItemAvatar>
                                                                                <img
                                                                                    src={item.image?.picture_url == null ? missingImage : item.image.picture_url}
                                                                                    alt=""
                                                                                    style={{
                                                                                        height: 48,
                                                                                        width: 48
                                                                                    }}
                                                                                />
                                                                            </ListItemAvatar>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.name}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.company}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={styled.buttonsBox}>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                size="small"
                                                                                endIcon={<DeleteForeverIcon />}
                                                                                onClick={(id) => handleDeleteProduct(item.id, item.name)}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </CardContent>
                                            :
                                            "There are no products. To add products use the button 'ADD PRODUCTS'"
                                        }
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(id) => handleEditRelatedProducts()}
                                    >
                                        ADD PRODUCTS
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>
        )
    }

    return (
        <div>
            <h3>Bundle definition</h3>
            <hr />
            {renderCreateForm()}
        </div>
    );
}

export default EditBundle;