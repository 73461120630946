import * as utils from '../utils/Utils';

export interface AppState {
    isAuthenticated: boolean;
    full_name: string;
    work_place: string;
    profile_picture_base64: string;
    roles: string[];
}

export class ListValue {
    Value: number = 0;
    Description: string = "";
}

export class SearchPaginationResult {
    RowCount: number = 0;
    First: number = 0;
    Prev: number = 0;
    Current: number = 0;
    Next: number = 0;
    Last: number = 0;
    AfterIdNext: number = 0;
}

export class SearchPaginationInput {
    PageNumber: number = 0;
    PageSize: number = 0;
    AfterId: number = 0; //nu e folosit; se merge pe varianta de search prin paginare (adica se folosesc parametrii PageSize si PageNumber)
}

export class UserData {
    UserId: number = 0;
    UserName: string = "";
    Type: string = "";
    CompanyName: string = "";
    CompanyId: number = 0;
    Email: string = "";
    EmailConfirmed: boolean = false;
    FirstName: string = "";
    LastName: string = "";
    PhoneNumber: string = "";
    PhoneNumberConfirmed: boolean = false;
    UserEnabled: boolean = false;
    TwoFactorEnabled: boolean = false;
    Roles: Role[] = [];
    Password1: string = "";
    Password2: string = "";
}

export class Role {
    Value: string = "";
    Label: string = "";
    Selected: boolean = false;
}

export class UserInfo {    
    full_name: string = "";
    work_place: string = "";
    profile_picture_format: string = "";
    profile_picture: string = "";
    
}

export class Report {
    report_id: number = 0;
    type: string = "";
    description: string = "";
    ins_date: string = "";
    ins_user: string = "";
    age: string = "";
    has_pdf: boolean = false;
    has_xlsx: boolean = false;
}

export class Banner {
    banner_id: number = 0;
    company_id: number = 0;
    enabled: boolean = false;
    type: string = '0';
    position: string = '0';
    type_desc: string = '';
    position_desc: string = '';
    title: string = "";
    title_color: string = "";
    description: string = "";
    description_color: string = "";
    btn_text: string = "";
    btn_text_color: string = "";
    btn_text_background_color: string = "";
    searchbox: boolean = false;
    url: string = "";
    dimension: string = '0';
    location_id: number = 0;
    image_desktop: Attachment = getAttachmentDefaultValues();
    image_mobile: Attachment = getAttachmentDefaultValues();
    effect_active: boolean = false;
    ins_date: string = '';
    ins_user: string = '';
    upd_date: string = '';
    upd_user: string = '';
}

export class Company {
    company_id: number = 0;
    name: string = "";
    address: string = "";
    lat: number = 0;
    lng: number = 0;
    description: string = "";
    phone: string = "";
    website: string = "";
    landing_page: string = "";
    email: string = "";
    email_subject: string = "";
    email_body: string = "";
    theme: string = "";
    enabled: boolean = false;
    is_main: boolean = false;
    is_marketplace: boolean = false;
    logo_square: Attachment = getAttachmentDefaultValues();
    logo_rectangle: Attachment = getAttachmentDefaultValues();
    cover_image: Attachment = getAttachmentDefaultValues();
    cover_image_mobile: Attachment = getAttachmentDefaultValues();
    favicon: Attachment = getAttachmentDefaultValues();
    
    gallery: Attachment[] = [];
    delivery_options: CompanyDeliveryOption[] = [];
    limited_delivery_area: boolean = false;
    delivery_area: LatLng[] = [];
    banners: Banner[] = [];
    website_settings: CompanyWebsiteSetting[] = [];
    ext_settings: CompanyExtSettings = getCompanyExtSettingsDefaultValues();
}

export class CompanyExtSettings {
    show_price: boolean = false;
    show_sold_by: boolean = false;
    seo_no_index: boolean = false;
    show_product_popup: boolean = false;
    show_product_popup_full: boolean = false;
    use_google_maps: boolean = false;
  };

export class CompanyDeliveryArea {
    company_id: number = 0;
    limited_delivery_area: boolean = false;
    delivery_area: LatLng[] = [];
}

export class CompanyDeliveryOption {
    id: number = 0;
    company_id: number = 0;
    name: string = '';
    enabled: boolean = false;
    price: number = 0;
    ccy: string = '';
    api_request: boolean = false;
    api_company_id: number = 0;
    api_user_name: string = "";
    api_pwd: string = "";
    sender_name: string = "";
    pickup_address_country: string = "";
    pickup_address_area: string = "";
    pickup_address_locality: string = "";
    pickup_address_number: string = "";
    pickup_address_street: string = "";
    pickup_contact_person: string = "";
    pickup_contact_phone: string = "";
    ins_date: string = '';
    ins_user: string = '';
    upd_date: string = '';
    upd_user: string = '';
}

export class CompanyWebsiteSettingJsonValue {
    id: number = 0;
    title: string = '';
    value: string = '';
    date: string = '';
    summary: string = '';
    picture_url: string = '';
    enabled: boolean = false;
}
export class CompanyWebsiteSetting {
    id: number = 0;
    company_id: number = 0;
    setting_id: number = 0;
    name: string = '';
    multiple_elements: boolean = false;
    format: string = '';    
    language: string = "";
    json_value: CompanyWebsiteSettingJsonValue = getCompanyWebsiteSettingJsonValueDefaultValues();
    json_values: CompanyWebsiteSettingJsonValue[] = [];
    text_value: string = '';
    ins_date: string = '';
    ins_user: string = '';
    upd_date: string = '';
    upd_user: string = '';
}

export class Category {
    category_id: number = 0;
    parent_category_id: number = 0;
    company_id: number = 0;
    company_name: string = "";
    name: string = "";
    slug: string = "";
    enabled: boolean = false;
    order_number: number = 0;
    image: Attachment = getAttachmentDefaultValues();
    children: Category[] = [];
    ins_date: string = "";
    ins_user: string = "";
    upd_date: string = "";
    upd_user: string = "";

    //campuri necesare interfetei (pt upload imagini / tab)
    tab_value: string = "1";
    subcategory: Category | null = getCategoryDefaultValues();
    show_subcategory_modal: boolean = false;
}

export class Bundle {
    bundle_id: number = 0;
    company_id: number = 0;  
    company_name: string = "";
    slug: string = "";
    title: string = "";
    description: string = "";
    background_color: string = "";
    image: Attachment = getAttachmentDefaultValues();
    enabled: boolean = false;
    products: BundleProduct[] = [];
    ins_date: string = "";
    ins_user: string = "";
    upd_date: string = "";
    upd_user: string = "";
  };
  
  export class BundleProduct {
    id: number = 0;
    product_id: number = 0;
    product_id_related: number = 0;
    name: string = '';
    company: string = '';
    image: Attachment = getAttachmentDefaultValues();
}

export class Attachment {
    attachment_id: number = 0;
    type: string = '';
    enabled: boolean = false;
    file_name: string = '';    
    format: string = '';
    alt: string = '';
    tag: string = '';
    picture_url: string = '';
    width: number = 0;
    height: number = 0;
    ins_date: string = "";
    ins_user: string = "";
    upd_date: string = "";
    upd_user: string = "";
    usage_count: number = 0;
    usage_description: string = "";
}


export class Product {
    product_id: number = 0;
    company_id: number = 0;    
    company_name: string = '';    
    name: string = '';
    slug: string = '';
    enabled: boolean = false;
    price: number = 0;
    quantity: number = 0;
    show_quantity: boolean = false;
    unlimited_quantity: boolean = false;
    sold: number = 0;
    unit: string = '';
    sale_price: number = 0;
    min_price: number = 0;
    max_price: number = 0;
    sku: string = '';
    description: string = '';
    description_html: string = '';
    image: Attachment = getAttachmentDefaultValues();
    gallery: Attachment[] = [];
    variations: ProductVariation[] = [];
    sales_channels: ProductSalesChannel[] = [];
    sales_channels_str: string = '';
    products_related: ProductRelated[] = [];
    ins_date: string = "";
    ins_user: string = "";
    upd_date: string = "";
    upd_user: string = "";
}

export class ProductVariation {
    id: string = '';
    variation_id: number = 0;
    product_id: number = 0;
    title: string = '';
    name: string = '';
    slug: string = '';
    price: number = 0;
    quantity: number = 0;
    show_quantity: boolean = false;
    unlimited_quantity: boolean = false;
    sold: number = 0;
    sale_price: number = 0;
    sku: string = '';
    enabled: boolean = true;
    ins_date: string = '';
    ins_user: string = '';
    upd_date: string = '';
    upd_user: string = '';
}

export class ProductSalesChannel {
    sale_id: number = 0;
    sale_uuid: string = '';
    product_id: number = 0;
    company_id: number = 0;
    company_name: string = '';
    category_id: number = 0;
    category_name: string = '';    
    enabled: boolean = true;
    ins_date: string = '';
    ins_user: string = '';
    upd_date: string = '';
    upd_user: string = '';
}

export class ProductRelated {
    id: number = 0;
    product_id: number = 0;
    product_id_related: number = 0;
    name: string = '';
    company: string = '';
    image: Attachment = getAttachmentDefaultValues();
}


export class Ingredient {
    ingredient_id: number = 0;
    name: string = "";
    unit: string = "";
    enabled: boolean = false;
    ins_date: string = "";
    ins_user: string = "";
    upd_date: string = "";
    upd_user: string = "";
}

export class DishIngredient {
    id: number = 0;
    dish_id: number = 0;
    ingredient_id: number = 0;
    ingredient_name: string = "";
    ingredient_unit: string = "";
    qty: number = 0;
    ins_date: string = "";
    ins_user: string = "";
}


export class Dish {
    data: DishData = {
        menu_dish_id: 0,
        dish_id: 0,
        meal_id: 0,
        title: '',
        kcal: 0,
        description: '',
        enabled: false,
        ins_date: '',
        ins_user: '',
        upd_date: '',
        upd_user: '',
    };
    picture: DishPicture = {
        picture_base64: '',
        picture_uploaded: false,
    };
    ingredients: DishIngredient[] = [];
}

export class DishData {
    menu_dish_id: number = 0;
    dish_id: number = 0;
    meal_id: number = 0;
    title: string = "";
    kcal: number = 0;
    description: string = "";
    enabled: boolean = false;
    ins_date: string = "";
    ins_user: string = "";
    upd_date: string = "";
    upd_user: string = "";
}

export class DishPicture {
    picture_file?: File;
    picture_base64: any;
    picture_uploaded: boolean = false;
}


export class ReportType {
    type: number = 0;
    name: string = "";
}

export class ReportSave {
    report_type: number = 0;
    start_date: string = '';
    end_date: string = '';
    client_id: number = 0;
    description: string = '';
}

export class TransactionType {
    type: string = "";
    name: string = "";
}

export class TransactionStatus {
    status: number = 0;
    name: string = "";
}

export type ProductReview = {
    sale_uuid: string;
    review_id: number;
    product_id: number;
    product_name: string;
    product_thumbnail: string;
    title: string;
    name: string;
    email: string;
    message: string;
    rating: number;
    valid: boolean;
    ins_date: string;
    show_delete: boolean;
  };

  export type LatLng = {
    lat: number;
    lng: number;
  };

  export type Document = {
    document_id: number;
    type: string;
    description: string;
    ins_date: string;
    ins_user: string;
    age: string;
    has_pdf: boolean;
    has_xlsx: boolean;
  };
  export type EmailArguments = {
    email_id: number;
    type: number;
    related_id: number;
    document_id: number;
    to: string;
    from: string;
    cc: string;
    subject: string;
    body: string;
    error: boolean;
    error_message: string;
    ins_date: string;
    ins_user: string;
  }
  

  export type Order = {
    id: string;
    company_name: string;
    order_id: number;
  
    total: number;
    subtotal: number;
    delivery_fee: number;
    discount: number;
    ccy: string;
    status: OrderStatus;
    
    ins_date: string;
    ins_user: string;
    upd_date: string;
    upd_user: string;
    products: OrderItem[];
  
    checkout_details?: CheckoutDetails;

    documents: Document[];
    emails: EmailArguments[];

    //campuri necesare interfetei    
    tab_value: string;
  };

  export type OrderStatus = {
    status: number;
    name: string;
    serial: number;
    color: string;
  };

  export interface OrderItem {
    id: string | number;
    sale_uuid: string | number;
    variation_id?: number;
    name: string;
    slug: string;
    shop_slug: string;
    unit?: string;
    quantity?: number;
    stock?: number;
    price: number;
    sale_price?: number;
    catalog_price: number;
    ccy: string;
    image: {
      picture_url: string;
      [key: string]: unknown;
    };  
    [key: string]: any;
  }

  export interface CheckoutDetails {
    address: Address | null;
    contact: Contact | null;
    deliveryInstructions: DeliveryInstructions | null;
  
  }

  export type Address = {
    address_id: number;
    title: string;
    is_default: boolean;
    line_1: string;
    line_2: string;
    lat: number;
    lng: number;
    fill_mode: number;
  };

  export type Contact = {
    contact_id: number;
    contact_person: string;
    phone: string;
    email: string;
    is_default: boolean;
    
  };

  export type DeliveryInstructions = {
    delivery_option: OrderDeliveryOption;
    notes: string;
    leave_at_door: boolean;  
  }

export type OrderDeliveryOption = {
  id: number;
  name: string;
  price: number;
  ccy: string;
};

export type Transaction = {
    transaction_id: number;
    type: string;    
    order_id: number;
    status_name: string;
    variation_name: string;
    variation_title: string;
    sku: string;
    quantity: number;
    price: number;
    sale_price: number;
    catalog_price: number;
    ccy: string;
    name: string;
    unit: string;
    user_name: string;
    ins_date: string;
    ins_user: string;
    upd_date: string;
    upd_user: string;
  };

export class GalleryType {
    value: string = "";
    description: string = "";
}

export function getUserDataDefaultValues(): UserData {
    let output: UserData = {
        UserId: 0,
        UserName: '',
        Type: '0',
        CompanyName: '',
        CompanyId: 0,
        Email: '',
        EmailConfirmed: false,
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        PhoneNumberConfirmed: false,
        UserEnabled: false,
        TwoFactorEnabled: false,
        Roles: [],
        Password1: '',
        Password2: ''
    };
    return output;
}




export function getCompanyDefaultValues(): Company {
    let output: Company = {
        company_id: 0,
        name: '',
        address: '',
        lat: 0,
        lng: 0,
        description: '',
        phone: '',
        website: '',
        landing_page: '',
        email: '',
        email_subject: '',
        email_body: '',
        theme: '',
        enabled: false,
        is_main: false,
        is_marketplace: false,
        logo_square: getAttachmentDefaultValues(),
        logo_rectangle: getAttachmentDefaultValues(),
        cover_image: getAttachmentDefaultValues(),
        cover_image_mobile: getAttachmentDefaultValues(),
        favicon: getAttachmentDefaultValues(),
        gallery: [],
        delivery_options: [],        
        limited_delivery_area: false,
        delivery_area: [],
        banners: [],
        website_settings: [],
        ext_settings: getCompanyExtSettingsDefaultValues(),
    };
    return output;
}

export function getCompanyExtSettingsDefaultValues(): CompanyExtSettings {
    let output: CompanyExtSettings = {
        show_price: false,
        show_sold_by: false,
        seo_no_index: false,
        show_product_popup: false,
        show_product_popup_full: false,
        use_google_maps: false,
    };
    return output;
}

export function getCompanyDeliveryOptionDefaultValues(): CompanyDeliveryOption {
    let output: CompanyDeliveryOption = {
        id: 0,
        company_id: 0,
        name: '',
        enabled: true,
        price: 0,
        ccy: 'RON',
        api_request: false,
        api_company_id: 0,
        api_user_name: '',
        api_pwd: '',
        sender_name: '',
        pickup_address_country: '',
        pickup_address_area: '',
        pickup_address_locality: '',
        pickup_address_number: '',
        pickup_address_street: '',
        pickup_contact_person: '',
        pickup_contact_phone: '',
        ins_date: '',
        ins_user: '',
        upd_date: '',
        upd_user: '',
    };
    return output;
}

export function getCompanyWebsiteSettingDefaultValues(): CompanyWebsiteSetting {
    let output: CompanyWebsiteSetting = {
        id: 0,
        company_id: 0,
        setting_id: 0,
        name: '',
        multiple_elements: false,
        format: '',
        language: '',
        json_value: getCompanyWebsiteSettingJsonValueDefaultValues(),
        json_values: [],
        text_value: '',
        ins_date: '',
        ins_user: '',
        upd_date: '',
        upd_user: '',
    };
    return output;
}
export function getCompanyWebsiteSettingJsonValueDefaultValues(): CompanyWebsiteSettingJsonValue {
    let output: CompanyWebsiteSettingJsonValue = {        
        id: 0,
        title: '',
        value: '',
        date: utils.getDateISO(),
        summary: '',
        picture_url: '',
        enabled: false,
    };
    return output;
}


export function getBannerDefaultValues(): Banner {
    let output: Banner = {
        banner_id: 0,
        company_id: 0,
        enabled: true,
        type: '0',
        position: '0',
        type_desc: '',
        position_desc: '',
        title: '',
        title_color: '#000000',
        description: '',
        description_color: '#000000',
        searchbox: false,
        btn_text: '',
        btn_text_color: '#000000',
        btn_text_background_color: '#ffffff',
        url: '',
        dimension: '0',
        location_id: 0,
        image_desktop: getAttachmentDefaultValues(),
        image_mobile: getAttachmentDefaultValues(),
        effect_active: true,
        ins_date: '',
        ins_user: '',
        upd_date: '',
        upd_user: '',
    };
    return output;
}


export function getAttachmentDefaultValues(): Attachment {
    let output: Attachment = {
        attachment_id: 0,
        type: '',
        enabled: false,
        file_name: '',
        format: '',
        alt: '',
        tag: '',
        picture_url: '',
        width: 0,
        height: 0,
        ins_date: '',
        ins_user: '',
        upd_date: '',
        upd_user: '',
        usage_count: 0,
        usage_description: '',
    };
    return output;
}

export function getProductDefaultValues(): Product {
    let output: Product = {
        product_id: 0,
        company_id: 0,
        company_name: '',
        name: '',
        slug: '',
        enabled: false,
        price: 0,
        quantity: 0,
        show_quantity: false,
        unlimited_quantity: false,
        sold: 0,
        unit: '',
        sale_price: 0,
        min_price: 0,
        max_price: 0,
        sku: '',
        description: '',
        description_html: '',
        image: getAttachmentDefaultValues(),
        gallery: [],
        variations: [],
        sales_channels: [],
        sales_channels_str: '',
        products_related: [],
        ins_date: '',
        ins_user: '',
        upd_date: '',
        upd_user: '',
    };
    return output;
}

export function getProductVariationDefaultValues(): ProductVariation {
    let output: ProductVariation = {
        id: '',
        variation_id: 0,
        product_id: 0,
        title: '',
        name: '',
        slug: '',
        price: 0,
        quantity: 0,
        show_quantity: false,
        unlimited_quantity: false,
        sold: 0,
        sale_price: 0,
        sku: '',
        enabled: true,
        ins_date: '',
        ins_user: '',
        upd_date: '',
        upd_user: '',
    };
    return output;
}

export function getProductSalesChannelDefaultValues(): ProductSalesChannel {
    let output: ProductSalesChannel = {
        sale_id: 0,
        sale_uuid: '',
        product_id: 0,
        company_id: 0,
        company_name: '',
        category_id: 0,
        category_name: '',
        enabled: true,
        ins_date: '',
        ins_user: '',
        upd_date: '',
        upd_user: '',
    };
    return output;
}

export function getProductRelatedDefaultValues(): ProductRelated {
    let output: ProductRelated  = {
        id: 0,
        product_id: 0,
        product_id_related: 0,
        name: '',        
        company: '',
        image: getAttachmentDefaultValues(),
    };
    return output;
}

export function getBundleDefaultValues(): Bundle {
    let output: Bundle = {
        bundle_id: 0,
        company_id: 0,
        company_name: '',        
        slug: '',
        title: '',
        description: '',
        background_color: '',
        image: getAttachmentDefaultValues(),
        enabled: false,
        products: [],
        ins_date: '',
        ins_user: '',
        upd_date: '',
        upd_user: '',
    };
    return output;
}

export function getCategoryDefaultValues(subcategory: boolean = true): Category {
    let output: Category = {
        category_id: 0,
        parent_category_id: 0,
        company_id: 0,
        company_name: '',
        name: '',
        slug: '',
        enabled: true,
        order_number: 0,
        image: getAttachmentDefaultValues(),
        children: [],
        ins_date: '',
        ins_user: '',
        upd_date: '',
        upd_user: '',
        tab_value: '1',
        subcategory: subcategory ? getCategoryDefaultValues(false) : null,
        show_subcategory_modal: false,
    };
    return output;
}

export function getOrderDefaultValues(): Order {
    let output: Order = {

        id: '',
        order_id: 0,  
        company_name: '',
        total: 0,
        subtotal: 0,
        delivery_fee: 0,
        discount: 0,
        ccy: 'RON',
        status: {
            status: 0,
            name: '',
            serial: 0,
            color: '',
        },
        
        ins_date: '',
        ins_user: '',
        upd_date: '',
        upd_user: '',
        products: [],
        documents: [],
        emails: [],

        //campuri necesare interfetei    
        tab_value: "1",
    };
    return output;
}


export function getDishDefaultValues(): Dish {
    let output: Dish = {
        data: {
            menu_dish_id: 0,
            dish_id: 0,
            meal_id: 0,
            title: '',
            kcal: 0,
            description: '',
            enabled: false,
            ins_date: '',
            ins_user: '',
            upd_date: '',
            upd_user: '',
        },
        picture: {
            picture_file: undefined,
            picture_base64: '',
            picture_uploaded: false,
        },
        ingredients: [],
    };
    return output;
}


export function getSearchPaginationResultDefaultValues(): SearchPaginationResult {
    let output: SearchPaginationResult = {
        RowCount: 0,
        First: -1,
        Prev: -1,
        Current: 0,
        Next: -1,
        Last: -1,
        AfterIdNext: -1,
    };

    return output;
}

export function getSearchPaginationInputDefaultValues(): SearchPaginationInput {
    let output: SearchPaginationInput = {
        PageSize: 20,
        PageNumber: 0,
        AfterId: 0,
    };
    return output;
}

export interface UsersSearchResult extends SearchPaginationResult {
    Data: UserData[];
}

export interface ReportsSearchResult extends SearchPaginationResult {
    Data: Report[];
}

export interface CompaniesSearchResult extends SearchPaginationResult {
    Data: Company[];
}

export interface BundlesSearchResult extends SearchPaginationResult {
    Data: Bundle[];
}

export interface CategoriesSearchResult extends SearchPaginationResult {
    Data: Category[];
}

export interface ProductsSearchResult extends SearchPaginationResult {
    Data: Product[];
}

export interface ProductsReviewsSearchResult extends SearchPaginationResult {
    Data: ProductReview[];
}


export interface OrdersSearchResult extends SearchPaginationResult {
    Data: Order[];
}

export interface TransactionsSearchResult extends SearchPaginationResult {
    Data: Transaction[];
}