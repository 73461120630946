import * as React from 'react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import PropTypes from 'prop-types';
import {
    AppBar,
    Badge,
    Box,
    Hidden,
    IconButton,
    Toolbar
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';

import {
    LockOutlined as LockIcon,    
    PersonAddAlt as UserPlusIcon,
    Logout as LogOutIcon,
} from '@mui/icons-material';

import Logo from './Logo';

const DashboardNavbar = ({ onMobileNavOpen, auth, ...rest }) => {
    const [notifications] = useState([]);

    return (
        <AppBar
            elevation={0}
            {...rest}
        >
            <Toolbar>
                <RouterLink to="/">
                    <Logo />
                </RouterLink>
                <Box sx={{ flexGrow: 1 }} />
                <Hidden xlDown>
                    {auth.isAuthenticated ?
                        <React.Fragment>
                            <IconButton color="inherit" size="large">
                                <Badge
                                    badgeContent={notifications.length}
                                    color="primary"
                                    variant="dot"
                                >
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                            <IconButton component={RouterLink} color="inherit" size="large" to={`${ApplicationPaths.LogOut}`} title="Logout">
                                <LogOutIcon />
                            </IconButton>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <IconButton component={RouterLink} color="inherit" size="large" to={`${ApplicationPaths.Register}`} title="Register">
                                <UserPlusIcon />
                            </IconButton>
                            <IconButton component={RouterLink} color="inherit" size="large" to={`${ApplicationPaths.Login}`} title="Login">
                                <LockIcon />
                            </IconButton>
                        </React.Fragment>
                    }
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

DashboardNavbar.propTypes = {
    onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
