import * as Models from "../models/Models";
import * as utils from '../utils/Utils';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  FetchReviewsState {
    formData: FormData;
}

export class FormData extends Models.SearchPaginationInput {
    start_date: string = "";
    end_date: string = "";
    name: string = "";
    company_id: number = 0;
    enabled: string = "0";
}


const unloadedState: FetchReviewsState = {
    formData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        start_date: utils.getDateISO(),
        end_date: utils.getDateISO(),
        name: "",
        company_id: 0,
        enabled: "0",
    }
};



/* new reducer */

const fetchReviewsSlice = createSlice({
    name: 'FetchReviews',
    initialState: unloadedState,
    reducers: {
      setFetchReviewsFormData(state, action: PayloadAction<FormData>) {
        state.formData = action.payload
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = fetchReviewsSlice
  // Extract and export each action creator by name
  export const { setFetchReviewsFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer