import * as React from 'react';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux'
import * as FetchReviewsStore from '../store/FetchReviews';
import * as Validator from "../validator/Validator";
import * as Navigation from "../navigation/Navigation";
import { Select, Grid, TextField, Button, MenuItem, Input, InputLabel } from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'

import {
    Box,
    Card,
    Switch,
    ListItemAvatar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';

import missingImage from '../icons/missing-image.jpg';
import { useChangeState } from '../utils/useChangeState';
import { RootState, useAppDispatch } from '../store/configureStore';
import { useDeleteProductReviewMutation, useGetListCompaniesQuery, useGetListsQuery, useGetProductsReviewsQuery, useSaveProductReviewToggleValidMutation } from '../store/apiSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import { setError } from '../store/ApiInterface';


export const FetchReviews : React.FC = (props) => {

    const dispatch = useAppDispatch();

    const storeState = useSelector((state: RootState) => state.fetchReviews)

    const [state, setState] = useState<FetchReviewsStore.FormData>({
        ...storeState.formData,
    });    
    
    const changeState = useChangeState(state, setState);

    const [searchState, setSearchState] = useState<FetchReviewsStore.FormData>({
        ...storeState.formData,
    });
    
    const { data: yesNoList } = useGetListsQuery('1');
    const { data: pageSizeList } = useGetListsQuery('3');
    const { data: companies } = useGetListCompaniesQuery({ default_value: '0', default_description: 'All'});

    const { data } = useGetProductsReviewsQuery((yesNoList != null && pageSizeList != null && companies != null) ? searchState : skipToken) //asteptam sa vina lista yesNo si pageSize apoi apelam cautarea
    
    const [ saveToggle ] = useSaveProductReviewToggleValidMutation();
    const [ deleteReview ] = useDeleteProductReviewMutation();


    const handleSearch = (event: any) => {
        event.preventDefault();
        if (handleValidation()){
            setSearchState({ ...state, PageNumber: 0 })
        }
    }

    const handleChangePageSize = (event: any) => {
        if (handleValidation()){
            setState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  })
            setSearchState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  }) //nu avem optiunea de a modifica state-ul si a astepta cu "await" sa fie efectuata modificarea; asa ca apelam schimbarea de pagina in cele 2 state-uri explicit (asta ca sa fie si efectuata apelarea API-ului si sa ramana si in state-ul responsabil de UI)
        }
    }

    const handleChangeStartDate = (newValue: Date | null) => {
        setState({ ...state, start_date: utils.formatDate(newValue) });
    }

    const handleChangeEndDate = (newValue: Date | null) => {
        setState({ ...state, end_date: utils.formatDate(newValue) });
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var name = state.name;

        var StartDate = state.start_date;
        var EndDate = state.end_date;

        if (!Validator.isDateISO(StartDate)) {
            err += "Start Date is invalid!\n";
            result = false;
        }

        if (!Validator.isDateISO(EndDate)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (name != null && name.length > 0 && !Validator.isAlphaNumericAndSpace(name)) {
            err += "Name should be alpha numetic!\n";
            result = false;
        }
        if (name != null && name.length > 100) {
            err += "Name should not exceed 100 characters!\n";
            result = false;
        }

        if (!result) {
            dispatch(setError(err));
        }
        return result;
    }

    const renderSearchBox = () => {
        return (
            <fieldset>
                <legend>Search criteria</legend>
                <Grid container spacing={5}>

                    <Grid item xs={2}>
                        <InputLabel id="labelDatelId">
                            Start Date
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                format="dd.MM.yyyy"
                                value={new Date(state.start_date)}
                                onChange={handleChangeStartDate}
                                slotProps={{ textField: { variant: 'standard' } }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={2}>
                        <InputLabel id="labelDatelId">
                            End Date
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                format="dd.MM.yyyy"
                                value={new Date(state.end_date)}
                                onChange={handleChangeEndDate}
                                slotProps={{ textField: { variant: 'standard' } }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={2}>
                        <TextField
                            name="name"
                            variant="standard"
                            label="Name"
                            value={state.name}
                            onChange={changeState}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <InputLabel shrink id="labelComapny">
                            Company
                        </InputLabel>
                        <Select
                            name="company_id"
                            labelId="labelComapny"
                            value={companies? state.company_id : ''}
                            input={<Input />}
                            MenuProps={utils.MenuProps}
                            onChange={(e) => changeState(e)}
                        >
                            {companies?.map(o =>
                                <MenuItem key={o.company_id} value={o.company_id}>{o.name}</MenuItem>
                            )}
                        </Select>
                    </Grid>
                    
                    <Grid item xs={2}>
                        <InputLabel shrink id="labelEnabled">
                            Enabled
                        </InputLabel>
                        <Select
                            name="enabled"
                            labelId="labelEnabled"
                            value={yesNoList? state.enabled : ''}
                            input={<Input />}
                            MenuProps={utils.MenuProps}
                            onChange={(e) => changeState(e)}
                        >
                            {yesNoList?.map(o =>
                                <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel shrink id="labelPageSize">
                            Page size
                        </InputLabel>
                        <Select
                            id="PageSize"
                            labelId="labelPageSize"
                            value={pageSizeList? state.PageSize : ''}
                            input={<Input />}
                            MenuProps={utils.MenuProps}
                            onChange={handleChangePageSize}
                        >
                            {pageSizeList?.map(o =>
                                <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styled.buttonsBox}>
                            <Button variant="contained" color="primary" onClick={handleSearch}>
                                Search
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </fieldset>
        );
    }

    const renderSearchResult = useMemo(() => {

        const handleDelete = (review_id: number) => {
            if (!window.confirm("Do you want to delete the review?"))
                return;
            else {
                deleteReview(review_id);
            }
        }
    
        const handleToggleValid = (review_id: number) => {
            saveToggle(review_id);
        }

        const handleNavigation = (pageNumber: number) => {
            setSearchState((prevState) => ({ ...prevState, PageNumber: pageNumber }));
        }

        return (
            data &&
                <div>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <fieldset>
                                <legend>Search results</legend>
                                <Card>
                                    <Box>
                                        <Table>
                                            <TableHead>
                                                <TableRow key={0}>
                                                    <TableCell>
                                                        Review Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Product
                                                    </TableCell>
                                                    <TableCell>
                                                        Title
                                                    </TableCell>
                                                    <TableCell>
                                                        Message
                                                    </TableCell>
                                                    <TableCell>
                                                        Rating
                                                    </TableCell>
                                                    <TableCell>
                                                        Name
                                                    </TableCell>
                                                    <TableCell>
                                                        Email
                                                    </TableCell>
                                                    <TableCell>
                                                        Valid
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: "200px" }}>
                                                        Delete
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.Data.map((item) => (
                                                    <TableRow
                                                        hover
                                                        key={item.review_id}
                                                    >
                                                        <TableCell>
                                                            {item.review_id}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box
                                                                sx={{
                                                                    alignItems: 'center',
                                                                    display: 'flex'
                                                                }}
                                                            >
                                                                <ListItemAvatar>
                                                                    <img
                                                                        src={item.product_thumbnail == null ? missingImage : item.product_thumbnail}
                                                                        alt=''
                                                                        style={{
                                                                            height: 48,
                                                                            width: 48
                                                                        }}
                                                                    />
                                                                </ListItemAvatar>
                                                                <Typography
                                                                    color="textPrimary"
                                                                    variant="body1"
                                                                >
                                                                    {item.product_name}
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.title}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.message}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.rating}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.email}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Switch
                                                                checked={item.valid}
                                                                onChange={(e) => handleToggleValid(item.review_id)}
                                                                name="Enabled"
                                                                color="primary"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                size="small"
                                                                onClick={(id) => handleDelete(item.review_id)}
                                                                >
                                                                Delete
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </Card>

                                <div className="form-group text-center">
                                    <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.First)} onClick={(id) => handleNavigation(data.First)}>{Navigation.displayNavigationText('First', data.First)}</Button>
                                    <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.Prev)} onClick={(id) => handleNavigation(data.Prev)}>{Navigation.displayNavigationText('Previous', data.Prev)}</Button>
                                    <Button variant="outlined" disabled={true}>{Navigation.displayNavigationText('Current', data.Current)}</Button>
                                    <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.Next)} onClick={(id) => handleNavigation(data.Next)}>{Navigation.displayNavigationText('Next', data.Next)}</Button>
                                    <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.Last)} onClick={(id) => handleNavigation(data.Last)}>{Navigation.displayNavigationText('Last', data.Last)}</Button>
                                </div>
                            </fieldset>
                        </Grid>
                    </Grid>
                </div>
        );
    }, [data, deleteReview, saveToggle]);

    return (
        <React.Fragment>
            <h1 id="tabelLabel">Manage Reviews</h1>
            {renderSearchBox()}
            {renderSearchResult}
        </React.Fragment>
    );
}

export default FetchReviews;