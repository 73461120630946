import * as Models from "../models/Models";
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  FetchCompaniesState {
    formData: FormData;
}

export class FormData extends Models.SearchPaginationInput {
    name: string = "";
    enabled: string = "0";
}


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: FetchCompaniesState = {
    formData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        name: "",
        enabled: "0",
    }
};


/* new reducer */

const fetchClientsSlice = createSlice({
    name: 'FetchCompanies',
    initialState: unloadedState,
    reducers: {
      setFetchCompaniesFormData(state, action: PayloadAction<FormData>) {
        state.formData = action.payload
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = fetchClientsSlice
  // Extract and export each action creator by name
  export const { setFetchCompaniesFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer