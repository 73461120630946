import * as ApiInterface from './ApiInterface';
import * as FetchUsers from './FetchUsers';
import * as FetchCompanies from './FetchCompanies';
import * as FetchBundles from './FetchBundles';
import * as FetchCategories from './FetchCategories';
import * as FetchProducts from './FetchProducts';
import * as FetchReviews from './FetchReviews';
import * as FetchOrders from './FetchOrders';
import * as FetchTransactions from './FetchTransactions';
import * as Reports from './Reports';



// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    ApiInterface: ApiInterface.default,
    fetchUsers: FetchUsers.default,
    fetchCompanies: FetchCompanies.default,
    fetchBundles: FetchBundles.default,
    fetchCategories: FetchCategories.default,
    fetchProducts: FetchProducts.default,
    fetchReviews: FetchReviews.default,
    fetchOrders: FetchOrders.default,
    fetchTransactions: FetchTransactions.default,
    reports: Reports.default,
};