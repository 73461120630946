import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Snackbar, Alert } from '@mui/material';
import { RootState, useAppDispatch } from '../store/configureStore'
import { resetError, resetInfo } from '../store/ApiInterface';



export const ApiInterface : React.FC = (props) => {

    const storeState = useSelector((state: RootState) => state.ApiInterface)

    const dispatch = useAppDispatch();

    useEffect(() => {
        
        //https://able.bio/drenther/track-page-visibility-in-react-using-render-props--78o9yw5        
        document.addEventListener('focusin', e => handleFocusIn(e));        

        return () => {
          // unsubscribe event          
          document.removeEventListener("focusin", handleFocusIn);

        };
      }, []);    

    const handleFocusIn = (e: Event) => {
        //ca sa mearga tinymce in modala (altfel unele butoane sunt disabled cand este folosit editorul tinymce in modala de ex image nu se poate adauga)
        //https://www.tiny.cloud/docs/integrations/bootstrap/#usingtinymceinabootstrapdialog

        if (e.target != null){
            if (e.target instanceof HTMLElement){
                if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                    e.stopImmediatePropagation();
                }
            }
        }
    };
    
    const handleCloseError = () => {
        dispatch(resetError())
    }

    const handleCloseInfo = () => {        
        dispatch(resetInfo())
    }

    return (
        <>
            {
                storeState.error && (
                    <div>
                        <Snackbar
                            open={storeState.error != null}
                            autoHideDuration={6000}
                            onClose={handleCloseError}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        >
                            <Alert elevation={6} variant="filled" onClose={handleCloseError} severity="error" >{storeState.error}</Alert>
                        </Snackbar>
                    </div>
                )
            }
            {
                storeState.info && (
                    <div>
                        <Snackbar
                            open={storeState.info != null}
                            autoHideDuration={6000}
                            onClose={handleCloseInfo}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        >
                            <Alert elevation={6} variant="filled" onClose={handleCloseInfo} severity="success">{storeState.info}</Alert>
                        </Snackbar>
                    </div>
                )
            }
        </>
    )
}

export default ApiInterface;



