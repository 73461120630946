 import React, { useEffect, useImperativeHandle, useState } from 'react';
 import { Editor } from '@tinymce/tinymce-react';


interface Props {
  description_html: string;  
}

export type TinyEditorHandle = {
  getValue: () => string;
};

//folosim varianta self host pt ca in varianta clound dupa 2000 de load-uri pe luna se plateste o taxa
//https://www.tiny.cloud/docs/tinymce/latest/react-pm-host/
//ca sa faca update manual la sursele din /public/tinymce trebuie executat "npm run postinstall"
const TinyEditor = React.forwardRef<TinyEditorHandle, Props>(({ description_html }: Props, ref) => {
   
  const [value, setValue] = useState(description_html ?? '');  
  useEffect(() => setValue(description_html ?? ''), [description_html]);
  
  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    }
  }));

  return (
    <>       
      <Editor
        initialValue={description_html}
        value={value}
        //apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
        tinymceScriptSrc='/tinymce/tinymce.min.js'
        init={{
          license_key: 'gpl',
          height: 500,
          menubar: true,
          relative_urls: false,
          remove_script_host : true,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
            'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | formatselect | fontselect | fontsizeselect | ' +
          'bold underline italic forecolor backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | link image | table |' +
          'removeformat | help',
          
        }}
        //onChange={handleEditorChange}
        onEditorChange={(newValue, editor) => setValue(newValue)}
      />
    </>
  );
});

export default TinyEditor;
