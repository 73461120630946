import * as React from 'react';
import * as Validator from "../validator/Validator";
import * as Models from '../models/Models';
import {
    Grid,
    TextField,    
    Button,
    Switch,
    Select,
    Input,
    MenuItem,    
    Box,
    Dialog,
    Tab,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from '@mui/material';
import { DeleteForever as DeleteForeverIcon, ModeEdit as ModeEditIcon } from '@mui/icons-material';
import { DialogTitle, DialogContent } from './StyledComponents'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import ImageUpload from './ImageUpload'
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useChangeState } from '../utils/useChangeState';
import { useGetCategoryQuery, useGetListCompaniesQuery, useSaveCategoryMutation, useSaveAttachmentMutation, useDeleteSubcategoryMutation } from '../store/apiSlice';
import { useAppDispatch } from '../store/configureStore';
import { setError } from '../store/ApiInterface';


export const EditCategory : React.FC = (props) => {

    const params = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const id = params.id != null ? parseInt(params.id) : 0;
    const [state, setState] = useState({
        ...Models.getCategoryDefaultValues(),

        //campuri in afara modelului, necesare pt functionarea interfetei
        tab_value: "1" as string,
        subcategory: Models.getCategoryDefaultValues() as Models.Category | null,
        show_subcategory_modal: false as boolean,
    });
    
    const changeState = useChangeState(state, setState);

    
    const { data: category } = useGetCategoryQuery(id)
    const { data: companies } = useGetListCompaniesQuery({ default_value: '0', default_description: 'Select company'});
    const [ saveCategory, { isSuccess, reset } ] = useSaveCategoryMutation();
    const [ saveAttachment ] = useSaveAttachmentMutation();
    const [ deleteSubcategory ] = useDeleteSubcategoryMutation();

    useEffect(() => {
        if (category){
            setState((prevState) => ({
                ...prevState,
                ...category
            }))
        }
    }, [category]);

    useEffect(() => {
        if (isSuccess){
            reset();
            if (state.show_subcategory_modal){
                setState((prevState) => ({
                    ...prevState,
                    show_subcategory_modal: false
                }))                
            }            
        }
    }, [isSuccess, state.show_subcategory_modal, reset]);

    // This will handle the submit form event.  
    const handleSave = (event: any) => {
        event.preventDefault();
        if (handleValidation()) {
            saveCategory(state);
        }
    }
    // This will handle Cancel button click event.  
    const handleClose = (e: any) => {
        e.preventDefault();
        navigate("/categories");
    }

    const handleChangePicture = (image_file: File | undefined) => {
        
        if (handleValidationChangePicture(image_file) && image_file !== undefined) {

            const formData = new FormData();
            formData.append("related_id", state.category_id.toString());
            formData.append("file", image_file);
            formData.append("type", "category");
            formData.append("company_id", state.company_id.toString());
            saveAttachment({ picture: formData, tag: 'EditCategory', tag_id: id });
        }
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setState({ ...state, tab_value: newValue });
    };

    const handleAddSubcategory = () => {
        setState({
            ...state, 
            show_subcategory_modal: true,
            subcategory: {
                ...Models.getCategoryDefaultValues(),
                parent_category_id: state.category_id,
                company_id: state.company_id,
            }
        });
    }

    const handleEditSubcategory = (category: Models.Category) => {
        setState({
            ...state, 
            show_subcategory_modal: true,
            subcategory: { ...category }
        });
    }

    const handleDeleteSubcategory = (subcategory_id: number, name: string) => {
        if (!window.confirm("Do you want to delete the subcategory: " + name))
            return;
        else {            
            deleteSubcategory({id: subcategory_id, category_id: id});
        }
    }

    const handleCloseSubcategoryModal = () => {
        setState({
            ...state,
            show_subcategory_modal: false,
        });
    }
    
    const handleSaveSubcategory = (event: any) => {
        event.preventDefault();
        if (handleValidationSubcategory()) {
            setState({ ...state, show_subcategory_modal: false });
            saveCategory(state.subcategory!);
        }
    }

    const handleValidationSubcategory = () => {
        let result = true;
        let err = '';

        var name = state.subcategory!.name;
        var company_id = state.subcategory!.company_id;
        var category_id = state.category_id;
        var parent_category_id = state.subcategory!.parent_category_id;

        if (!Validator.isAlphaNumericAndSpaceUnderscore(name)) {
            err += "Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (name.length <= 0 || name.length > 100) {
            err += "Name should be between 1 and 100 characters!\n";
            result = false;
        }

        if (category_id <= 0) {
            err += "First save the main category!\n";
            result = false;
        }

        if (company_id <= 0) {
            err += "Subcategory company is not set!\n";
            result = false;
        }

        if (parent_category_id <= 0) {
            err += "Subcategory parent category id is not set!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var name = state.name;
        var company_id = state.company_id;

        if (!Validator.isAlphaNumericAndSpaceUnderscore(name)) {
            err += "Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (name.length <= 0 || name.length > 100) {
            err += "Name should be between 1 and 100 characters!\n";
            result = false;
        }

        if (company_id <= 0) {
            err += "Select the company!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    const handleValidationChangePicture = (image_file: File | undefined) => {
        let result = true;
        let err = '';

        if (state.category_id <= 0) {
            err += "First save the category then change the picture!\n";
            result = false;
        }

        if (image_file === undefined) {
            err += "Picture is not uploaded!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    // Returns the HTML Form to the render() method.  
    const renderCreateForm = () => {
        return (
            <>
                <Dialog
                        onClose={handleCloseSubcategoryModal}
                        aria-labelledby="customized-dialog-title-edit-sales-channels"
                        open={state.show_subcategory_modal}
                        fullWidth={true}
                        maxWidth={"lg"}
                    >
                        <DialogTitle id="customized-dialog-title-edit-sales_channels" onClose={handleCloseSubcategoryModal}>
                            Edit sales channels
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={5}>
                                <Grid item xs={6}>
                                    <Grid container spacing={5}>
                                        
                                        <Grid item xs={4}>
                                            <strong>Name:</strong>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                name="name"
                                                variant="standard"
                                                value={state.subcategory!.name}
                                                onChange={e => changeState(e, 'subcategory')}
                                                required
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <strong>Order Number:</strong>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                name="order_number"
                                                variant="standard"
                                                value={state.subcategory!.order_number}
                                                type="number"
                                                onChange={e => changeState(e, 'subcategory')}
                                                required
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <strong>Enabled:</strong>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Switch
                                                name="enabled"
                                                checked={state.subcategory!.enabled}
                                                onChange={e => changeState(e, 'subcategory')}
                                                color="primary"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={5}>
                                        {state.subcategory!.ins_date !== "" ?
                                            <>
                                                <Grid item xs={4}>
                                                    <strong>Created at:</strong>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {moment(state.subcategory!.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                                </Grid></> : ""
                                        }
                                        {state.subcategory!.ins_user !== "" ?
                                            <>
                                                <Grid item xs={4}>
                                                    <strong>Created by:</strong>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {state.subcategory!.ins_user}
                                                </Grid></> : ""
                                        }
                                        {state.subcategory!.upd_date !== "" ?
                                            <>
                                                <Grid item xs={4}>
                                                    <strong>Modified at:</strong>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {moment(state.subcategory!.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                                </Grid></> : ""
                                        }
                                        {state.subcategory!.upd_user !== "" ?
                                            <>
                                                <Grid item xs={4}>
                                                    <strong>Modified by:</strong>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {state.subcategory!.upd_user}
                                                </Grid></> : ""
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} alignItems="center" justifyContent="center">
                                    <div className="form-group text-center">
                                        <Box sx={styled.buttonsBox}>
                                            <Button variant="contained" color="primary" onClick={handleSaveSubcategory}>
                                                Save
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={handleCloseSubcategoryModal}>
                                                Close
                                            </Button>
                                        </Box>
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <div>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={state.tab_value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                                        <Tab label="Basic data" value="1" />
                                        <Tab label="Subcategories" value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <form name="formEditCategory" id="formEditCategory" onSubmit={handleSave}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={6}>
                                                <Grid container spacing={5}>

                                                    <Grid item xs={4}>
                                                        <strong>Comapany:</strong>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Select
                                                            name="company_id"                                            
                                                            value={companies? state.company_id : ''}
                                                            input={<Input />}
                                                            MenuProps={utils.MenuProps}
                                                            onChange={e => changeState(e)}
                                                            disabled={state.category_id !== 0}
                                                        >
                                                            {companies?.map(o =>
                                                                <MenuItem key={o.company_id} value={o.company_id}>{o.name}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </Grid>


                                                    <Grid item xs={4}>
                                                        <strong>Name:</strong>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <TextField
                                                            name="name"
                                                            variant="standard"
                                                            value={state.name}
                                                            onChange={changeState}
                                                            required
                                                        />
                                                    </Grid>

                                                    <Grid item xs={4}>
                                                        <strong>Order Number:</strong>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <TextField
                                                            name="order_number"
                                                            variant="standard"
                                                            value={state.order_number}
                                                            type="number"
                                                            onChange={changeState}
                                                            required
                                                        />
                                                    </Grid>

                                                    <Grid item xs={4}>
                                                        <strong>Enabled:</strong>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Switch
                                                            name="enabled"
                                                            checked={state.enabled}
                                                            onChange={(e) => changeState(e)}
                                                            color="primary"
                                                        />
                                                    </Grid>
                                                    {state.ins_date !== "" ?
                                                        <>
                                                            <Grid item xs={4}>
                                                                <strong>Created at:</strong>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {moment(state.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                                            </Grid></> : ""
                                                    }
                                                    {state.ins_user !== "" ?
                                                        <>
                                                            <Grid item xs={4}>
                                                                <strong>Created by:</strong>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {state.ins_user}
                                                            </Grid></> : ""
                                                    }
                                                    {state.upd_date !== "" ?
                                                        <>
                                                            <Grid item xs={4}>
                                                                <strong>Modified at:</strong>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {moment(state.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                                            </Grid></> : ""
                                                    }
                                                    {state.upd_user !== "" ?
                                                        <>
                                                            <Grid item xs={4}>
                                                                <strong>Modified by:</strong>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {state.upd_user}
                                                            </Grid></> : ""
                                                    }
                                                    <Grid item xs={12}>
                                                        <Box sx={styled.buttonsBox}>
                                                            <Button variant="contained" color="primary" type="submit">
                                                                Save
                                                            </Button>
                                                            <Button variant="contained" color="primary" onClick={handleClose}>
                                                                Close
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>                            
                                                <ImageUpload
                                                    onUploadImage={(image) => handleChangePicture(image)}
                                                    width={100}
                                                    image={state.image}
                                                />
                                            </Grid>                        
                                        </Grid>
                                    </form>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <Card>
                                                <CardHeader title="Subcategories" />
                                                <Divider />
                                                {state.children.length > 0 ?
                                                    <CardContent>
                                                        <Box>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow key={0}>
                                                                        <TableCell>
                                                                            Name
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            Slug
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            Order Number
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            Enabled
                                                                        </TableCell>
                                                                        <TableCell style={{ minWidth: "100px" }}>
                                                                            Delete
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {state.children.map((item) => (
                                                                        <TableRow
                                                                            hover
                                                                            key={item.category_id}
                                                                        >
                                                                            <TableCell>
                                                                                {item.name}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {item.slug}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {item.order_number}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {item.enabled ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Box sx={styled.buttonsBox}>
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        size="small"
                                                                                        endIcon={<ModeEditIcon />}
                                                                                        onClick={(id) => handleEditSubcategory(item)}
                                                                                    >
                                                                                        Edit
                                                                                    </Button>

                                                                                    <Button
                                                                                        variant="contained"
                                                                                        color="secondary"
                                                                                        size="small"
                                                                                        endIcon={<DeleteForeverIcon />}
                                                                                        onClick={(id) => handleDeleteSubcategory(item.category_id, item.name)}
                                                                                    >
                                                                                        Delete
                                                                                    </Button>
                                                                                </Box>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </Box>
                                                    </CardContent>
                                                    :
                                                    "There are no subcategories. To add subcategories use the button 'ADD SUBCATEGORY'"
                                                }
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={(id) => handleAddSubcategory()}
                                                disabled = {state.category_id === 0}
                                            >
                                                Add Subcategory
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </div>
            </>
        )
    }
    
    return (
        <div>
            <h3>Category definition</h3>
            <hr />
            {renderCreateForm()}
        </div>
    );
}


export default EditCategory;