import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    List,
    Typography
} from '@mui/material';

import {    
    BusinessCenter as BusinessCenterIcon,
    Fastfood as FastfoodIcon,
    Discount as DiscountIcon,
    Assessment as AssessmentIcon,
    LockOutlined as LockIcon,
    PersonAddAlt as UserPlusIcon,
    Logout as LogOutIcon,
    EditLocationAltOutlined as EditIcon,
    Star as StarIcon,
    ShoppingCart as ShoppingCartIcon,
    SyncAlt as SyncAltIcon,
} from '@mui/icons-material';


import NavItem from './NavItem';
import {generateAvatar} from '../../utils/Utils';

const items_auth = [
    {
        href: '/categories',
        icon: BusinessCenterIcon,
        title: 'Categories'
    },
    {
        href: '/bundles',
        icon: DiscountIcon,
        title: 'Bundles'
    },
    {
        href: '/products',
        icon: FastfoodIcon,
        title: 'Products'
    },
    {
        href: '/companies',
        icon: BusinessCenterIcon,
        title: 'Companies'
    },
    {
        href: '/reviews',
        icon: StarIcon,
        title: 'Reviews'
    },
    {
        href: '/orders',
        icon: ShoppingCartIcon,
        title: 'Orders'
    },
    {
        href: '/transactions',
        icon: SyncAltIcon,
        title: 'Transactions'
    },
    {
        href: '/reports',
        icon: AssessmentIcon,
        title: 'Reports'
    },
    {
        href: '/fetchusers',
        icon: UserPlusIcon,
        title: 'Users'
    },
    {
        href: `${ApplicationPaths.Profile}`,
        icon: EditIcon,
        title: 'Profile'
    },
    {
        href: `${ApplicationPaths.LogOut}`,
        icon: LogOutIcon,
        title: 'Logout'
    }
];

const items_not_auth = [
    {
        href: `${ApplicationPaths.Login}`,
        icon: LockIcon,
        title: 'Login'
    },
    {
        href: `${ApplicationPaths.Register}`,
        icon: UserPlusIcon,
        title: 'Register'
    }
];

const DashboardSidebar = ({ onMobileClose, openMobile, auth }) => {
    const location = useLocation();
    const items = useMemo(() => {
        const isAdmin = auth.roles.includes('Admin');
        const isCompany = auth.roles.includes('Company_Regular') || auth.roles.includes('Company_Manager');

        if (auth.isAuthenticated) {
            if (isAdmin || isCompany){
                return items_auth;
            }
        }
        else {
            return items_not_auth;            
        }

        return [];
        
      }, [auth.isAuthenticated, auth.roles]);
    

      useEffect(() => {
        if (onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname, onMobileClose]);

    

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            {auth.isAuthenticated &&
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        p: 2
                    }}
                >
                    <Avatar
                        component={RouterLink}
                        //src={user.avatar}
                        src={auth.profile_picture_base64 == null ? generateAvatar(auth.full_name) /*missingImage*/ : auth.profile_picture_base64}
                        sx={{
                            cursor: 'pointer',
                            width: 64,
                            height: 64
                        }}
                        to={`${ApplicationPaths.Profile}`}
                    />
                    <Typography
                        color="textPrimary"
                        variant="h5"
                    >
                        {auth.full_name}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        {auth.work_place}
                    </Typography>
                </Box>
            }
            <Divider />
            <Box sx={{ p: 2 }}>
                <List>
                    {
                        items.map((item) => (
                            <NavItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                            />
                        ))}
                </List>
            </Box>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                    PaperProps={{
                        sx: {
                            width: 256
                        }
                    }}
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: 256,
                            top: 64,
                            height: 'calc(100% - 64px)'
                        }
                    }}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
    onMobileClose: () => {
    },
    openMobile: false
};

export default DashboardSidebar;
