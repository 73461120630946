import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux'
import * as FetchOrdersStore from '../store/FetchOrders';
import * as Validator from "../validator/Validator";
import * as Navigation from "../navigation/Navigation";
import { Select, Grid, TextField, Button, MenuItem, Input, InputLabel } from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import moment from 'moment';

import {    
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { useChangeState } from '../utils/useChangeState';
import { useGetListOrderStatusQuery, useGetListsQuery, useGetOrdersQuery } from '../store/apiSlice';
import { RootState, useAppDispatch } from '../store/configureStore';
import { skipToken } from '@reduxjs/toolkit/query';
import { setError } from '../store/ApiInterface';


export const FetchOrders : React.FC = (props) => {

    const navigate = useNavigate();    

    const dispatch = useAppDispatch();

    const storeState = useSelector((state: RootState) => state.fetchOrders)
    
    const [state, setState] = useState<FetchOrdersStore.FormData>({
        ...storeState.formData,
    });
    
    const changeState = useChangeState(state, setState);

    const [searchState, setSearchState] = useState<FetchOrdersStore.FormData>({
        ...storeState.formData
    });

    const { data: orderStatus } = useGetListOrderStatusQuery({ default_value: '0', default_description: 'All'});
    const { data: pageSizeList } = useGetListsQuery('3');
    const { data } = useGetOrdersQuery((orderStatus != null && pageSizeList != null) ? searchState : skipToken) //asteptam sa vina lista yesNo si pageSize apoi apelam cautarea
    

    const handleSearch = (event: any) => {
        event.preventDefault();
        if (handleValidation()){
            setSearchState({ ...state, PageNumber: 0 })
        }
    }

    const handleChangePageSize = (event: any) => {
        if (handleValidation()){
            setState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  })
            setSearchState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  }) //nu avem optiunea de a modifica state-ul si a astepta cu "await" sa fie efectuata modificarea; asa ca apelam schimbarea de pagina in cele 2 state-uri explicit (asta ca sa fie si efectuata apelarea API-ului si sa ramana si in state-ul responsabil de UI)
        }
    }

    const handleChangeStartDate = (newValue: Date | null) => {
        setState({ ...state, start_date: utils.formatDate(newValue) });
    }

    const handleChangeEndDate = (newValue: Date | null) => {
        setState({ ...state,end_date: utils.formatDate(newValue) });
    }    


    const handleValidation = () => {
        let result = true;
        let err = '';

        var order_id = state.order_id;
        var order_uuid = state.order_uuid;
        var user_name = state.user_name;

        var StartDate = state.start_date;
        var EndDate = state.end_date;


        if (order_id != null && order_id.length > 0 && !Validator.isNumeric(order_id)) {
            err += "Order id should be numeric!\n";
            result = false;
        }
        if (order_id != null && order_id.length > 100) {
            err += "Order id should not exceed 100 characters!\n";
            result = false;
        }

        if (order_uuid != null && order_uuid.length > 0 && !Validator.isUuid(order_uuid)) {
            err += "Order uuid should be numeric!\n";
            result = false;
        }
        if (order_uuid != null && order_uuid.length > 100) {
            err += "Order uuid should not exceed 100 characters!\n";
            result = false;
        }


        if (user_name != null && user_name.length > 0 && !Validator.isEmail(user_name)) {
            err += "User name should be valid email!\n";
            result = false;
        }
        if (user_name != null && user_name.length > 100) {
            err += "User name should not exceed 100 characters!\n";
            result = false;
        }


        if (!Validator.isDateISO(StartDate)) {
            err += "Start Date is invalid!\n";
            result = false;
        }
        if (!Validator.isDateISO(EndDate)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (!result) {
            dispatch(setError(err));
        }
        return result;
    }

    const renderSearchBox = () => {
        return (
            <fieldset>
                <legend>Search criteria</legend>
                <Grid container spacing={5}>

                    <Grid item xs={2}>
                        <InputLabel id="labelStartDate">
                            Start Date
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                format="dd.MM.yyyy"
                                value={new Date(state.start_date)}
                                onChange={handleChangeStartDate}
                                slotProps={{ textField: { variant: 'standard' } }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={2}>
                        <InputLabel id="labelEndDate">
                            End Date
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                format="dd.MM.yyyy"
                                value={new Date(state.end_date)}
                                onChange={handleChangeEndDate}
                                slotProps={{ textField: { variant: 'standard' } }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={2}>
                        <InputLabel id="labelUserName">
                            User Name
                        </InputLabel>
                        <TextField
                            name="user_name"
                            variant="standard"
                            value={state.user_name}
                            onChange={changeState}
                        />
                    </Grid>

                    <Grid item xs={1}>
                        <InputLabel id="labelOrderId">
                            Order Id
                        </InputLabel>
                        <TextField
                            name="order_id"
                            variant="standard"
                            value={state.order_id}
                            onChange={changeState}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <InputLabel id="labelOrderUuid">
                            Order Uuid
                        </InputLabel>
                        <TextField
                            name="order_uuid"
                            variant="standard"
                            value={state.order_uuid}
                            onChange={changeState}
                        />
                    </Grid>

                    <Grid item xs={1}>
                        <InputLabel shrink id="labelStatus">
                            Status
                        </InputLabel>
                        <Select
                            name="status"
                            labelId="labelStatus"
                            value={orderStatus? state.status : ''}
                            input={<Input />}
                            MenuProps={utils.MenuProps}
                            onChange={(e) => changeState(e)}
                        >
                            {orderStatus?.map(o =>
                                <MenuItem key={o.status} value={o.status}>{o.name}</MenuItem>
                            )}
                        </Select>
                    </Grid>
                    
                    <Grid item xs={2}>
                        <InputLabel shrink id="labelPageSize">
                            Page size
                        </InputLabel>
                        <Select
                            id="PageSize"
                            labelId="labelPageSize"
                            value={pageSizeList? state.PageSize : ''}
                            input={<Input />}
                            MenuProps={utils.MenuProps}
                            onChange={handleChangePageSize}
                        >
                            {pageSizeList?.map(o =>
                                <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styled.buttonsBox}>
                            <Button variant="contained" color="primary" onClick={handleSearch}>
                                Search
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </fieldset>
        );
    }

    const renderSearchResult = useMemo(() => {

        const handleEdit = (order_id: number) => {
            navigate("/orders/edit/" + order_id);
        }

        const handleNavigation = (pageNumber: number) => {
            setSearchState((prevState) => ({ ...prevState, PageNumber: pageNumber }));
        }

        return (
            data &&
                <div>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <fieldset>
                                <legend>Search results</legend>
                                <Card>
                                    <Box>
                                        <Table>
                                            <TableHead>
                                                <TableRow key={0}>
                                                    <TableCell>
                                                        Order Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Company
                                                    </TableCell>
                                                    <TableCell>
                                                        Status
                                                    </TableCell>
                                                    <TableCell>
                                                        Total
                                                    </TableCell>
                                                    <TableCell>
                                                        Discount
                                                    </TableCell>
                                                    <TableCell>
                                                        Contact Person
                                                    </TableCell>
                                                    <TableCell>
                                                        User
                                                    </TableCell>
                                                    <TableCell>
                                                        Date
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: "200px" }}>
                                                        View
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.Data.map((item) => (
                                                    <TableRow
                                                        hover
                                                        key={item.order_id}
                                                    >
                                                        <TableCell>
                                                            {item.order_id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.company_name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.status.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {utils.formatPrice({amount: item.total, currencyCode: item.ccy, locale: 'ro'})}
                                                        </TableCell>
                                                        <TableCell>
                                                            {utils.formatPrice({amount: item.discount, currencyCode: item.ccy, locale: 'ro'})}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.checkout_details?.contact?.contact_person}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.ins_user}
                                                        </TableCell>
                                                        <TableCell>
                                                            {moment(item.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                onClick={(id) => handleEdit(item.order_id)}>
                                                                View
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </Card>

                                <div className="form-group text-center">
                                    <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.First)} onClick={(id) => handleNavigation(data.First)}>{Navigation.displayNavigationText('First', data.First)}</Button>
                                    <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.Prev)} onClick={(id) => handleNavigation(data.Prev)}>{Navigation.displayNavigationText('Previous', data.Prev)}</Button>
                                    <Button variant="outlined" disabled={true}>{Navigation.displayNavigationText('Current', data.Current)}</Button>
                                    <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.Next)} onClick={(id) => handleNavigation(data.Next)}>{Navigation.displayNavigationText('Next', data.Next)}</Button>
                                    <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.Last)} onClick={(id) => handleNavigation(data.Last)}>{Navigation.displayNavigationText('Last', data.Last)}</Button>
                                </div>
                            </fieldset>
                        </Grid>
                    </Grid>
                </div>
        );
    }, [data, navigate])

    return (
        <React.Fragment>
            <h1 id="tabelLabel">Manage Orders</h1>
            {renderSearchBox()}
            {renderSearchResult}
        </React.Fragment>
    );
}

export default FetchOrders;