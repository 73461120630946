import { useState, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';


const DashboardLayoutRoot = styled('div')(
    ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    })
);

const DashboardLayoutWrapper = styled('div')(
    ({ theme }) => ({
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        }
    })
);

const DashboardLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
});

const DashboardLayoutContent = styled('div')({
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
});

const DashboardLayout = ({ auth }) => {
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);   
    
    //folosim useCallback pt ca mobileCloseNav este folosit in DashboardSidebar in useEffect cand se schimba locatia;
    //daca se schimba mobileCloseNav atunci useEffect din DashboardSidebar se declanseaza (ca si cand location ar fi fost schimbat) si inchide meniul
    //cum era inaine "onMobileNavOpen={() => setMobileNavOpen(false)}" functia din argument se schimba la fiecare re-render si de aceea useEffect din DashboardSidebar declansa 
    const mobileCloseNav = useCallback(() =>{        
        setMobileNavOpen((open) => open ? false: open);
    }, [])

    const mobileOpenNav = useCallback(() =>{
        setMobileNavOpen(true);
    }, [])

    return (
        <DashboardLayoutRoot>
            <DashboardNavbar
                onMobileNavOpen={mobileOpenNav}
                auth={auth}
                 />
            <DashboardSidebar
                onMobileClose={mobileCloseNav}
                openMobile={isMobileNavOpen}
                auth={ auth }
            />
            <DashboardLayoutWrapper>
                <DashboardLayoutContainer>
                    <DashboardLayoutContent>
                        <Outlet />
                    </DashboardLayoutContent>
                </DashboardLayoutContainer>
            </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
    );
};

export default DashboardLayout;
