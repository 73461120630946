import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Validator from "../validator/Validator";
import * as Models from '../models/Models';
import {
    Box,
    Tab,
    Grid,
    Dialog,
    TextField,
    TextareaAutosize,
    Button,
    Switch,
    ListItemAvatar,
    Select,
    Input,
    MenuItem,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DeleteForever as DeleteForeverIcon, ModeEdit as ModeEditIcon } from '@mui/icons-material';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import FetchProducts from './FetchProducts'
import { DialogTitle, DialogContent } from './StyledComponents'
import moment from 'moment';
import TinyEditor, { TinyEditorHandle } from './TinyEditor';
import ImageUpload from './ImageUpload';
import FetchTransactions from './FetchTransactions';
import missingImage from '../icons/missing-image.jpg'; // with import
import Gallery from './Gallery';
import { useChangeState } from '../utils/useChangeState';
import { ProductSalesChannel, ProductVariation } from '../models/Models';
import { 
    useGetListCompaniesQuery,
    useGetCategoriesByCompanyIdQuery,
    useGetProductQuery,
    useSaveProductMutation,
    useSaveProductVariationMutation,
    useDeleteProductVariationMutation,
    useSaveProductSalesChannelMutation,
    useDeleteProductSalesChannelMutation,
    useSaveProductRelatedProductsMutation,
    useDeleteProductRelatedProductMutation,
    useSaveAttachmentMutation,
    useGetListCompaniesForSalesChannelQuery,
} from '../store/apiSlice';
import { setError } from '../store/ApiInterface';
import { useAppDispatch } from '../store/configureStore';



export const EditProduct : React.FC = (props) => {
    
    const params = useParams();
    
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const childTinyMceProductDescRef = useRef<TinyEditorHandle>(null);

    const id = params.id != null ? parseInt(params.id) : 0;

    const [state, setState] = useState({
        ...Models.getProductDefaultValues(),

        //campuri in afara modelului, necesare pt functionarea interfetei
        variation: Models.getProductVariationDefaultValues() as ProductVariation,
        sales_channel: Models.getProductSalesChannelDefaultValues() as ProductSalesChannel,
        selected_product_ids: [] as number[],
        show_variation_modal: false as boolean,
        show_sales_channels_modal: false as boolean,
        show_related_products_modal: false as boolean,
        tab_value: "1" as string
    });
    
    const changeState = useChangeState(state, setState);

    const { data: product_data } = useGetProductQuery(id);
    const { data: companies } = useGetListCompaniesQuery({ default_value: '0', default_description: 'Select company'});
    const { data: companies_for_sales_channel } = useGetListCompaniesForSalesChannelQuery({ product_id: id, default_value: '0', default_description: 'Select company'});
    const { data: categories } = useGetCategoriesByCompanyIdQuery(state.sales_channel.company_id, { skip: !state.show_sales_channels_modal });

    const [ saveProduct ] = useSaveProductMutation();
    const [ saveProductVariation, { isSuccess: isSuccessVariation, reset: resetVariation} ] = useSaveProductVariationMutation();
    const [ deleteProductVariation ] = useDeleteProductVariationMutation();
    const [ saveProductSalesChannel, { isSuccess: isSuccessSaleChannel, reset: resetSalesChannel } ] = useSaveProductSalesChannelMutation();
    const [ deleteProductSalesChannel ] = useDeleteProductSalesChannelMutation();
    const [ saveProductRelatedProducts, { isSuccess: isSuccessProductRelated, reset: resetProductRelated } ] = useSaveProductRelatedProductsMutation();
    const [ deleteProductRelatedProduct ] = useDeleteProductRelatedProductMutation();
    const [ saveAttachment ] = useSaveAttachmentMutation();
    

    useEffect(() => {
        if (product_data){
            setState((prevState) => ({
                ...prevState,
                ...product_data
            }))
        }
    }, [product_data]);


    useEffect(() => {
        if (isSuccessVariation){
            if (state.show_variation_modal){
                setState((prevState) => ({ ...prevState, show_variation_modal: false }));
            }
            resetVariation()
        }

        if (isSuccessSaleChannel){
            if (state.show_sales_channels_modal){
                setState((prevState) => ({ ...prevState, show_sales_channels_modal: false }));
            }
            resetSalesChannel()
        }

        if (isSuccessProductRelated){
            if (state.show_related_products_modal){
                setState((prevState) => ({ ...prevState, show_related_products_modal: false }));
            }
            resetProductRelated();
        }

    }, [isSuccessVariation, 
        isSuccessSaleChannel, 
        isSuccessProductRelated, 
        state.show_variation_modal, 
        state.show_sales_channels_modal, 
        state.show_related_products_modal,
        resetVariation,
        resetSalesChannel,
        resetProductRelated
    ]);

    
    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setState({ ...state, tab_value: newValue });
    };

    // This will handle the submit form event.  
    const handleSave = (event: any) => {
        event.preventDefault();
        
        if (handleValidation()) {
            //pt produse noi redirectul asigura si reload se face prin flag (status 301 venit tocmai din DB via API)
            //pt produse editate fortam un reload pt a fi siguri ca interfata afiseaza ce e in DB
            var new_prod = {
                ...state,                
            }

            //cand suntem in tab-ul "description" nu avem null in variabila de mai jos; cand suntem in alt tab va fi null si nu putema accesa componenta
            if (childTinyMceProductDescRef.current){
                new_prod.description_html = childTinyMceProductDescRef.current.getValue();
            }

            saveProduct(new_prod);
        }
    }
    // This will handle Cancel button click event.  
    const handleClose = (e: any) => {
        e.preventDefault();
        navigate("/products");
    }

    const handleChangePicture = (image_file: File | undefined) => {
        
        if (handleValidationChangePicture(image_file) && image_file !== undefined) {

            const formData = new FormData();
            formData.append("related_id", state.product_id.toString());
            formData.append("file", image_file);
            formData.append("type", 'product_image');
            formData.append("company_id", state.company_id.toString());
            saveAttachment({ picture: formData, tag: 'EditProduct', tag_id: id });
        }
    }

    const handleCloseVariationModal = () => {
        setState({ ...state, show_variation_modal: false, });
    }

    const handleSaveVariation = (event: any) => {
        event.preventDefault();
        if (handleVariationValidation()) {
            saveProductVariation(state.variation);
        }
    }

    const handleAddVariation = () => {
        setState({
            ...state, 
            show_variation_modal: true,
            variation: {
                ...Models.getProductVariationDefaultValues(),
                product_id: state.product_id
            }
        });
    }

    const handleEditVariation = (variation: Models.ProductVariation) => {
        setState({
            ...state,
            show_variation_modal: true,
            variation: { ...variation }
        });
    }

    const handleDeleteVariation = (variation_id: number, title: string) => {
        if (!window.confirm("Do you want to delete the variation: " + title))
            return;
        else {
            deleteProductVariation({ id: variation_id, product_id: id });
        }
    }

    const handleChangeSalesChannelCompanyId = (event: any) => {
        setState({ 
            ...state, 
            sales_channel: {
                ...state.sales_channel,
                company_id: Number(event.target.value),
                category_id: 0
            }
          });
    }

    const handleCloseSalesChannelsModal = () => {
        setState({
            ...state,
            show_sales_channels_modal: false,
        });
    }

    const handleSaveSalesChannels = (event: any) => {
        event.preventDefault();
        
        if (handleSalesChannelsValidation()) {
            saveProductSalesChannel(state.sales_channel);
        }
    }

    const handleAddSalesChannels = () => {
        setState({
            ...state,
            show_sales_channels_modal: true,
            sales_channel: {
                ...Models.getProductSalesChannelDefaultValues(),
                product_id: state.product_id
            }
        });
    }

    const handleEditSalesChannels = (sales_channel: Models.ProductSalesChannel) => {
        setState({
            ...state,
            show_sales_channels_modal: true,
            sales_channel: { ...sales_channel }
        });
    }

    const handleDeleteSalesChannels = (sales_channel_id: number, title: string) => {
        if (!window.confirm("Do you want to delete the sales channel: " + title))
            return;
        else {
            deleteProductSalesChannel( {id: sales_channel_id, product_id: id} );
        }
    }

    const handleEditRelatedProducts = () => {
        setState({
            ...state,
            show_related_products_modal: true,
            selected_product_ids: state.products_related.map(x => x.product_id_related)
        });
    }

    const handleSaveRelatedProductsModal = (selected_product_ids: number[]) => {
        if (selected_product_ids.length === 0) {
            dispatch(setError("Select related products"));
            return;
        }

        const rel_product = {
            product_id: state.product_id,
            product_related_ids: selected_product_ids
        }

        saveProductRelatedProducts(rel_product);
    }

    const handleCloseRelatedProductsModal = () => {
        setState({
            ...state,
            show_related_products_modal: false,
            selected_product_ids: [],
        });
    }

    const handleDeleteRelatedProduct = (related_product_id: number, title: string) => {
        if (!window.confirm("Do you want to delete the related product: " + title))
            return;
        else {
            deleteProductRelatedProduct({ id: related_product_id, product_id: id });
        }
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var name = state.name;
        var description = state.description;
        var company_id = state.company_id;        

        if (!Validator.isText(name)) {
            err += "Invalid character in Name!\n";
            result = false;
        }
        if (name.length <= 0 || name.length > 100) {
            err += "Name should be between 1 and 100 characters!\n";
            result = false;
        }

        
        if (!Validator.isText(description)) {
            err += "Invalid character in Description!\n";
            result = false;
        }
        if (description.length <= 0 || description.length > 1000) {
            err += "Description should be between 1 and 1000 characters!\n";
            result = false;
        }
        

        if (company_id <= 0) {
            err += "Select the company!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    const handleVariationValidation = () => {
        let result = true;
        let err = '';

        var name = state.variation.name;
        var title = state.variation.title;

        if (!Validator.isAlphaNumericAndSpaceUnderscore(name)) {
            err += "Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (name.length <= 0 || name.length > 100) {
            err += "Name should be between 1 and 100 characters!\n";
            result = false;
        }

        if (!Validator.isAlphaNumericAndSpaceUnderscore(title)) {
            err += "Title should be alpha numeric or underscore!\n";
            result = false;
        }
        if (title.length <= 0 || title.length > 100) {
            err += "Title should be between 1 and 100 characters!\n";
            result = false;
        }

        if (state.variation.product_id <= 0) {
            err += "Product in not saved!\n";
            result = false;
        }
        

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    const handleSalesChannelsValidation = () => {
        let result = true;
        let err = '';

        var company_id = state.sales_channel.company_id;
        var category_id = state.sales_channel.category_id;

        if (company_id <= 0) {
            err += "Select the company!\n";
            result = false;
        }

        if (category_id <= 0) {
            err += "Select the category!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    const handleValidationChangePicture = (image_file: File | undefined) => {
        let result = true;
        let err = '';

        if (state.product_id <= 0) {
            err += "First save the product then change the picture!\n";
            result = false;
        }

        if (image_file === undefined) {
            err += "Picture is not uploaded!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }    

    // Returns the HTML Form to the render() method.  
    const renderCreateForm = ()  => {
        return (
            <div>
                <Dialog
                    onClose={handleCloseVariationModal}
                    aria-labelledby="customized-dialog-title-edit-variations"
                    open={state.show_variation_modal}
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <DialogTitle id="customized-dialog-title-edit-variations" onClose={handleCloseVariationModal}>
                        Edit variation
                    </DialogTitle>
                    <DialogContent dividers>                        
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <Grid container spacing={5}>
                                    <Grid item xs={4}>
                                        <strong>Name:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            name="name"
                                            variant="standard"
                                            value={state.variation.name}
                                            onChange={(e) => changeState(e, 'variation')}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Title:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="title"
                                            variant="standard"
                                            value={state.variation.title}
                                            onChange={(e) => changeState(e, 'variation')}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Enabled:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Switch
                                            name="enabled"
                                            checked={state.variation.enabled}
                                            onChange={(e) => changeState(e, 'variation')}
                                            color="primary"
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Price:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="price"
                                            variant="standard"
                                            type="number"
                                            value={state.variation.price}
                                            onChange={(e) => changeState(e, 'variation')}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Quantity:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="quantity"
                                            variant="standard"
                                            type="number"
                                            value={state.variation.quantity}
                                            onChange={(e) => changeState(e, 'variation')}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Show Quantity:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Switch
                                            name="show_quantity"
                                            checked={state.variation.show_quantity}
                                            onChange={(e) => changeState(e, 'variation')}
                                            color="primary"
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Unlimited Quantity:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Switch
                                            name="unlimited_quantity"
                                            checked={state.variation.unlimited_quantity}
                                            onChange={(e) => changeState(e, 'variation')}
                                            color="primary"
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Sold:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="sold"
                                            variant="standard"
                                            type="number"
                                            value={state.variation.sold}
                                            onChange={(e) => changeState(e, 'variation')}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Sale Price:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="sale_price"
                                            variant="standard"
                                            type="number"
                                            value={state.variation.sale_price}
                                            onChange={(e) => changeState(e, 'variation')}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Sku:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="sku"
                                            variant="standard"
                                            value={state.variation.sku}
                                            onChange={(e) => changeState(e, 'variation')}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={5}>
                                    {state.variation.ins_date !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Created at:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {moment(state.variation.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                            </Grid></> : ""
                                    }
                                    {state.variation.ins_user !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Created by:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {state.variation.ins_user}
                                            </Grid></> : ""
                                    }
                                    {state.variation.upd_date !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Modified at:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {moment(state.variation.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                            </Grid></> : ""
                                    }
                                    {state.variation.upd_user !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Modified by:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {state.variation.upd_user}
                                            </Grid></> : ""
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={styled.buttonsBox} className="text-center">
                                    <Button variant="contained" color="primary" onClick={handleSaveVariation}>
                                        Save
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={handleCloseVariationModal}>
                                        Close
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Dialog
                    onClose={handleCloseSalesChannelsModal}
                    aria-labelledby="customized-dialog-title-edit-sales-channels"
                    open={state.show_sales_channels_modal}
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <DialogTitle id="customized-dialog-title-edit-sales_channels" onClose={handleCloseSalesChannelsModal}>
                        Edit sales channels
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <Grid container spacing={5}>
                                    <Grid item xs={4}>
                                        <strong>Company:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select
                                            name="company_id"
                                            value={companies_for_sales_channel? state.sales_channel.company_id : ''}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={handleChangeSalesChannelCompanyId}
                                            disabled={state.sales_channel.sale_id !== 0}
                                        >
                                            {companies_for_sales_channel?.map(o =>
                                                <MenuItem key={o.company_id} value={o.company_id}>{o.name}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Category:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select
                                            name="category_id"
                                            value={categories? state.sales_channel.category_id : ''}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={(e) => changeState(e, 'sales_channel')}
                                        >
                                            {categories?.map(o =>
                                                <MenuItem key={o.category_id} value={o.category_id}>{o.name}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Enabled:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Switch
                                            name="enabled"
                                            checked={state.sales_channel.enabled}
                                            onChange={(e) => changeState(e, 'sales_channel')}
                                            color="primary"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={5}>
                                    {state.sales_channel.ins_date !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Created at:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {moment(state.sales_channel.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                            </Grid></> : ""
                                    }
                                    {state.sales_channel.ins_user !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Created by:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {state.sales_channel.ins_user}
                                            </Grid></> : ""
                                    }
                                    {state.sales_channel.upd_date !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Modified at:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {moment(state.sales_channel.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                            </Grid></> : ""
                                    }
                                    {state.sales_channel.upd_user !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Modified by:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {state.sales_channel.upd_user}
                                            </Grid></> : ""
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={styled.buttonsBox} className="text-center">
                                    <Button variant="contained" color="primary" onClick={handleSaveSalesChannels}>
                                        Save
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={handleCloseSalesChannelsModal}>
                                        Close
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullScreen
                    onClose={handleCloseRelatedProductsModal}
                    aria-labelledby="customized-dialog-related-products"
                    open={state.show_related_products_modal}
                >
                    <DialogTitle id="customized-dialog-related-products" onClose={handleCloseRelatedProductsModal}>
                        Related products
                    </DialogTitle>
                    <DialogContent dividers>
                        <FetchProducts
                            selectable={true}
                            selected_company_id={0}
                            selected_product_ids={state.selected_product_ids}
                            onCloseCallback={handleCloseRelatedProductsModal}
                            onSaveCallback={handleSaveRelatedProductsModal}
                        />
                    </DialogContent>
                </Dialog>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={state.tab_value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList name="tab_value" onChange={handleChangeTab} aria-label="lab API tabs example">
                                <Tab label="Basic data" value="1" />
                                <Tab label="Description" value="2" />
                                <Tab label="Gallery" value="3" />
                                <Tab label="Variations" value="4" />
                                <Tab label="Sales channels" value="5" />
                                <Tab label="Related products" value="6" />
                                <Tab label="Transactions" value="7" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <form name="formEditProduct" id="formEditProduct" onSubmit={handleSave}>
                                <Grid container spacing={5}>
                                    <Grid item xs={4}>
                                        <Grid container spacing={5}>

                                            <Grid item xs={4}>
                                                <strong>Comapany:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Select
                                                    name="company_id"
                                                    value={companies? state.company_id : ''}
                                                    input={<Input />}
                                                    MenuProps={utils.MenuProps}
                                                    onChange={(e) => changeState(e)}
                                                    disabled={state.product_id !== 0}
                                                >
                                                    {companies?.map(o =>
                                                        <MenuItem key={o.company_id} value={o.company_id}>{o.name}</MenuItem>
                                                    )}
                                                </Select>
                                            </Grid>                                            

                                            <Grid item xs={4}>
                                                <strong>Enabled:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Switch                          
                                                    name="enabled"
                                                    checked={state.enabled}
                                                    onChange={(e) => changeState(e)}
                                                    color="primary"
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Name:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField                                                    
                                                    name="name"
                                                    variant="standard"
                                                    value={state.name}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Price:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField                                                    
                                                    name="price"
                                                    variant="standard"
                                                    type="number"
                                                    value={state.price}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Quantity:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="quantity"
                                                    variant="standard"
                                                    type="number"
                                                    value={state.quantity}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Show Quantity:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Switch
                                                    name="show_quantity"
                                                    checked={state.show_quantity}
                                                    onChange={(e) => changeState(e)}
                                                    color="primary"
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Unlimited Quantity:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Switch
                                                    name="unlimited_quantity"
                                                    checked={state.unlimited_quantity}
                                                    onChange={(e) => changeState(e)}
                                                    color="primary"
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Sold:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField                                                    
                                                    name="sold"
                                                    variant="standard"
                                                    type="number"
                                                    value={state.sold}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Unit:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="unit"
                                                    variant="standard"
                                                    value={state.unit}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Sku:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="sku"
                                                    variant="standard"
                                                    value={state.sku}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={4}>
                                                <strong>Sale Price:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="sale_price"
                                                    variant="standard"
                                                    type="number"
                                                    value={state.sale_price}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Description:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextareaAutosize
                                                    name="description"
                                                    minRows={3}
                                                    maxRows={7}
                                                    style={{ width: "100%" }}
                                                    className="textarea"
                                                    value={state.description}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>


                                            {state.ins_date !== "" ?
                                                <>
                                                    <Grid item xs={4}>
                                                        <strong>Created at:</strong>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {moment(state.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                                    </Grid></> : ""
                                            }
                                            {state.ins_user !== "" ?
                                                <>
                                                    <Grid item xs={4}>
                                                        <strong>Created by:</strong>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {state.ins_user}
                                                    </Grid></> : ""
                                            }
                                            {state.upd_date !== "" ?
                                                <>
                                                    <Grid item xs={4}>
                                                        <strong>Modified at:</strong>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {moment(state.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                                    </Grid></> : ""
                                            }
                                            {state.upd_user !== "" ?
                                                <>
                                                    <Grid item xs={4}>
                                                        <strong>Modified by:</strong>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {state.upd_user}
                                                    </Grid></> : ""
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>                                        
                                        <ImageUpload
                                            onUploadImage={(image) => handleChangePicture(image)}
                                            width={300}
                                            image={state.image}
                                        />
                                    </Grid>

                                    <Grid item xs={8}>                                        
                                        <Box sx={styled.buttonsBox} className="text-center">
                                            <Button variant="contained" color="primary" type="submit">
                                                Save
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </TabPanel>
                        <TabPanel value="2">
                            <TinyEditor 
                                ref={childTinyMceProductDescRef}
                                description_html={product_data?.description_html || ''}
                            />
                            <div className="form-group text-center">
                                <Box sx={styled.buttonsBox}>
                                    <Button variant="contained" color="primary" onClick={handleSave}>
                                        Save
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={handleClose}>
                                        Close
                                    </Button>
                                </Box>
                            </div>
                        </TabPanel>
                        <TabPanel value="3">
                            <Gallery
                                tag='EditProduct'
                                related_id={state.product_id}
                                company_id={state.company_id}
                                gallery={state.gallery}
                                type={[
                                    {value: "product_image", description: "Product Image"},
                                    {value: "product_gallery", description: "Product Gallery"},
                                    {value: "product_image_description", description: "Images used description"}, 
                                    {value: "product_pdf_description", description: "Pdf used description"}]
                                    }
                                />
                        </TabPanel>
                        <TabPanel value="4">
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Variations" />
                                        <Divider />
                                        {state.variations.length > 0 ?
                                            <CardContent>
                                                <Box>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow key={0}>
                                                                <TableCell>
                                                                    Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    Title
                                                                </TableCell>
                                                                <TableCell>
                                                                    Price
                                                                </TableCell>
                                                                <TableCell>
                                                                    Quantity
                                                                </TableCell>
                                                                <TableCell>
                                                                    Show Quantity
                                                                </TableCell>
                                                                <TableCell>
                                                                    Unlimited Quantity
                                                                </TableCell>
                                                                <TableCell>
                                                                    Sale Price
                                                                </TableCell>
                                                                <TableCell>
                                                                    Enabled
                                                                </TableCell>
                                                                <TableCell style={{ minWidth: "100px" }}>
                                                                    Delete
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {state.variations.map((item) => (
                                                                <TableRow
                                                                    hover
                                                                    key={item.variation_id}
                                                                >
                                                                    <TableCell>
                                                                        {item.name}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.title}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.price}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.quantity}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.show_quantity ? "Yes" : "No"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.unlimited_quantity ? "Yes" : "No"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.sale_price}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.enabled ? "Yes" : "No"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={styled.buttonsBox}>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                size="small"
                                                                                endIcon={<ModeEditIcon />}
                                                                                onClick={(id) => handleEditVariation(item)}
                                                                            >
                                                                                Edit
                                                                            </Button>

                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                size="small"
                                                                                endIcon={<DeleteForeverIcon />}
                                                                                onClick={(id) => handleDeleteVariation(item.variation_id, item.name)}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </CardContent>
                                            :
                                            "There are no variations. To add variations use the button 'ADD VARIATION'"
                                        }
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(id) => handleAddVariation()}
                                    >
                                        Add Variation
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="5">
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Sales channels" />
                                        <Divider />
                                        {state.sales_channels.length > 0 ?
                                            <CardContent>
                                                <Box>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow key={0}>
                                                                <TableCell>
                                                                    Company
                                                                </TableCell>
                                                                <TableCell>
                                                                    Category
                                                                </TableCell>
                                                                <TableCell>
                                                                    Enabled
                                                                </TableCell>
                                                                <TableCell style={{ minWidth: "100px" }}>
                                                                    Delete
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {state.sales_channels.map((item) => (
                                                                <TableRow
                                                                    hover
                                                                    key={item.sale_id}
                                                                >
                                                                    <TableCell>
                                                                        {item.company_name}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.category_name}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.enabled ? "Yes" : "No"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={styled.buttonsBox}>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                size="small"
                                                                                endIcon={<ModeEditIcon />}
                                                                                onClick={(id) => handleEditSalesChannels(item)}
                                                                            >
                                                                                Edit
                                                                            </Button>

                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                size="small"
                                                                                endIcon={<DeleteForeverIcon />}
                                                                                onClick={(id) => handleDeleteSalesChannels(item.sale_id, item.company_name + " / " + item.category_name)}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </CardContent>
                                            :
                                            "There are no sales channels. To add sales channels use the button 'ADD SALES CHANNEL'"
                                        }
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(id) => handleAddSalesChannels()}
                                    >
                                        Add Sale Channel
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="6">
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Related products" />
                                        <Divider />
                                        {state.products_related.length > 0 ?
                                            <CardContent>
                                                <Box>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow key={0}>
                                                                <TableCell>
                                                                    Image
                                                                </TableCell>
                                                                <TableCell>
                                                                    Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    Company
                                                                </TableCell>
                                                                <TableCell style={{ minWidth: "100px" }}>
                                                                    Delete
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {state.products_related.map((item, index) => (
                                                                <TableRow
                                                                    hover
                                                                    key={item.id}
                                                                >
                                                                    <TableCell>
                                                                        <Box
                                                                            sx={{
                                                                                alignItems: 'center',
                                                                                display: 'flex'
                                                                            }}
                                                                        >
                                                                            <ListItemAvatar>
                                                                                <img
                                                                                    src={item.image?.picture_url == null ? missingImage : item.image.picture_url}
                                                                                    alt={`related product ${item.name}`}
                                                                                    style={{
                                                                                        height: 48,
                                                                                        width: 48
                                                                                    }}
                                                                                />
                                                                            </ListItemAvatar>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.name}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.company}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={styled.buttonsBox}>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                size="small"
                                                                                endIcon={<DeleteForeverIcon />}
                                                                                onClick={(id) => handleDeleteRelatedProduct(item.id, item.name)}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </CardContent>
                                            :
                                            "There are no related products. To add related products use the button 'ADD RELATED PRODUCTS'"
                                        }
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(id) => handleEditRelatedProducts()}
                                    >
                                        ADD RELATED PRODUCTS
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="7">
                            <FetchTransactions 
                                selected_company_id = {state.company_id}
                                selected_product_id = {state.product_id}
                            />
                        </TabPanel>
                    </TabContext>
                </Box>

            </div>
        )
    }

    return (
        <div>
            <h3>Product definition</h3>
            <hr />
            {renderCreateForm()}
        </div>
    );
}


export default EditProduct;