import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Validator from "../validator/Validator";
import * as Models from '../models/Models';
import {
    Box,
    Tooltip,
    Typography,
    Tab,
    Dialog,
    Grid,
    TextField,
    TextareaAutosize,
    Button,
    Switch,
    IconButton,
    Select,
    Input,
    MenuItem,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    FormControlLabel,
    ListItemAvatar,
    Link,
} from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import {
    DeleteForever as DeleteForeverIcon,
    ModeEdit as ModeEditIcon,
    Info as InfoIcon,
    Preview as PreviewIcon,
    Html as HtmlIcon,
    Abc as AbcIcon,
    LooksOneOutlined as LooksOneOutlinedIcon,
    PlusOneOutlined as PlusOneOutlinedIcon,
    ArrowDownward as ArrowDownwardIcon,
    ArrowUpward as ArrowUpwardIcon,
    MonetizationOn as MonetizationOnIcon,
    Autorenew as AutorenewIcon,
} from '@mui/icons-material';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import { DialogTitle, DialogContent, Accordion, AccordionSummary, AccordionDetails, HtmlTooltip } from './StyledComponents'
import { LoadScript, GoogleMap, PolygonF } from "@react-google-maps/api";
import useWindowDimensions from '../hocs'
import ImageUpload from './ImageUpload'
import TinyEditor, { TinyEditorHandle } from './TinyEditor';
import moment from 'moment';
import Gallery from './Gallery';
import missingImage from '../icons/missing-image.jpg'; // with import
import { useChangeState } from '../utils/useChangeState';
import { getBannerDefaultValues, getCompanyDeliveryOptionDefaultValues, getCompanyWebsiteSettingDefaultValues } from '../models/Models';
import {
    useGetListsWithDefaultValueQuery,
    useGetCompanyQuery,
    useSaveCompanyMutation,
    useSaveCompanyDeliveryAreaMutation,
    useSaveCompanyDeliveryOptionsMutation,
    useDeleteCompanyDeliveryOptionMutation,
    useSaveCompanyBannerMutation,
    useDeleteCompanyBannerMutation,
    useSaveCompanySettingMutation,
    useDeleteCompanySettingMutation,
    useSaveAttachmentMutation,
    useGetCompanyDeliveryListMutation,
} from '../store/apiSlice';
import { useAppDispatch } from '../store/configureStore';
import { setError } from '../store/ApiInterface';
import { red } from '@mui/material/colors';

type EditCompanyState =
    Models.Company &
    {
        trigger_save_delivery_area: boolean,
        tab_value: string,
        show_delivery_options_modal: boolean,
        delivery_option: Models.CompanyDeliveryOption,
        show_banner_modal: boolean,
        banner: Models.Banner,
        delivery_area_center: Models.LatLng,
        delivery_area_fit_bounds: boolean,
        div_map_style: React.CSSProperties,
        accordion_company_picture_value: string,
        accordion_banner_picture_value: string,
        show_website_setting_modal: boolean,
        website_setting_show_table: boolean,
        website_setting_language: string,
        website_setting: Models.CompanyWebsiteSetting
    }

export const EditCompany: React.FC = (props) => {

    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const childTinyMceDescJsonValueRef = useRef<TinyEditorHandle>(null);
    const childTinyMceDescTextValueRef = useRef<TinyEditorHandle>(null);

    const windowDimensions = useWindowDimensions();

    const polygonRef = useRef<google.maps.Polygon | null>(null);
    const mapRef = useRef<google.maps.Map | undefined>(undefined);
    const mapDivRef = useRef<HTMLDivElement | undefined>(undefined);

    const [bannerIdFollowing, setBannerIdFollowing] = useState(0);
    const [settingIdFollowing, setSettingIdFollowing] = useState(0);

    const id = params.id != null ? parseInt(params.id) : 0;


    const [state, setState] = useState<EditCompanyState>({
        ...Models.getCompanyDefaultValues(),

        //campuri in afara modelului, necesare pt functionarea interfetei
        trigger_save_delivery_area: false,
        tab_value: '1',
        show_delivery_options_modal: false,
        delivery_option: getCompanyDeliveryOptionDefaultValues(),
        show_banner_modal: false,
        banner: getBannerDefaultValues(),
        delivery_area_center: { lat: 45.97623725592808, lng: 25.09364044114051 } as Models.LatLng,
        delivery_area_fit_bounds: true,
        div_map_style: {} as React.CSSProperties,
        accordion_company_picture_value: 'panel1',
        accordion_banner_picture_value: 'panel1',
        show_website_setting_modal: false,
        website_setting_show_table: false,
        website_setting_language: "0",
        website_setting: getCompanyWebsiteSettingDefaultValues(),
    });    
    
    const [deliveryCompanies, setDeliveryCompanies] = useState<Models.Company[] | null>(null);
    const changeState = useChangeState(state, setState);

    const { data: ccyList } = useGetListsWithDefaultValueQuery({ list_id: '4', default_value: '0', default_description: 'Select ccy' });
    const { data: themeList } = useGetListsWithDefaultValueQuery({ list_id: '7', default_value: '0', default_description: 'Select theme' });
    const { data: bannerTypeList } = useGetListsWithDefaultValueQuery({ list_id: '8', default_value: '0', default_description: 'Select banner type' });
    const { data: bannerPositionList } = useGetListsWithDefaultValueQuery({ list_id: '9', default_value: '0', default_description: 'Select banner position' });
    const { data: bannerDimensionList } = useGetListsWithDefaultValueQuery({ list_id: '10', default_value: '0', default_description: 'Select banner dimension' });
    const { data: languageList } = useGetListsWithDefaultValueQuery({ list_id: '11', default_value: '0', default_description: 'Select language' });    
    
    const [ triggerGetCompanyDeliveryList ] = useGetCompanyDeliveryListMutation();
    

    const { data: companyData } = useGetCompanyQuery(id)
    const [saveData] = useSaveCompanyMutation();

    const [saveDeliveryArea] = useSaveCompanyDeliveryAreaMutation();
    const [saveDeliveryOption, { isSuccess: isSuccessSaveDeliveryOption, reset: resetSaveDeliveryOption }] = useSaveCompanyDeliveryOptionsMutation();
    const [deleteDeliveryOption] = useDeleteCompanyDeliveryOptionMutation();
    const [saveBanner, { data: bannerData, reset: resetBanner }] = useSaveCompanyBannerMutation();
    const [deleteBanner] = useDeleteCompanyBannerMutation();
    const [saveSetting] = useSaveCompanySettingMutation();
    const [deleteSetting] = useDeleteCompanySettingMutation();

    const [saveAttachment] = useSaveAttachmentMutation();    

    

    useEffect(() => {
        if (companyData) {            
            setState((prev) => ({
                ...prev, // ca sa pastreze valorile din alte obiecte ale state-ului decat "product"
                ...companyData,
            }));
        }
    }, [companyData]);

    //nu inchidem popup-ul de ediare banner imediat dupa save (il lasam inca deschis poate mai face modificari de ex adauga o poza)
    //dupa salvare, ca sa avem id-ul banner-ului abia inserat il preluam dupa apelul APi-ului si il transmitem catre modala prin setBannerIdFollowing / BannerIdFollowing
    // la settings e un pic diferit, in sensul ca lista de settings este predefinita si "urmarim" dupa setting_id predefinit - nemaiffind necesar sa il luam dupa apelul API-ului
    useEffect(() => {
        if (bannerData) {
            setBannerIdFollowing(bannerData);
            resetBanner();
        }
    }, [bannerData, resetBanner]);

    useEffect(() => {

        setState((prevState) => {
            let current_banner = { ...prevState.banner };

            if (prevState.banner.type !== 'HeroBannerCard' &&
                prevState.banner.type !== 'HeroSliderBlock') {
                current_banner = {
                    ...current_banner,
                    btn_text: '',
                    searchbox: false,
                };
            }

            if (prevState.banner.type !== 'BannerHeroGrid') {
                current_banner = {
                    ...current_banner,
                    dimension: '0',
                };
            }

            if (prevState.banner.type !== 'BannerAllCarousel' &&
                prevState.banner.type !== 'BannerCard' &&
                prevState.banner.type !== 'BannerGrid' &&
                prevState.banner.type !== 'BannerGridTwo' &&
                prevState.banner.type !== 'BannerHeroGrid') {
                current_banner = {
                    ...current_banner,
                    effect_active: false,
                };
            }

            return { ...prevState, banner: current_banner }
        });

    }, [state.banner.type]);

    useEffect(() => {
        if (isSuccessSaveDeliveryOption) {
            handleCloseDeliveryOptionsModal();
            resetSaveDeliveryOption();
        }
    }, [isSuccessSaveDeliveryOption, resetSaveDeliveryOption]);
    
    useEffect(() => {
        if (bannerIdFollowing > 0) {
            const banner_new = companyData?.banners.find(x => x.banner_id === bannerIdFollowing);
            if (banner_new != null) {
                setState((prev) => ({ ...prev, banner: { ...banner_new } }));
            }
            else {
                setState((prev) => ({ ...prev, banner: { ...Models.getBannerDefaultValues() } }));
            }
        }
    }, [bannerIdFollowing, companyData?.banners]);


    useEffect(() => {
        //nu e ca la banner, aici avem o lista presetata de settings, si urmarim id-ul "setting_id" presetat nu id-ul cu care se salveaza in DB
        if (settingIdFollowing > 0) {
            const website_setting_new = companyData?.website_settings.find(x => x.setting_id === settingIdFollowing && x.language === state.website_setting_language);
            if (website_setting_new != null) {
                setState((prev) => ({ ...prev, website_setting: { ...website_setting_new } }));
            }
            else {
                setState((prev) => ({ ...prev, website_setting: { ...Models.getCompanyWebsiteSettingDefaultValues() } }));
            }
        }
    }, [settingIdFollowing, companyData?.website_settings, state.website_setting_language]);


    const divMapStyle = useCallback((): React.CSSProperties => {
        let bounds = mapDivRef.current?.getBoundingClientRect();
        return {
            fontFamily: 'sans-serif',
            textAlign: 'center',
            width: '100%',
            height: windowDimensions.height - (bounds?.top || 0) - 24
        };
    }, [windowDimensions]);


    const computeBoundsAndCenter = useCallback((markers: Models.LatLng[]) => {

        if (mapRef.current == null) //map not initialized
            return

        if (!state.delivery_area_fit_bounds)
            return

        if (markers != null && markers.length > 0) {
            const bounds = new (window as any).google.maps.LatLngBounds();

            for (let i = 0; i < markers.length; i++) {
                const marker = markers[i];
                const newPoint = new google.maps.LatLng(marker.lat, marker.lng);
                bounds.extend(newPoint);
            }

            mapRef.current.fitBounds(bounds);
            mapRef.current.setCenter(bounds.getCenter());

        }
        else {
            mapRef.current.setCenter(state.delivery_area_center);
            mapRef.current.setZoom(7);
        }
    }, [state.delivery_area_center, state.delivery_area_fit_bounds])

    useEffect(() => {
        setState((prevState) => ({ ...prevState, div_map_style: divMapStyle() }))
    }, [windowDimensions, divMapStyle]);


    useEffect(() => {
        if (state.trigger_save_delivery_area) {
            setState((prevState) => ({ ...prevState, trigger_save_delivery_area: false }));
            computeBoundsAndCenter(state.delivery_area);
            saveDeliveryArea({
                company_id: state.company_id,
                limited_delivery_area: state.limited_delivery_area,
                delivery_area: state.delivery_area
            });
        }
    }, [state.trigger_save_delivery_area, state.company_id, state.limited_delivery_area, state.delivery_area, computeBoundsAndCenter, saveDeliveryArea]);

    useEffect(() => {
        computeBoundsAndCenter(state.delivery_area);
    }, [state.delivery_area_fit_bounds, state.delivery_area, computeBoundsAndCenter]);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setState((prevState) => ({ ...prevState, tab_value: newValue }));
    };

    // This will handle the submit form event.  
    const handleSave = (event: any) => {
        event.preventDefault();
        if (handleValidation()) {
            saveData(state);
        }
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var name = state.name;
        var address = state.address;
        var description = state.description;
        var landing_page = state.landing_page;
        var phone = state.phone;
        var email = state.email;
        var email_subject = state.email_subject;
        var email_body = state.email_body;


        if (!Validator.isAlphaNumericAndSpaceUnderscore(name)) {
            err += "Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (name.length <= 0 || name.length > 50) {
            err += "Name should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!Validator.isText(address)) {
            err += "Illegal character in address!\n";
            result = false;
        }
        if (address.length <= 0 || address.length > 200) {
            err += "Address should be between 1 and 200 characters!\n";
            result = false;
        }

        if (!Validator.isText(landing_page)) {
            err += "Illegal character in Landing Page!\n";
            result = false;
        }
        if (landing_page.length > 200) {
            err += "Landing Page should be between 0 and 200 characters!\n";
            result = false;
        }

        if (!Validator.isText(description)) {
            err += "Illegal character in description!\n";
            result = false;
        }
        if (description.length <= 0 || description.length > 500) {
            err += "Description should be between 1 and 500 characters!\n";
            result = false;
        }

        if (phone !== '' && !Validator.isPhone(phone)) {
            err += "Phone number is invalid!\n";
            result = false;
        }
        if (phone.length > 20) {
            err += "Phone should be between 0 and 20 characters!\n";
            result = false;
        }

        if (!Validator.isEmail(email)) {
            err += "Email is not valid!\n";
            result = false;
        }
        if (email.length <= 0 || email.length > 200) {
            err += "Email should be between 1 and 200 characters!\n";
            result = false;
        }

        if (!Validator.isAlphaNumericAndSpaceUnderscore(email_subject)) {
            err += "Email subject must be alpha numeric, underscore or space!\n";
            result = false;
        }
        if (email_subject.length <= 0 || email_subject.length > 200) {
            err += "Email Subject should be between 1 and 200 characters!\n";
            result = false;
        }

        if (email_body.length <= 0) {
            err += "Email Body is mandatory!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    // This will handle Cancel button click event.  
    const handleClose = (e: any) => {
        e.preventDefault();
        navigate("/companies");
    }

    const handleChangeExtSettingsShowProductPopup = (event: React.ChangeEvent<HTMLInputElement>) => {        
        setState((prevState) => ({ 
            ...prevState,
            ext_settings: {
                ...prevState.ext_settings,
                show_product_popup: event.target.checked,
                show_product_popup_full: false,
            }
         }));
    }


    const handleChangeAcordionCompany = (event: React.SyntheticEvent, newExpanded: boolean, panel: string) => {
        setState((prevState) => ({ ...prevState, accordion_company_picture_value: newExpanded ? panel : "" }));
    }

    const handleValidationChangePicture = (image_file: File | undefined) => {
        let result = true;
        let err = '';

        if (state.company_id <= 0) {
            err += "First save the company then change the picture!\n";
            result = false;
        }

        if (image_file === undefined) {
            err += "Picture is not upload!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    const handleChangeCompanyImage = (type: string, image_file: File | undefined) => {

        if (handleValidationChangePicture(image_file) && image_file != null) {

            const formData = new FormData();
            formData.append("related_id", state.company_id.toString());
            formData.append("file", image_file);
            formData.append("type", type);
            formData.append("company_id", state.company_id.toString());
            saveAttachment({ picture: formData, tag: 'EditCompany', tag_id: id });
        }
    }

    const handleCloseDeliveryOptionsModal = () => {
        setState((prevState) => ({
            ...prevState,
            show_delivery_options_modal: false,
            delivery_option: { ...Models.getCompanyDeliveryOptionDefaultValues() }
        }));
        setDeliveryCompanies(null);
    }

    const handleValidationDeliveryOption = () => {
        let result = true;
        let err = '';

        if (!state.delivery_option.ccy || state.delivery_option.ccy === "" || state.delivery_option.ccy === "0")
        {
            err += "Select the currency!\n";
            result = false;
        }

        if (!Validator.isAlphaNumericAndSpaceUnderscore(state.delivery_option.name))
        {
            err += "Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (state.delivery_option.name.length <= 0 || state.delivery_option.name.length > 100)
        {
            err += "Name should be between 1 and 100 characters!\n";
            result = false;
        }
        
        if (state.delivery_option.api_request)
        {            
            if (state.delivery_option.api_company_id <= 0)
            {
                err += "Delivery API company id is required!\n";
                result = false;
            }
            if (state.delivery_option.api_user_name.length <= 0 || state.delivery_option.api_user_name.length > 100)
            {
                err += "Delivery API User Name should be between 1 and 100 characters!\n";
                result = false;
            }
            if (state.delivery_option.api_pwd.length <= 0 || state.delivery_option.api_pwd.length > 100)
            {
                err += "Delivery API password should be between 1 and 100 characters!\n";
                result = false;
            }                
            if (state.delivery_option.sender_name === "")
            {
                err += "Sender name is required!\n";
                result = false;
            }
            if (state.delivery_option.pickup_address_country === "")
            {
                err += "Pickup address country is required!\n";
                result = false;
            }
            
            if (state.delivery_option.pickup_address_area === "" &&
                state.delivery_option.pickup_address_locality === "" &&
                state.delivery_option.pickup_address_number === "" &&
                state.delivery_option.pickup_address_street === "")
            {
                err += "Pickup address is mandatory: fill area, locality, number or street!\n";
                result = false;
            }

            if (state.delivery_option.pickup_contact_person === "")
            {
                err += "Pickup contact person is required!\n";
                result = false;
            }

            if (state.delivery_option.pickup_contact_phone === "")
            {
                err += "Pickup contact phone is required!\n";
                result = false;
            }
        }
        
        if (state.delivery_option.api_user_name !== "")
        {
            if (!Validator.isText(state.delivery_option.api_user_name))
            {
                err += "Illegal character in Api User Name!\n";
                result = false;
            }
            if (state.delivery_option.api_user_name.length > 100)
            {
                err += "Api User Name should be between 1 and 100 characters!\n";
                result = false;
            }
        }

        if (state.delivery_option.api_pwd !== "")
        {
            if (!Validator.isText(state.delivery_option.api_pwd))
            {
                err += "Illegal character in Api Password!\n";
                result = false;
            }
            if (state.delivery_option.api_pwd.length > 100)
            {
                err += "Api password should be between 1 and 100 characters!\n";
                result = false;
            }
        }

        if (state.delivery_option.sender_name !== "")
        {
            if (!Validator.isText(state.delivery_option.sender_name))
            {
                err += "Illegal character in sender name!\n";
                result = false;
            }
            if (state.delivery_option.sender_name.length > 200)
            {
                err += "Sender name should be between 1 and 200 characters!\n";
                result = false;
            }
        }

        if (state.delivery_option.pickup_address_country !== "")
        {
            if (!Validator.isText(state.delivery_option.pickup_address_country))
            {
                err += "Illegal character in pickup address country!\n";
                result = false;
            }
            if (state.delivery_option.pickup_address_country.length > 50)
            {
                err += "Pickup address country should be between 1 and 50 characters!\n";
                result = false;
            }
        }

        if (state.delivery_option.pickup_address_area !== "")
        {
            if (!Validator.isText(state.delivery_option.pickup_address_area))
            {
                err += "Illegal character in pickup address area!\n";
                result = false;
            }
            if (state.delivery_option.pickup_address_area.length > 200)
            {
                err += "Pickup address area should be between 1 and 200 characters!\n";
                result = false;
            }
        }
        if (state.delivery_option.pickup_address_locality !== "") 
        {
            if (!Validator.isText(state.delivery_option.pickup_address_locality))
            {
                err += "Illegal character in pickup address locality!\n";
                result = false;
            }
            if (state.delivery_option.pickup_address_locality.length > 200)
            {
                err += "Pickup address locality should be between 1 and 200 characters!\n";
                result = false;
            }
        }
        if (state.delivery_option.pickup_address_number !== "")
        {
            if (!Validator.isText(state.delivery_option.pickup_address_number))
            {
                err += "Illegal character in pickup address number!\n";
                result = false;
            }
            if (state.delivery_option.pickup_address_number.length > 200)
            {
                err += "Pickup address number should be between 1 and 200 characters!\n";
                result = false;
            }
        }
        if (state.delivery_option.pickup_address_street !== "")
        {
            if (!Validator.isText(state.delivery_option.pickup_address_street))
            {
                err += "Illegal character in pickup address street!\n";
                result = false;
            }
            if (state.delivery_option.pickup_address_street.length > 200)
            {
                err += "Pickup address street should be between 1 and 200 characters!\n";
                result = false;
            }
        }
        if (state.delivery_option.pickup_contact_person !== "")
        {
            if (!Validator.isText(state.delivery_option.pickup_contact_person))
            {
                err += "Illegal character in pickup contact person!\n";
                result = false;
            }
            if (state.delivery_option.pickup_contact_person.length > 200)
            {
                err += "Pickup contact person should be between 1 and 200 characters!\n";
                result = false;
            }
        }
        if (state.delivery_option.pickup_contact_phone !== "")
        {
            if (!Validator.isText(state.delivery_option.pickup_contact_phone))
            {
                err += "Illegal character in pickup contact phone!\n";
                result = false;
            }
            if (state.delivery_option.pickup_contact_phone.length > 200)
            {
                err += "Pickup contact phone should be between 1 and 200 characters!\n";
                result = false;
            }
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;

    }

    const handleSaveDeliveryOptions = (event: any) => {
        event.preventDefault();        
        if (handleValidationDeliveryOption()) {
            saveDeliveryOption(state.delivery_option);
        }
    }

    const handleAddDeliveryOptions = () => {
        setState((prevState) => ({
            ...prevState,
            show_delivery_options_modal: true,
            delivery_option: {
                ...Models.getCompanyDeliveryOptionDefaultValues(),
                company_id: state.company_id
            }
        }));
    }

    const GetCompanyDeliveryList = async (delivery_option: Models.CompanyDeliveryOption) => {
        const default_company = {...Models.getCompanyDefaultValues(), name: 'Select company'};

        const emptyDeliveryCompany : Models.Company[] = [default_company]
        const result = await triggerGetCompanyDeliveryList({ user_name: delivery_option.api_user_name, password: delivery_option.api_pwd});

        if ('error' in result || !result) {
            setDeliveryCompanies(emptyDeliveryCompany);
                setState((prevState) => ({
                    ...prevState,
                    delivery_option: {
                        ...prevState.delivery_option,
                        api_company_id: 0
                    }
                }))
        }
        else if (result.data)
        {
            setDeliveryCompanies(emptyDeliveryCompany.concat(result.data));
            
            if (Number(delivery_option.api_company_id) === 0){
                if (result.data.length > 0){
                    setState((prevState) => ({
                        ...prevState,
                        delivery_option: {
                            ...prevState.delivery_option,
                            api_company_id: result.data[0].company_id
                        }
                    }))
                }
            }
            else {
                //daca ce este selectat nu exista in lista atunci punem 0 ca sa nu poata si salvata valoarea inexistenta in lista            
                const curr_comp_found = result.data.find(x => x.company_id === Number(delivery_option.api_company_id)); //folosin Number pt ca vede ca string api_company_id si nu merge comparatia cu ===
                if (!curr_comp_found)
                {
                    setState((prevState) => ({
                        ...prevState,
                        delivery_option: {
                            ...prevState.delivery_option,
                            api_company_id: 0
                        }
                    })) 
                }
            }
        }
        else {
            setDeliveryCompanies(null)
        }

    }
    const handleGetCompanyDeliveryList = (e: any) => {
        if (!state.delivery_option.api_request){
            dispatch(setError("Only if api request is on you get get the list of companies"));
            return;
        }
        GetCompanyDeliveryList(state.delivery_option);

    }

    const handleEditDeliveryOptions = (delivery_option: Models.CompanyDeliveryOption) => {        
        setState((prevState) => ({
            ...prevState,
            show_delivery_options_modal: true,
            delivery_option: { ...delivery_option }
        }));

        if (delivery_option.api_request){
            GetCompanyDeliveryList(delivery_option);
        }
    }

    const handleDeleteDeliveryOptions = (delivery_option_id: number, title: string) => {
        if (!window.confirm("Do you want to delete the delivery option: " + title))
            return;
        else {
            deleteDeliveryOption({ id: delivery_option_id, company_id: id });
        }
    }

    const handleNewDeliveryArea = (event: any) => {

        var new_delivery_area: Models.LatLng[] = [];

        if (mapRef.current != null) {
            let ne = mapRef.current.getBounds()?.getNorthEast();
            let sw = mapRef.current.getBounds()?.getSouthWest();

            let ne_lat = ne?.lat() || 0;
            let ne_lng = ne?.lng() || 0;

            let sw_lat = sw?.lat() || 0;
            let sw_lng = sw?.lng() || 0;

            let center_lat = (ne_lat + sw_lat) / 2;
            let center_lng = (ne_lng + sw_lng) / 2;

            if (ne_lng < 0 && sw_lng > 0) {
                center_lng += 180;
            }

            if (ne_lng > 0 && sw_lng < 0) {
                center_lng += 180;
            }

            let diff_lat = (center_lat - sw_lat) / 2;
            let diff_lng = (center_lng - sw_lng) / 2;

            let p1: Models.LatLng = { lat: center_lat + diff_lat, lng: center_lng }
            let p2: Models.LatLng = { lat: center_lat - diff_lat, lng: center_lng - diff_lng }
            let p3: Models.LatLng = { lat: center_lat - diff_lat, lng: center_lng + diff_lng }

            new_delivery_area.push(p1);
            new_delivery_area.push(p2);
            new_delivery_area.push(p3);


            setState((prevState) => ({ ...prevState, delivery_area: new_delivery_area, trigger_save_delivery_area: true }));
        }
    }

    const handleChangeLimitedDeliveryArea = async (event: any) => {
        if (!event.target.checked && !window.confirm("Delivery area will be deteled. Are you sure?")) {
            return;
        }
        setState((prevState) => ({ ...state, limited_delivery_area: event.target.checked, trigger_save_delivery_area: true }));
    }

    const handleDeleteDeliveryArea = (event: any) => {
        setState((prevState) => ({ ...prevState, delivery_area: [], trigger_save_delivery_area: true }));
    }



    const onLoadMapDiv = (el: HTMLDivElement) => {
        mapDivRef.current = el;
    }

    const onLoadMap = (map: google.maps.Map) => {
        mapRef.current = map;
        setState((prevState) => ({ ...prevState, div_map_style: divMapStyle() }))
    }

    const onEditPolygon = () => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map((latLng: google.maps.LatLng) => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setState((prevState) => ({ ...prevState, delivery_area: nextPath, trigger_save_delivery_area: true }));
        }
    };

    const onLoadPolygon = (polygon: google.maps.Polygon) => {
        polygonRef.current = polygon;
        computeBoundsAndCenter(state.delivery_area);
    }

    const onUnmountPolygon = () => {
        polygonRef.current = null;
    }

    //banners
    const handleCloseBannersModal = () => {
        setState((prevState) => ({
            ...state,
            show_banner_modal: false,
            banner: { ...Models.getBannerDefaultValues() }

        }));
        setBannerIdFollowing(0);

    }

    const handleValidationBanner = () => {
        let result = true;
        let err = '';

        var title = state.banner.title;
        var description = state.banner.description;
        var url = state.banner.url;

        var type = state.banner.type;
        var position = state.banner.position;

        if (!Validator.isText(title)) {
            err += "Illegal character in Title!\n";
            result = false;
        }

        if (title.length > 100) {
            err += "Title should not exceed 100 characters!\n";
            result = false;
        }

        if (!Validator.isText(description)) {
            err += "Illegal character in Description!\n";
            result = false;
        }
        if (description.length > 200) {
            err += "Description should not exceed 200 characters!\n";
            result = false;
        }

        if (url !== '' && !Validator.isUrl(url)) {
            err += "Illegal character in url!\n";
            result = false;
        }
        if (url.length > 100) {
            err += "Url should not exceed 100 characters!\n";
            result = false;
        }

        if (type === '0') {
            err += "Select banner type!\n";
            result = false;
        }

        if (position === '0') {
            err += "Select banner position!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    const handleSaveBanners = (event: any) => {
        event.preventDefault();

        if (handleValidationBanner()) {
            saveBanner(state.banner);
        }
    }

    const handleAddBanners = () => {

        setState((prevState) => ({
            ...prevState,
            show_banner_modal: true,
            banner: {
                ...Models.getBannerDefaultValues(),
                company_id: state.company_id
            }
        }));
        setBannerIdFollowing(0);
    }

    const handleEditBanners = (banner: Models.Banner) => {
        setState((prevState) => ({
            ...state,
            show_banner_modal: true,
            banner: { ...banner }
        }));
        setBannerIdFollowing(banner.banner_id);
    }

    const handleDeleteBanners = (banner_id: number, title: string) => {
        if (!window.confirm("Do you want to delete the banner: " + title))
            return;
        else {
            deleteBanner({ id: banner_id, company_id: id });
        }
    }

    const handleValidationChangeBannerPicture = (image_file: File | undefined) => {
        let result = true;
        let err = '';

        if (state.banner.banner_id <= 0) {
            err += "First save the banner then change the picture!\n";
            result = false;
        }

        if (image_file === undefined) {
            err += "Picture is not uploaded!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }


    const handleChangeBannerImage = (type: string, image_file: File | undefined) => {

        if (handleValidationChangeBannerPicture(image_file) && image_file != null) {

            const formData = new FormData();
            formData.append("related_id", state.banner.banner_id.toString());
            formData.append("file", image_file);
            formData.append("type", type);
            formData.append("company_id", state.company_id.toString());
            saveAttachment({ picture: formData, tag: 'EditCompany', tag_id: id });
        }
    }

    const handleChangeAcordionBanner = (event: React.SyntheticEvent, newExpanded: boolean, panel: string) => {
        setState((prevState) => ({ ...prevState, accordion_banner_picture_value: newExpanded ? panel : "" }));
    }


    const handleCloseWebsiteSettingModal = () => {
        setState((prevState) => ({
            ...prevState,
            show_website_setting_modal: false,
            website_setting: { ...Models.getCompanyWebsiteSettingDefaultValues() }
        }));
        setSettingIdFollowing(0);
    }

    const handleValidationWebsiteSetting = () => {
        let result = true;
        let err = '';

        var multiple_elements = state.website_setting.multiple_elements;
        var setting_id = state.website_setting.setting_id;
        var title = state.website_setting.json_value.title;
        var date = state.website_setting.json_value.date;
        var summary = state.website_setting.json_value.summary;


        //doar pt cazul in care avem elemente multiple avem validari (avem campuri ca titlu, data)
        //in cazul in care avem un singur element se completeaza daor campul "text_value" unde facem sanitizare in backend (putem avea si html in el asa ca nu pun restrictie de lungime sau caractere)
        if (multiple_elements) {
            if (!Validator.isText(title)) {
                err += "Illegal character in title!\n";
                result = false;
            }
            if (title.length <= 0 || title.length > 500) {
                err += "Title should be between 1 and 500 characters!\n";
                result = false;
            }

            if (date != null && date !== '' && !Validator.isDateISO(date)) {
                err += "Date is invalid!\n";
                result = false;
            }

            if (summary != null && !Validator.isText(summary)) {
                err += "Illegal character in summary!\n";
                result = false;
            }
            if (summary != null && summary.length > 5000) {
                err += "Summary should not exceed 5000 characters!\n";
                result = false;
            }

            if (setting_id === 12) /*blog*/ {
                if (title == null || title === '') {
                    err += "For 'Blogs' title is mandatory!\n";
                    result = false;
                }

                if (summary == null || summary === '') {
                    err += "For 'Blogs' summary is mandatory!\n";
                    result = false;
                }

                if (date == null || date === '') {
                    err += "For 'Blogs' date is mandatory!\n";
                    result = false;
                }
            }
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    const handleSaveWebsiteSetting = (event: any) => {
        event.preventDefault();

        if (handleValidationWebsiteSetting()) {
            const website_setting_new = {
                ...state.website_setting,
            }
            
            //pt cazul cand e text, state-ul este modificat direct prin controlled textbox
            if (state.website_setting.format !== 'text' && childTinyMceDescTextValueRef.current){
                website_setting_new.text_value = childTinyMceDescTextValueRef.current.getValue()                
            }

            saveSetting(website_setting_new);
        }
    }

    const handleSaveWebsiteSettingJson = (event: any) => {
        event.preventDefault();       
        

        if (handleValidationWebsiteSetting()) {

            var json_values = [...state.website_setting.json_values];
            const new_json_value = {
                ...state.website_setting.json_value,
            }

            //pt cazul cand e text, state-ul este modificat direct prin controlled textbox
            if (state.website_setting.format !== 'text' && childTinyMceDescJsonValueRef.current){
                new_json_value.value = childTinyMceDescJsonValueRef.current.getValue() //luam valoare scrisa in TinyMce; trebuie asa pt ca daca am modifica state-ul face re-render in TinyMCE si muta cursorul la inceput
            }

            if (new_json_value.id === 0) {
                json_values.unshift(new_json_value); //The unshift() method adds the specified elements to the beginning of an array and returns the new length of the array
                //json_values = json_values.concat(state.website_setting.json_value);
            }
            else {
                var index = json_values.findIndex(x => x.id === new_json_value.id);
                if (index !== -1) {
                    json_values[index] = new_json_value;
                }
            }

            const website_setting = {
                ...state.website_setting,
                json_values: json_values
            };

            setState((prevState) => ({
                ...prevState,
                website_setting_show_table: true,
                website_setting: website_setting
            }));

            saveSetting(website_setting);
        }
    }

    const handleDeleteWebsiteSettingJson = (id: number, title: string) => {

        if (!window.confirm("Do you want to delete the value: " + title))
            return;
        else {

            var json_values = [...state.website_setting.json_values];
            json_values = json_values.filter(x => x.id !== id);

            const website_setting = {
                ...state.website_setting,
                json_values: json_values
            };

            setState((prevState) => ({
                ...prevState,
                website_setting_show_table: true,
                website_setting: website_setting
            }));
            saveSetting(website_setting);
        }
    }

    const handleCloseWebsiteSettingJson = () => {
        setState({ ...state, website_setting_show_table: true, });
    }

    const handleEditWebsiteSetting = (setting: Models.CompanyWebsiteSetting) => {
        setState((prevState) => ({
            ...prevState,
            show_website_setting_modal: true,
            website_setting_show_table: true,
            website_setting: { ...setting }
        }));
        setSettingIdFollowing(setting.setting_id);
    }

    const handleDeleteWebsiteSetting = (setting_id: number, name: string) => {
        if (!window.confirm("Do you want to delete the setting: " + name))
            return;
        else {
            deleteSetting({ id: setting_id, company_id: id });
        }
    }
    const handleAddWebsiteSettingValue = (event: any) => {
        setState((prevState) => ({
            ...prevState,
            website_setting_show_table: false,
            website_setting: {
                ...state.website_setting,
                json_value: {
                    ...Models.getCompanyWebsiteSettingJsonValueDefaultValues(),
                }
            }
        }));
    }

    const handleEditWebsiteSettingJson = (json_value: Models.CompanyWebsiteSettingJsonValue) => {
        setState((prevState) => ({
            ...prevState,
            website_setting_show_table: false,
            website_setting: {
                ...state.website_setting,
                json_value: { ...json_value }
            }
        }));
    }

    const handleMoveWebsiteSettingJson = (json_value: Models.CompanyWebsiteSettingJsonValue, offset: number) => {
        var json_values = [...state.website_setting.json_values];
        var index = json_values.findIndex(x => x.id === json_value.id);

        if (index !== -1 && index + offset >= 0 && index + offset < json_values.length) {
            const aux = json_values[index + offset];
            json_values[index + offset] = json_values[index];
            json_values[index] = aux;
        }

        const website_setting = {
            ...state.website_setting,
            json_values: json_values
        };

        setState((prevState) => ({
            ...prevState,
            website_setting: website_setting
        }));
        saveSetting(website_setting);
    }


    const handleChangeWebsiteSettingJsonDate = (newValue: Date | null) => {
        setState((prevState) => ({
            ...prevState,
            website_setting: {
                ...state.website_setting,
                json_value: {
                    ...state.website_setting.json_value,
                    date: utils.formatDate(newValue)
                }
            }
        }));
    }

    //exemplu
    // eslint-disable-next-line
    const handleChangeWebsiteSettingJsonTitle = (event: any) => {
        setState((prevState) => ({
            ...prevState,
            website_setting: {
                ...state.website_setting,
                json_value: {
                    ...state.website_setting.json_value,
                    title: event.target.value
                }
            }
        }));
    }    

    // Returns the HTML Form to the render() method.  
    const renderCreateForm = () => {
        const website_settings_filtered = state.website_settings.filter(x => x.language === state.website_setting_language);

        return (
            <div>
                <Dialog
                    onClose={handleCloseDeliveryOptionsModal}
                    aria-labelledby="customized-dialog-title-edit-delivery-options"
                    open={state.show_delivery_options_modal}
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <DialogTitle id="customized-dialog-title-edit-delivery-options" onClose={handleCloseDeliveryOptionsModal}>
                        Edit delivery options
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <Grid container spacing={5}>

                                    <Grid item xs={4}>
                                        <strong>Name:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="name"
                                            variant="standard"
                                            value={state.delivery_option.name}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Enabled:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Switch
                                            name="enabled"
                                            checked={state.delivery_option.enabled}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                            color="primary"
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Price:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="price"
                                            type="number"
                                            variant="standard"
                                            value={state.delivery_option.price}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                            required
                                        />
                                        <Select
                                            name="ccy"
                                            value={ccyList ? state.delivery_option.ccy : ''}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                        >
                                            {ccyList?.map(o =>
                                                <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>

                                    {state.delivery_option.ins_date !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Created at:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {moment(state.delivery_option.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                            </Grid></> : ""
                                    }
                                    {state.delivery_option.ins_user !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Created by:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {state.delivery_option.ins_user}
                                            </Grid></> : ""
                                    }
                                    {state.delivery_option.upd_date !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Modified at:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {moment(state.delivery_option.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                            </Grid></> : ""
                                    }
                                    {state.delivery_option.upd_user !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Modified by:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {state.delivery_option.upd_user}
                                            </Grid></> : ""
                                    }
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>                                
                                <Grid container spacing={5}>
                                    <Grid item xs={4}>
                                        <strong>Delivery API request:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Switch
                                            name="api_request"
                                            checked={state.delivery_option.api_request}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                            color="primary"
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>API User Name:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="api_user_name"
                                            variant="standard"
                                            value={state.delivery_option.api_user_name}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>API PWD:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="api_pwd"
                                            variant="standard"
                                            value={state.delivery_option.api_pwd}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                            type="password"
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>API Company:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select
                                            name="api_company_id"
                                            value={deliveryCompanies ? state.delivery_option.api_company_id : ''}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                        >
                                            {deliveryCompanies?.map(o =>
                                                <MenuItem key={o.company_id} value={o.company_id}>{o.name}</MenuItem>
                                            )}
                                        </Select>
                                        <IconButton color="success" aria-label="Refresh company list" onClick={handleGetCompanyDeliveryList}>
                                            <AutorenewIcon />
                                        </IconButton>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Sender Name:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="sender_name"
                                            variant="standard"
                                            value={state.delivery_option.sender_name}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Pickup Address Country:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="pickup_address_country"
                                            variant="standard"
                                            value={state.delivery_option.pickup_address_country}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Pickup Address Area:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="pickup_address_area"
                                            variant="standard"
                                            value={state.delivery_option.pickup_address_area}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Pickup Address Locality:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="pickup_address_locality"
                                            variant="standard"
                                            value={state.delivery_option.pickup_address_locality}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Pickup Address Number:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="pickup_address_number"
                                            variant="standard"
                                            value={state.delivery_option.pickup_address_number}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Pickup Address Street:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="pickup_address_street"
                                            variant="standard"
                                            value={state.delivery_option.pickup_address_street}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Pickup Contact Person:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="pickup_contact_person"
                                            variant="standard"
                                            value={state.delivery_option.pickup_contact_person}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Pickup Contact Phone:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="pickup_contact_phone"
                                            variant="standard"
                                            value={state.delivery_option.pickup_contact_phone}
                                            onChange={(e) => changeState(e, 'delivery_option')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} alignItems="center" justifyContent="center">
                                <div className="form-group text-center">
                                    <Box sx={styled.buttonsBox}>
                                        <Button variant="contained" color="primary" onClick={handleSaveDeliveryOptions}>
                                            Save
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={handleCloseDeliveryOptionsModal}>
                                            Close
                                        </Button>
                                    </Box>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Dialog
                    onClose={handleCloseBannersModal}
                    aria-labelledby="customized-dialog-title-edit-banner"
                    open={state.show_banner_modal}
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <DialogTitle id="customized-dialog-title-edit-delivery-options" onClose={handleCloseBannersModal}>
                        {"Edit banner: " + state.banner.banner_id}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <Grid container spacing={5}>

                                    <Grid item xs={4}>
                                        <strong>Position:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select
                                            name="position"
                                            value={bannerPositionList ? state.banner.position : ''}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={(e) => changeState(e, 'banner')}
                                        >
                                            {bannerPositionList?.map(o =>
                                                <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Type:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select
                                            name="type"
                                            value={bannerTypeList ? state.banner.type : ''}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={(e) => changeState(e, 'banner')}
                                        >
                                            {bannerTypeList?.map(o =>
                                                <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>


                                    <Grid item xs={4}>
                                        <strong>Title ('alt' image attribute):</strong>
                                        <Tooltip title="Used as 'alt' attribute for banner image and in HeroBannerCard and HeroSliderBlock where is displayed as text.">
                                            <InfoIcon />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="title"
                                            variant="standard"
                                            value={state.banner.title}
                                            onChange={(e) => changeState(e, 'banner')}
                                        />
                                        <input type="color"
                                            name="title_color"
                                            value={state.banner.title_color}
                                            style={{ marginLeft: '20px' }}
                                            title="Title text color"
                                            onChange={(e) => changeState(e, 'banner')} />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Description:</strong>
                                        <Tooltip title="Used in HeroBannerCard and HeroSliderBlock.">
                                            <InfoIcon />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="description"
                                            variant="standard"
                                            value={state.banner.description}
                                            onChange={(e) => changeState(e, 'banner')}
                                        />
                                        <input type="color"
                                            name="description_color"
                                            value={state.banner.description_color}
                                            style={{ marginLeft: '20px' }}
                                            title="Description text color"
                                            onChange={(e) => changeState(e, 'banner')} />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Enabled:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Switch
                                            name="enabled"
                                            checked={state.banner.enabled}
                                            onChange={(e) => changeState(e, 'banner')}
                                            color="primary"
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Location id:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="location_id"
                                            variant="standard"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={state.banner.location_id}
                                            onChange={(e) => changeState(e, 'banner')}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Search Box:</strong>
                                        <Tooltip title="Used in HeroBannerCard and HeroSliderBlock.">
                                            <InfoIcon />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Switch
                                            name="searchbox"
                                            checked={state.banner.searchbox}
                                            onChange={(e) => changeState(e, 'banner')}
                                            color="primary"
                                            disabled={state.banner.type !== 'HeroBannerCard' && state.banner.type !== 'HeroSliderBlock'}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Button text:</strong>
                                        <Tooltip title="Used in HeroBannerCard and HeroSliderBlock.">
                                            <InfoIcon />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="btn_text"
                                            variant="standard"
                                            value={state.banner.btn_text}
                                            onChange={(e) => changeState(e, 'banner')}
                                            disabled={state.banner.type !== 'HeroBannerCard' && state.banner.type !== 'HeroSliderBlock'}
                                        />

                                        <input type="color"
                                            name="btn_text_color"
                                            value={state.banner.btn_text_color}
                                            style={{ marginLeft: '20px' }}
                                            title="Button text color"
                                            onChange={(e) => changeState(e, 'banner')} />

                                        <input type="color"
                                            name="btn_text_background_color"
                                            value={state.banner.btn_text_background_color}
                                            style={{ marginLeft: '20px' }}
                                            title="Button background color"
                                            onChange={(e) => changeState(e, 'banner')} />
                                    </Grid>


                                    <Grid item xs={4}>
                                        <strong>Url:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="url"
                                            variant="standard"
                                            value={state.banner.url}
                                            onChange={(e) => changeState(e, 'banner')}
                                        />
                                    </Grid>


                                    <Grid item xs={4}>
                                        <strong>Dimension:</strong>
                                        <Tooltip title="Used in BannerHeroGrid.">
                                            <InfoIcon />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select
                                            name="dimension"
                                            value={bannerDimensionList ? state.banner.dimension : ''}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={(e) => changeState(e, 'banner')}
                                            disabled={state.banner.type !== 'BannerHeroGrid'}
                                        >
                                            {bannerDimensionList?.map(o =>
                                                <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Effect active:</strong>
                                        <Tooltip title="Used in BannerAllCarousel, BannerGridTwo, BannerGrid, BannerHeroGrid, BannerCard.">
                                            <InfoIcon />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Switch
                                            name="effect_active"
                                            checked={state.banner.effect_active}
                                            onChange={(e) => changeState(e, 'banner')}
                                            color="primary"
                                            disabled={state.banner.type !== 'BannerAllCarousel' &&
                                                state.banner.type !== 'BannerCard' &&
                                                state.banner.type !== 'BannerGrid' &&
                                                state.banner.type !== 'BannerGridTwo' &&
                                                state.banner.type !== 'BannerHeroGrid'}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <Accordion expanded={state.accordion_banner_picture_value === 'panel1'} onChange={(e, newExpanded) => handleChangeAcordionBanner(e, newExpanded, 'panel1')}>
                                            <AccordionSummary aria-controls="panel1b-content" id="panel1d-header">
                                                <Typography>{`Desktop image ${state.banner.image_desktop.width > 0 ? ' (' + state.banner.image_desktop.width + ' x ' + state.banner.image_desktop.height + ')' : ''}`}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <ImageUpload
                                                    onUploadImage={(image) => handleChangeBannerImage('banner_desktop', image)}
                                                    width={520}
                                                    image={state.banner.image_desktop}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion expanded={state.accordion_banner_picture_value === 'panel2'} onChange={(e, newExpanded) => handleChangeAcordionBanner(e, newExpanded, 'panel2')}>
                                            <AccordionSummary aria-controls="panel2b-content" id="panel2d-header">
                                                <Typography>{`Mobile image ${state.banner.image_mobile.width > 0 ? ' (' + state.banner.image_mobile.width + ' x ' + state.banner.image_mobile.height + ')' : ''} - used in HeroBannerCard or HeroSliderBlock`}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <ImageUpload
                                                    onUploadImage={(image) => handleChangeBannerImage('banner_mobile', image)}
                                                    width={520}
                                                    image={state.banner.image_mobile}
                                                    disabled={state.banner.type !== 'HeroBannerCard'
                                                        && state.banner.type !== 'HeroSliderBlock'
                                                        && state.banner.type !== 'BannerGridTwo'
                                                    }
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} alignItems="center" justifyContent="center">
                                <div className="form-group text-center">
                                    <Box sx={styled.buttonsBox}>
                                        <Button variant="contained" color="primary" onClick={handleSaveBanners}>
                                            Save
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={handleCloseBannersModal}>
                                            Close
                                        </Button>
                                    </Box>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Dialog
                    onClose={handleCloseWebsiteSettingModal}
                    aria-labelledby="customized-dialog-title-edit-website-settings"
                    open={state.show_website_setting_modal}
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <DialogTitle id="customized-dialog-title-edit-website-settings" onClose={handleCloseWebsiteSettingModal}>
                        {"Edit website settings: " + state.website_setting.name + " / " + state.website_setting.language}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={5}>

                            {state.website_setting.multiple_elements === false ?
                                <>
                                    <Grid item xs={12}>
                                        {state.website_setting.format === 'text' ?
                                            <Box
                                                sx={{
                                                    width: '100%'
                                                }}
                                            >
                                                <TextField
                                                    name="text_value"
                                                    variant="standard"
                                                    label="Text value"
                                                    fullWidth
                                                    multiline
                                                    value={state.website_setting.text_value}
                                                    onChange={(e) => changeState(e, 'website_setting')}
                                                />
                                            </Box>
                                            :
                                            <TinyEditor
                                                ref={childTinyMceDescTextValueRef}
                                                description_html={state.website_setting.text_value}
                                            />
                                        }
                                    </Grid>
                                    <Grid item xs={12} alignItems="center" justifyContent="center">
                                        <div className="form-group text-center">
                                            <Box sx={styled.buttonsBox}>
                                                <Button variant="contained" color="primary" onClick={handleSaveWebsiteSetting}>
                                                    Save
                                                </Button>
                                                <Button variant="contained" color="primary" onClick={handleCloseWebsiteSettingModal}>
                                                    Close
                                                </Button>
                                            </Box>
                                        </div>
                                    </Grid>
                                </>
                                :
                                <>
                                    {state.website_setting_show_table ?
                                        <>
                                            <Grid item xs={12}>
                                                {state.website_setting.json_values.length > 0 ?
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow key={0}>
                                                                <TableCell>
                                                                    Title
                                                                </TableCell>
                                                                <TableCell>
                                                                    Value
                                                                </TableCell>
                                                                <TableCell>
                                                                    Enabled
                                                                </TableCell>
                                                                <TableCell>
                                                                    Move up / down
                                                                </TableCell>
                                                                <TableCell style={{ minWidth: "100px" }}>
                                                                    Edit / Delete
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {state.website_setting.json_values.map((item, index) => (
                                                                <TableRow
                                                                    hover
                                                                    key={index}
                                                                >
                                                                    <TableCell>
                                                                        <div style={{ whiteSpace: 'pre-wrap' }}>
                                                                            {item.title}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {state.website_setting.format === 'text' ?
                                                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                                                {item.value}
                                                                            </div>
                                                                            :
                                                                            <HtmlTooltip title={
                                                                                <div dangerouslySetInnerHTML={{ __html: item.value }}>

                                                                                </div>
                                                                            }>
                                                                                <PreviewIcon />
                                                                            </HtmlTooltip>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.enabled ? "Yes" : "No"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <IconButton
                                                                            disabled={index === 0}
                                                                            onClick={(e) => handleMoveWebsiteSettingJson(item, -1)}
                                                                            size="large">
                                                                            <ArrowUpwardIcon />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            disabled={index >= state.website_setting.json_values.length - 1}
                                                                            onClick={(e) => handleMoveWebsiteSettingJson(item, 1)}
                                                                            size="large">
                                                                            <ArrowDownwardIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={styled.buttonsBox}>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                size="small"
                                                                                endIcon={<ModeEditIcon />}
                                                                                onClick={(e) => handleEditWebsiteSettingJson(item)}
                                                                            >
                                                                                Edit
                                                                            </Button>

                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                size="small"
                                                                                endIcon={<DeleteForeverIcon />}
                                                                                onClick={(e) => handleDeleteWebsiteSettingJson(item.id, item.title)}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                    :
                                                    "There are no values"
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button variant="contained" color="primary" onClick={handleAddWebsiteSettingValue}>
                                                    Add value
                                                </Button>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item xs={10}>
                                                <Box
                                                    sx={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    <TextField
                                                        name="title"
                                                        variant="standard"
                                                        label="Title"
                                                        fullWidth
                                                        multiline
                                                        value={state.website_setting.json_value.title}
                                                        //onChange={handleChangeWebsiteSettingJsonTitle}
                                                        onChange={(e) => changeState(e, 'website_setting', 'json_value')}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControlLabel
                                                    control={<Switch
                                                        name="enabled"
                                                        checked={state.website_setting.json_value.enabled}
                                                        onChange={(e) => changeState(e, 'website_setting', 'json_value')}
                                                        color="primary"
                                                    />}
                                                    label="Enabled" />

                                            </Grid>
                                            {state.website_setting.setting_id === 12 /* blog */ ?
                                                <>
                                                    <Grid item xs={12}>
                                                        <Box
                                                            sx={{
                                                                width: '100%'
                                                            }}
                                                        >
                                                            <TextField
                                                                name="summary"
                                                                variant="standard"
                                                                label="Summary"
                                                                fullWidth
                                                                multiline
                                                                minRows={2}
                                                                value={state.website_setting.json_value.summary}
                                                                onChange={(e) => changeState(e, 'website_setting', 'json_value')}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DesktopDatePicker
                                                                format="dd.MM.yyyy"
                                                                value={new Date(state.website_setting.json_value.date)}
                                                                onChange={(e) => handleChangeWebsiteSettingJsonDate(e)}
                                                                slotProps={{ textField: { variant: 'standard' } }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>

                                                    <Grid item xs={8}>
                                                        <TextField
                                                            name="picture_url"
                                                            variant="standard"
                                                            label="Picture Url"
                                                            fullWidth
                                                            multiline
                                                            value={state.website_setting.json_value.picture_url}
                                                            onChange={(e) => changeState(e, 'website_setting', 'json_value')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Box
                                                            sx={{
                                                                alignItems: 'center',
                                                                display: 'flex'
                                                            }}
                                                        >
                                                            <ListItemAvatar>
                                                                <a href={state.website_setting.json_value.picture_url} target="_blank" rel="noreferrer">
                                                                    <img
                                                                        src={state.website_setting.json_value.picture_url == null || state.website_setting.json_value.picture_url === ''
                                                                            ? missingImage
                                                                            :
                                                                            state.website_setting.json_value.picture_url}
                                                                        alt={state.website_setting.json_value.title}
                                                                        style={{
                                                                            height: 48,
                                                                            width: 48
                                                                        }}
                                                                    />
                                                                </a>
                                                            </ListItemAvatar>
                                                        </Box>
                                                    </Grid>
                                                </>
                                                :
                                                <></>
                                            }

                                            <Grid item xs={12}>
                                                {state.website_setting.format === 'text' ?
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <TextField
                                                            name="value"
                                                            variant="standard"
                                                            label="Text value"
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                            value={state.website_setting.json_value.value}
                                                            onChange={(e) => changeState(e, 'website_setting', 'json_value')}
                                                        />
                                                    </Box>
                                                    :
                                                    <TinyEditor
                                                        ref={childTinyMceDescJsonValueRef}
                                                        description_html={state.website_setting.json_value.value}
                                                    />
                                                }
                                            </Grid>
                                            <Grid item xs={12} alignItems="center" justifyContent="center">
                                                <div className="form-group text-center">
                                                    <Box sx={styled.buttonsBox}>
                                                        <Button variant="contained" color="primary" onClick={handleSaveWebsiteSettingJson}>
                                                            Save
                                                        </Button>
                                                        <Button variant="contained" color="primary" onClick={handleCloseWebsiteSettingJson}>
                                                            Close
                                                        </Button>
                                                    </Box>
                                                </div>
                                            </Grid>
                                        </>
                                    }

                                </>
                            }


                            {state.website_setting.ins_date !== "" ?
                                <>
                                    <Grid item xs={2}>
                                        <strong>Created at:</strong>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {moment(state.website_setting.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                    </Grid></> : ""
                            }
                            {state.website_setting.ins_user !== "" ?
                                <>
                                    <Grid item xs={2}>
                                        <strong>Created by:</strong>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {state.website_setting.ins_user}
                                    </Grid></> : ""
                            }
                            {state.website_setting.upd_date !== "" ?
                                <>
                                    <Grid item xs={2}>
                                        <strong>Modified at:</strong>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {moment(state.website_setting.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                    </Grid></> : ""
                            }
                            {state.website_setting.upd_user !== "" ?
                                <>
                                    <Grid item xs={2}>
                                        <strong>Modified by:</strong>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {state.website_setting.upd_user}
                                    </Grid></> : ""
                            }


                        </Grid>
                    </DialogContent>
                </Dialog>

                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={state.tab_value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                                <Tab label="Basic data" value="1" />
                                <Tab label="Delivery options" value="2" />
                                <Tab label="Delivery area" value="3" />
                                <Tab label="Banners" value="4" />
                                <Tab label="Gallery" value="5" />
                                <Tab label="Website settings" value="6" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <form name="formEditCompany" id="formEditCompany" onSubmit={handleSave}>
                                <Grid container spacing={5}>
                                    <Grid item xs={4}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={4}>
                                                <strong>Name:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="name"
                                                    variant="standard"
                                                    value={state.name}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Is Main:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Switch
                                                    name="is_main"
                                                    checked={state.is_main}
                                                    onChange={(e) => changeState(e)}
                                                    color="primary"
                                                />
                                                <Tooltip title={
                                                                <div style={{ whiteSpace: 'pre-line' }}>
                                                                    {"Identifies the company that administers the platform.\n" +
                                                                    "Ony one main company can exists!\n" +
                                                                    "The following information is used:\n" +
                                                                    "- email: for identity pages (Register, Forgot Password) the email 'from' is obtained using clientId (from url for shops); but if the user is using 'Forgot Password' from admin app clientId is not avaibale so email 'from' is obtained from the main company \n "
                                                                    }
                                                                </div>}>
                                                        <InfoIcon />
                                                    </Tooltip>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Is Marketplace:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Switch
                                                    name="is_marketplace"
                                                    checked={state.is_marketplace}
                                                    onChange={(e) => changeState(e)}
                                                    color="primary"
                                                />
                                                <Tooltip title={
                                                                <div style={{ whiteSpace: 'pre-line' }}>
                                                                    {"If active the following happens:\n" +
                                                                    "- other companies can sell products through categories from this marketplace \n " +
                                                                    "- 'Shops' menu appear in navigation top bar where companies who sell products through this marketplace can be seen \n " +
                                                                    "- filter by shop is possible in seach products screen \n " +
                                                                    "- filter by category can display not only products for that category but shops that sell products from that category"
                                                                    }
                                                                </div>}>
                                                        <InfoIcon />
                                                    </Tooltip>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Address:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextareaAutosize
                                                    name="address"
                                                    minRows={3}
                                                    maxRows={3}
                                                    style={{ width: "100%" }}
                                                    className="textarea"
                                                    value={state.address}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Latitude:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="lat"
                                                    type="number"
                                                    variant="standard"
                                                    value={state.lat}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Longitude:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="lng"
                                                    type="number"
                                                    variant="standard"
                                                    value={state.lng}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Description:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextareaAutosize
                                                    name="description"
                                                    minRows={3}
                                                    maxRows={3}
                                                    style={{ width: "100%" }}
                                                    className="textarea"
                                                    value={state.description}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Phone:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="phone"
                                                    variant="standard"
                                                    value={state.phone}
                                                    onChange={changeState}
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Website:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="website"
                                                    variant="standard"
                                                    value={state.website}
                                                    onChange={changeState}
                                                    required
                                                />
                                                <Tooltip title={
                                                                <div style={{ whiteSpace: 'pre-line' }}>
                                                                    {"Start with 'https://www' end without '/'\n"+
                                                                    "Used in:\n"+
                                                                    "- sitemap.xml - to generate SEO sitemap;\n "+
                                                                    "- identity page logo - redirect to this url from shop;\n "+
                                                                    "- copyright at bottom shop page;\n "+
                                                                    "- vendor card in shops (left side);\n "+
                                                                    "- canonical URL for shop pages\n\n"}
                                                                </div>}>
                                                    <InfoIcon />
                                                </Tooltip>
                                            </Grid>


                                            <Grid item xs={4}>
                                                <strong>Landing Page:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="landing_page"
                                                    variant="standard"
                                                    value={state.landing_page}
                                                    onChange={changeState}
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Email:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="email"
                                                    variant="standard"
                                                    value={state.email}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Email subject:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="email_subject"
                                                    variant="standard"
                                                    value={state.email_subject}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Email body:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextareaAutosize
                                                    name="email_body"
                                                    minRows={3}
                                                    maxRows={3}
                                                    style={{ width: "100%" }}
                                                    className="textarea"
                                                    value={state.email_body}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Theme:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Select
                                                    name="theme"
                                                    value={themeList ? state.theme : ''}
                                                    input={<Input />}
                                                    MenuProps={utils.MenuProps}
                                                    onChange={(e) => changeState(e)}
                                                >
                                                    {themeList?.map(o =>
                                                        <MenuItem key={o.Value} value={o.Value}>{o.Description}</MenuItem>
                                                    )}
                                                </Select>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Enabled:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Switch
                                                    name="enabled"
                                                    checked={state.enabled}
                                                    onChange={(e) => changeState(e)}
                                                    color="primary"
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Box sx={styled.buttonsBox}>
                                                    <Button variant="contained" color="primary" type="submit">
                                                        Save
                                                    </Button>
                                                    <Button variant="contained" color="primary" onClick={handleClose}>
                                                        Close
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Accordion expanded={state.accordion_company_picture_value === 'panel1'} onChange={(e, newExpanded) => handleChangeAcordionCompany(e, newExpanded, 'panel1')}>
                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                <Typography>Logo square (128x128)</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <ImageUpload
                                                    onUploadImage={(image) => handleChangeCompanyImage('shop_logo_square', image)}
                                                    width={128}
                                                    image={state.logo_square}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion expanded={state.accordion_company_picture_value === 'panel2'} onChange={(e, newExpanded) => handleChangeAcordionCompany(e, newExpanded, 'panel2')}>
                                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                                <Typography>Logo rectangle (128x30)</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <ImageUpload
                                                    onUploadImage={(image) => handleChangeCompanyImage('shop_logo_rectangle', image)}
                                                    width={128}
                                                    image={state.logo_rectangle}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion expanded={state.accordion_company_picture_value === 'panel3'} onChange={(e, newExpanded) => handleChangeAcordionCompany(e, newExpanded, 'panel3')}>
                                            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                                <Typography>Cover image (1920x260)</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <ImageUpload
                                                    onUploadImage={(image) => handleChangeCompanyImage('shop_cover_image', image)}
                                                    width={520}
                                                    image={state.cover_image}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion expanded={state.accordion_company_picture_value === 'panel4'} onChange={(e, newExpanded) => handleChangeAcordionCompany(e, newExpanded, 'panel4')}>
                                            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                                <Typography>Cover image mobile (480x220)</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <ImageUpload
                                                    onUploadImage={(image) => handleChangeCompanyImage('shop_cover_image_mobile', image)}
                                                    width={520}
                                                    image={state.cover_image_mobile}
                                                />
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion expanded={state.accordion_company_picture_value === 'panel5'} onChange={(e, newExpanded) => handleChangeAcordionCompany(e, newExpanded, 'panel5')}>
                                            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                                <Typography>Favicon 48x48px, 96x96px, 144x144px ...</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={12}>
                                                        Details about favicons in google search results&nbsp;
                                                        <Link
                                                            href="https://developers.google.com/search/docs/appearance/favicon-in-search"
                                                            target='_blank'
                                                        >
                                                            here
                                                        </Link>
                                                        <Divider />
                                                        To verify site favicon&nbsp;
                                                        <Link
                                                            href="https://realfavicongenerator.net/"
                                                            target='_blank'
                                                        >
                                                            use this site
                                                        </Link>
                                                        <Divider />
                                                        To verify rich results&nbsp;
                                                        <Link
                                                            href="https://search.google.com/test/rich-results"
                                                            target='_blank'
                                                        >
                                                            use this site
                                                        </Link>
                                                        <Divider />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <ImageUpload
                                                            onUploadImage={(image) => handleChangeCompanyImage('shop_favicon', image)}
                                                            width={520}
                                                            image={state.favicon}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Box component="fieldset">
                                            <legend>Extended settings</legend>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <FormControlLabel 
                                                        control={<Switch
                                                                    name="show_price"
                                                                    checked={state.ext_settings.show_price}
                                                                    onChange={(e) => changeState(e, 'ext_settings')}
                                                                    color="primary"
                                                                />} 
                                                        label="Show price" />
                                                    <Tooltip title="If active, product price is displayed in search grid, product details, cart or order.">
                                                        <InfoIcon />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel 
                                                        control={<Switch
                                                                    name="show_sold_by"
                                                                    checked={state.ext_settings.show_sold_by}
                                                                    onChange={(e) => changeState(e, 'ext_settings')}
                                                                    color="primary"
                                                                />} 
                                                        label="Show sold by" />
                                                    <Tooltip title="Useful for marketplaces: if active seller is displayed in product card.">
                                                        <InfoIcon />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel 
                                                        control={<Switch
                                                                    name="seo_no_index"
                                                                    checked={state.ext_settings.seo_no_index}
                                                                    onChange={(e) => changeState(e, 'ext_settings')}
                                                                    color="primary"
                                                                />} 
                                                        label="SEO no index" />
                                                    <Tooltip title="If active, SEO index is disabled: the url: /robots.txt block all entries and for each page in the header the following tag is present: <meta name=&quot;robots&quot; content=&quot;noindex,nofollow&quot;/> ">
                                                        <InfoIcon />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel 
                                                        control={<Switch
                                                                    name="show_product_popup"
                                                                    checked={state.ext_settings.show_product_popup}
                                                                    onChange={(e) => handleChangeExtSettingsShowProductPopup(e)}
                                                                    color="primary"
                                                                />} 
                                                        label="Show product on popup" />
                                                    <Tooltip title="If active the product is shown as popup rather than navigate to other page and change the url.">
                                                        <InfoIcon />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel 
                                                        control={<Switch
                                                                    name="show_product_popup_full"
                                                                    checked={state.ext_settings.show_product_popup_full}
                                                                    onChange={(e) => changeState(e, 'ext_settings')}
                                                                    disabled={!state.ext_settings.show_product_popup}
                                                                    color="primary"
                                                                />} 
                                                        label="Show product on popup full" />
                                                    <Tooltip title="If active, the product popup have all the details as in the product page. If is not active only some details of the product are shown in the popup (for example name, price).">
                                                        <InfoIcon />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel 
                                                        control={<Switch
                                                                    name="use_google_maps"
                                                                    checked={state.ext_settings.use_google_maps}
                                                                    onChange={(e) => changeState(e, 'ext_settings')}
                                                                    color="primary"
                                                                />} 
                                                        label="Use google maps" />
                                                    <Tooltip title={
                                                                    <div style={{ whiteSpace: 'pre-line' }}>
                                                                        {"If active, in address screens google map is shown to visualize the address; also:\n"+
                                                                        "- as the user type the address autocomplete in on;\n "+
                                                                        "- if the map in moved the address is completed using places API for that location\n\n" + 
                                                                        "This option involves additional costs!"}
                                                                    </div>}>
                                                        <InfoIcon />
                                                    </Tooltip>
                                                    <Tooltip title="This option involves additional costs!">
                                                        <MonetizationOnIcon style={{
                                                                    color: red[700],
                                                                }} />
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </TabPanel>
                        <TabPanel value="2">
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Delivery options" />
                                        <Divider />
                                        {state.delivery_options.length > 0 ?
                                            <CardContent>
                                                <Box>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow key={0}>
                                                                <TableCell>
                                                                    Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    Enabled
                                                                </TableCell>
                                                                <TableCell>
                                                                    Price
                                                                </TableCell>
                                                                <TableCell>
                                                                    API Request
                                                                </TableCell>
                                                                <TableCell style={{ minWidth: "100px" }}>
                                                                    Delete
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {state.delivery_options.map((item) => (
                                                                <TableRow
                                                                    hover
                                                                    key={item.id}
                                                                >
                                                                    <TableCell>
                                                                        {item.name}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.enabled ? "Yes" : "No"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.price + " " + item.ccy}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.api_request ? "Yes" : "No"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={styled.buttonsBox}>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                size="small"
                                                                                endIcon={<ModeEditIcon />}
                                                                                onClick={(id) => handleEditDeliveryOptions(item)}
                                                                            >
                                                                                Edit
                                                                            </Button>

                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                size="small"
                                                                                endIcon={<DeleteForeverIcon />}
                                                                                onClick={(id) => handleDeleteDeliveryOptions(item.id, item.name)}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </CardContent>
                                            :
                                            "There are no delivery options. To add delivery options use the button 'ADD DELIVERY OPTIONS'"
                                        }
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(id) => handleAddDeliveryOptions()}
                                    >
                                        Add Delivery Option
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="3">
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <strong>Limited delivery area:</strong>
                                </Grid>
                                <Grid item xs={1}>
                                    <Switch
                                        name="limited_delivery_area"
                                        checked={state.limited_delivery_area}
                                        onChange={(e) => handleChangeLimitedDeliveryArea(e)}
                                        color="primary"
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <strong>Automatic fit bounds:</strong>
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        name="delivery_area_fit_bounds"
                                        checked={state.delivery_area_fit_bounds}
                                        onChange={(e) => changeState(e)}
                                        color="primary"
                                    />
                                </Grid>

                                <Grid item xs={5}>
                                    <Box sx={styled.buttonsBox}>
                                        <Button variant="contained"
                                            color="primary"
                                            onClick={handleNewDeliveryArea}
                                            disabled={!state.limited_delivery_area}>
                                            New delivery area
                                        </Button>
                                        <Button variant="contained"
                                            color="primary"
                                            onClick={handleDeleteDeliveryArea}
                                            disabled={!state.limited_delivery_area}>
                                            Delete delivery area
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <div
                                        ref={onLoadMapDiv}
                                        style={state.div_map_style}>

                                        <LoadScript
                                            id="script-loader"
                                            googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}
                                            language="en"
                                            region="us"
                                        >
                                            <GoogleMap
                                                mapContainerClassName="google-map"
                                                center={state.delivery_area_center}
                                                zoom={12}
                                                onLoad={onLoadMap}
                                            >
                                                <PolygonF
                                                    // Make the Polygon editable / draggable
                                                    editable
                                                    //draggable
                                                    path={state.delivery_area}
                                                    // Event used when manipulating and adding points
                                                    onMouseUp={onEditPolygon}
                                                    // Event used when dragging the whole Polygon
                                                    onDragEnd={onEditPolygon}
                                                    onLoad={onLoadPolygon}
                                                    onUnmount={onUnmountPolygon}
                                                />
                                            </GoogleMap>
                                        </LoadScript>
                                    </div>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="4">
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Banners" />
                                        <Divider />
                                        {state.banners.length > 0 ?
                                            <CardContent>
                                                <Box>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow key={0}>
                                                                <TableCell>
                                                                    Position
                                                                </TableCell>
                                                                <TableCell>
                                                                    Type
                                                                </TableCell>
                                                                <TableCell>
                                                                    Title
                                                                </TableCell>
                                                                <TableCell>
                                                                    Description
                                                                </TableCell>
                                                                <TableCell>
                                                                    Location Id
                                                                </TableCell>
                                                                <TableCell>
                                                                    Enabled
                                                                </TableCell>
                                                                <TableCell style={{ minWidth: "100px" }}>
                                                                    Delete
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {state.banners.map((item) => (
                                                                <TableRow
                                                                    hover
                                                                    key={item.banner_id}
                                                                >
                                                                    <TableCell>
                                                                        {item.position_desc}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.type_desc}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.title}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.description}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.location_id}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.enabled ? "Yes" : "No"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={styled.buttonsBox}>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                size="small"
                                                                                endIcon={<ModeEditIcon />}
                                                                                onClick={(id) => handleEditBanners(item)}
                                                                            >
                                                                                Edit
                                                                            </Button>

                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                size="small"
                                                                                endIcon={<DeleteForeverIcon />}
                                                                                onClick={(id) => handleDeleteBanners(item.banner_id, item.title)}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </CardContent>
                                            :
                                            "There are no banners. To add banners use the button 'ADD BANNER'"
                                        }
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(id) => handleAddBanners()}
                                    >
                                        Add Banner
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="5">
                            <Gallery
                                tag='EditCompany'
                                related_id={state.company_id}
                                company_id={state.company_id}
                                gallery={state.gallery}
                                type={[
                                { value: "company_images", description: "Company images" },    
                                { value: "company_gallery", description: "Company gallery" },
                                { value: "company_blog_images", description: "Company blog images" },
                                { value: "company_pdf_files", description: "Company Pdf files" }]
                                }
                            />
                        </TabPanel>
                        <TabPanel value="6">
                            <Grid container spacing={5}>
                                <Grid item xs={2}>
                                    <strong>Language:</strong>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        name="website_setting_language"
                                        value={languageList ? state.website_setting_language : ''}
                                        input={<Input />}
                                        MenuProps={utils.MenuProps}
                                        onChange={(e) => changeState(e)}
                                    >
                                        {languageList?.map(o =>
                                            <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                                        )}
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        {website_settings_filtered.length > 0 ?
                                            <CardContent>
                                                <Box>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow key={0}>
                                                                <TableCell>
                                                                    Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    Format
                                                                </TableCell>
                                                                <TableCell>
                                                                    Cardinality
                                                                </TableCell>
                                                                <TableCell>
                                                                    Value
                                                                </TableCell>
                                                                <TableCell style={{ minWidth: "100px" }}>
                                                                    Edit / Delete
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {website_settings_filtered.map((item) => (
                                                                <TableRow
                                                                    hover
                                                                    key={item.setting_id}
                                                                >
                                                                    <TableCell>
                                                                        {item.name}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.format === 'html' ?
                                                                            <Tooltip title="Html">
                                                                                <HtmlIcon />
                                                                            </Tooltip>
                                                                            :
                                                                            <Tooltip title="Plain text">
                                                                                <AbcIcon />
                                                                            </Tooltip>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.multiple_elements ?
                                                                            <Tooltip title="Multiple elements">
                                                                                <PlusOneOutlinedIcon />
                                                                            </Tooltip>
                                                                            :
                                                                            <Tooltip title="Single element">
                                                                                <LooksOneOutlinedIcon />
                                                                            </Tooltip>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.multiple_elements ?
                                                                            <>
                                                                                {item.json_values != null && item.json_values.length > 0 ?
                                                                                    <>
                                                                                        {item.format === 'text' ?
                                                                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                                                                <span><b>{item.json_values[0].title}</b></span>
                                                                                                <br />
                                                                                                <span>{item.json_values[0].value}</span>
                                                                                                <br />
                                                                                                <span>...</span>
                                                                                            </div>
                                                                                            :
                                                                                            <HtmlTooltip title={
                                                                                                <div dangerouslySetInnerHTML={{ __html: item.json_values[0].value }}>

                                                                                                </div>
                                                                                            }>
                                                                                                <PreviewIcon />
                                                                                            </HtmlTooltip>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    ""
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {item.text_value != null && item.text_value !== '' ?
                                                                                    <>
                                                                                        {item.format === 'text' ?
                                                                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                                                                {item.text_value}
                                                                                            </div>
                                                                                            :
                                                                                            <HtmlTooltip
                                                                                                title={
                                                                                                    <div dangerouslySetInnerHTML={{ __html: item.text_value }}>

                                                                                                    </div>
                                                                                                }>
                                                                                                <PreviewIcon />
                                                                                            </HtmlTooltip>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                                }

                                                                            </>
                                                                        }


                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={styled.buttonsBox}>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                size="small"
                                                                                endIcon={<ModeEditIcon />}
                                                                                onClick={(id) => handleEditWebsiteSetting(item)}
                                                                            >
                                                                                Edit
                                                                            </Button>

                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                size="small"
                                                                                endIcon={<DeleteForeverIcon />}
                                                                                onClick={(id) => handleDeleteWebsiteSetting(item.id, item.name)}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </CardContent>
                                            :
                                            "Select the language to see website settings."
                                        }
                                    </Card>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>
        )
    }

    return (
        <div>
            <h3>Company management</h3>
            <hr />
            {renderCreateForm()}
        </div>
    );
}

export default EditCompany;