export const Home = () => {    
    return (            
        <figure id="main-img">
            <img src={"/static/background.jpg"} alt="Italian Trulli"></img>
        </figure>
        
        /*
        <div style={{ height: '100%' }}>
            <div className="container-fluid homepage-bgimage"></div>
        </div>
        */
    );    
}