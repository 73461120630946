import * as Models from "../models/Models";
import * as utils from '../utils/Utils';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ReportsState {
    formData: FormData;
}

export class FormData extends Models.SearchPaginationInput {    
    report_type: number = 0;
    start_date: string = "";
    end_date: string = "";
    client_id: number = 0; //de redenumit cand se face primul raport
    description: string = "";
    start_date_search: string = "";
    end_date_search: string = "";
}


const unloadedState: ReportsState = {
    formData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        report_type: 0,
        start_date: utils.getDateISO(),
        end_date: utils.getDateISO(),
        client_id: 0,
        description: "",
        start_date_search: utils.getDateISO(-1),
        end_date_search: utils.getDateISO(),
    },
};


/* new reducer */

const reportsSlice = createSlice({
    name: 'Reports',
    initialState: unloadedState,
    reducers: {
      setReportsFormData(state, action: PayloadAction<FormData>) {
        state.formData = action.payload
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = reportsSlice
  // Extract and export each action creator by name
  export const { setReportsFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer