import moment from 'moment';

export function isNumeric(input: string): boolean {
    if (!input.match(/^[0-9]+$/)) {
        return false;
    }
    return true;
}

export function isAlphaNumericAndUnderscore(input: string): boolean {
    if (!input.match(/^[a-zA-Z0-9_]+$/)) {
        return false;
    }
    return true;
}

export function isUrl(input: string): boolean {
    if (!input.match(/^[a-zA-Z0-9_/]+$/)) {
        return false;
    }
    return true;
}

export function isAlphaNumeric(input: string): boolean {
    if (!input.match(/^[a-zA-Z0-9]+$/)) {
        return false;
    }
    return true;
}

export function isUuid(input: string): boolean {
    if (!input.match(/^[a-zA-Z0-9-]+$/)) {
        return false;
    }
    return true;
}

export function isAlphaNumericAndSpace(input: string): boolean {
    if (!input.match(/^[a-zA-Z0-9\s]+$/)) {
        return false;
    }
    return true;
}

export function isAlphaNumericAndSpaceUnderscore(input: string): boolean {
    if (!input.match(/^[a-zA-Z0-9\s_]+$/)) {
        return false;
    }
    return true;
}


export function isLetters(input: string): boolean {
    if (!input.match(/^[a-zA-Z]+$/)) {
        return false;
    }
    return true;
}


export function isText(input: string): boolean {
    if (!input.match(/^[%-@.,!'\/#&+\w\s]|[à-ü]|[À-Ü]*$/)) {
        return false;
    }
    return true;
}

export function isEmail(input: string): boolean {
    if (!input.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        return false;
    }
    return true;
}

//https://regex101.com/r/j48BZs/2
export function isPhone(input: string): boolean {

    //074 123 1234
    if (input.match(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)) 
    {
        return true;
    }

    //0741 231 234
    if (input.match(/^(\+\d{1,2}\s?)?\(?\d{4}\)?[\s.-]?\d{3}[\s.-]?\d{3}$/)) 
    {
        return true;
    }

    return false;
}


/*
export function isDateISO(input: string): boolean {
    var date: Date;
    var result: boolean;
    try {
        date = new Date(input);
        result = true;
    }
    catch (err)
    {
        result = false;        
    }
    return result;
}
*/

export function isDateISO(date: string): boolean {
    return moment(date, 'YYYY-MM-DD', true).isValid();
}
