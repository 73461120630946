import * as React from 'react';
import { useEffect, useState } from 'react';
import {    
    Grid,    
    Button,
    Box,
    ListItemAvatar,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Select,
    Input,
    MenuItem,
    TextField,
    Tooltip,
    InputAdornment,
    Switch,
    InputLabel,
} from '@mui/material';
import { DeleteForever as DeleteForeverIcon, PictureAsPdf as PictureAsPdfIcon } from '@mui/icons-material';
import * as utils from '../utils/Utils';
import { Attachment, GalleryType } from '../models/Models';
import moment from 'moment';
import { tagType, useDeleteAttachmentMutation, useSaveAttachmentAltMutation, useSaveAttachmentEnabledMutation, useSaveAttachmentMutation, useSaveAttachmentTagMutation } from '../store/apiSlice';


var missingImage = require('../icons/missing-image.jpg');

interface GalleryProps {
    tag: tagType;
    related_id: number;
    type: GalleryType[];
    company_id: number;
    gallery: Attachment[];
}

interface GalleryState {
    filter_attachment_type: string;
    attachment_type: string;
    gallery: Attachment[];
}


export const Gallery : React.FC<GalleryProps> = (props) => {

    const [state, setState] = useState<GalleryState>({
        filter_attachment_type: '-',
        attachment_type: '-',
        gallery: props.gallery.map(x => {return {...x}})
    });

    const [ saveAttachment ] = useSaveAttachmentMutation();
    const [ deleteAttachment ] = useDeleteAttachmentMutation();
    const [ setEnabled ] = useSaveAttachmentEnabledMutation();
    const [ setAlt ] = useSaveAttachmentAltMutation();
    const [ setTag ] = useSaveAttachmentTagMutation();

    useEffect(() => {        
        setState((prevState) => ({ ...prevState, gallery: props.gallery.map(x => {return {...x}}) }))
    }, [props.gallery]);

    const handleDeleteGallery = (id: number, title: number) => {
        if (!window.confirm("Do you want to delete the picture with: " + title))
            return;
        else {
            deleteAttachment( {id, tag: props.tag, tag_id: props.related_id} )
        }
    }

    const handleChangeFilterAttachmentType = (event: any) => {
        setState({ ...state, filter_attachment_type: event.target.value });
    }

    const handleChangeAttachmentType = (event: any) => {
        setState({ ...state, attachment_type: event.target.value });
    }

    const handleUploadGallery = async (event: React.ChangeEvent<HTMLInputElement>) => {

        if (state.attachment_type === '-') {
            alert("Select attachment type");
            event.target.value = "";
            event.target.files = null;
            return;
        }
        if (event != null && event.target != null && event.target.files != null && event.target.files[0] != null) {
            var file = event.target.files[0];
            utils.checkJpegPngSignature(file).then(result => {
                if (result) {
                    const formData = new FormData();
                    formData.append("related_id", props.related_id.toString());
                    formData.append("type", state.attachment_type);
                    formData.append("file", file);
                    formData.append("company_id", props.company_id.toString());

                    saveAttachment({ picture: formData, tag: props.tag, tag_id: props.related_id });
                }
                else {
                    alert("File content is invalid");
                }
            });
        }
    }

    const handleChangeEnabled = (event: React.ChangeEvent<HTMLInputElement>, id: Number) => {

        var gallery_new = state.gallery.map(x => {return {...x}});
        
        var item = gallery_new.find(x => x.attachment_id === id);
        if (item != null) {
            item.enabled = event.target.checked;
            setState({ ...state, gallery: gallery_new });

            setEnabled( { attachemnt: item, tag: props.tag, tag_id: props.related_id });
        }
    }


    const handleChangeAlt = (event: any, id: Number) => {

        var gallery_new = state.gallery.map(x => {return {...x}});
        
        var item = gallery_new.find(x => x.attachment_id === id);
        if (item != null) {
            item.alt = event.target.value;
            setState({ ...state, gallery: gallery_new });
        }
    }

    const handleChangeTag = (event: any, id: Number) => {

        var gallery_new = state.gallery.map(x => {return {...x}});
        
        var item = gallery_new.find(x => x.attachment_id === id);
        if (item != null) {
            item.tag = event.target.value;
            setState({ ...state, gallery: gallery_new });
        }
    }

    const handleSetAlt = (event: any, item: Attachment) => {
        var old_item = props.gallery.find(x => x.attachment_id === item.attachment_id);
        if (old_item && item.alt !== old_item.alt){
            setAlt( { attachemnt: item, tag: props.tag, tag_id: props.related_id });
        }
    }

    const handleSetTag = (event: any, item: Attachment) => {
        var old_item = props.gallery.find(x => x.attachment_id === item.attachment_id);
        if (old_item && item.tag !== old_item.tag){
            setTag( { attachemnt: item, tag: props.tag, tag_id: props.related_id });
        }
    }
    
    return (
        <Grid container spacing={5}>
            <Grid item xs={4}>                            
                <InputLabel shrink id="labelFilterType">
                    Filer by type
                </InputLabel>
                <Select
                    id="AttachmentTypeFilter"
                    value={state.filter_attachment_type}
                    input={<Input />}
                    MenuProps={utils.MenuProps}
                    onChange={handleChangeFilterAttachmentType}                                        
                >
                    <MenuItem key='-' value='-'>All</MenuItem>
                    {props.type.map((item) => (
                        <MenuItem key={item.value} value={item.value}>{item.description}</MenuItem>
                    ))}
                </Select>
            </Grid>
            
            <Grid item xs={12}>
                <Card>
                    <CardHeader title="Gallery" />
                    <Divider />
                    {state.gallery.length > 0 ?
                        <CardContent>
                            <Box>
                                <Table>
                                    <TableHead>
                                        <TableRow key={0}>
                                            <TableCell>
                                                Picture
                                            </TableCell>
                                            <TableCell>
                                                Type
                                            </TableCell>
                                            <TableCell>
                                                Enabled
                                            </TableCell>
                                            <TableCell>
                                                File Name
                                            </TableCell>
                                            <TableCell>
                                                Alt / Tag
                                            </TableCell>
                                            <TableCell>
                                                Url
                                            </TableCell>                                                
                                            <TableCell>
                                                Usage
                                            </TableCell>
                                            <TableCell style={{ minWidth: "100px" }}>
                                                Delete
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.gallery.filter(x => state.filter_attachment_type === '-' || x.type === state.filter_attachment_type).map((item) => (
                                            <TableRow
                                                hover
                                                key={item.attachment_id}
                                            >
                                                <TableCell>
                                                    <Tooltip 
                                                        title={
                                                            <div style={{ whiteSpace: 'pre-line' }}>
                                                                {
                                                                    "attachment_id: " + item.attachment_id +
                                                                    "\ninserted: " + item.ins_user + " at " + moment(item.ins_date).format('DD.MM.YYYY HH:mm:ss') +
                                                                    (item.upd_user != null ? "\nupdated: " + item.upd_user + " at " + moment(item.upd_date).format('DD.MM.YYYY HH:mm:ss') : "") +
                                                                    "\n\nclick to download"
                                                                }
                                                            </div>}>
                                                        <span>
                                                            <Box
                                                                sx={{
                                                                    alignItems: 'center',
                                                                    display: 'flex'
                                                                }}
                                                            >
                                                                <ListItemAvatar>
                                                                    <a href = {item.picture_url} target="_blank" rel="noreferrer">
                                                                        {item.format === '.pdf' ? 
                                                                            <PictureAsPdfIcon/>
                                                                            :
                                                                            <img
                                                                                src={item.picture_url == null ? missingImage : item.picture_url}
                                                                                alt=''
                                                                                style={{
                                                                                    height: 48,
                                                                                    width: 48
                                                                                }}
                                                                            />
                                                                        }
                                                                    </a>
                                                                    
                                                                </ListItemAvatar>
                                                            </Box>
                                                        </span>
                                                    </Tooltip>                                                        
                                                </TableCell>
                                                <TableCell>
                                                    {item.type}
                                                </TableCell>
                                                <TableCell>
                                                    <Switch
                                                        name="enabled"
                                                        checked={item.enabled}
                                                        onChange={(e) => handleChangeEnabled(e, item.attachment_id)}
                                                        color="primary"
                                                    />
                                                </TableCell>                                                
                                                <TableCell>
                                                    {item.file_name + item.format}
                                                </TableCell>
                                                <TableCell style={{minWidth: 300}}>
                                                    <TextField
                                                        name="Alt"
                                                        variant="standard"                                                            
                                                        value={item.alt}
                                                        fullWidth 
                                                        onChange={(e) => handleChangeAlt(e, item.attachment_id)}
                                                        onBlur={(e) => handleSetAlt(e, item)}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">Alt</InputAdornment>,
                                                        }}
                                                    />
                                                    <TextField                                                        
                                                        name="Tag"
                                                        variant="standard"                                                            
                                                        value={item.tag}
                                                        fullWidth 
                                                        onChange={(e) => handleChangeTag(e, item.attachment_id)}
                                                        onBlur={(e) => handleSetTag(e, item)}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">Tag</InputAdornment>,
                                                        }}
                                                    />
                                                </TableCell>                                                
                                                <TableCell>
                                                    {item.picture_url}
                                                </TableCell>
                                                <TableCell>
                                                    {item.usage_count > 0 ? item.usage_count + " times in: " + item.usage_description : "N/A"}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        size="small"
                                                        endIcon={<DeleteForeverIcon />}
                                                        onClick={(id) => handleDeleteGallery(item.attachment_id, item.attachment_id)}
                                                    >
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </CardContent>
                        :
                        "The gallery is empty. To add pictures use the button 'ADD PICTURE'"
                    }
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Select
                    id="AttachmentType"
                    value={state.attachment_type}
                    input={<Input />}
                    MenuProps={utils.MenuProps}
                    onChange={handleChangeAttachmentType}                                        
                >
                    <MenuItem key='-' value='-'>Select attachment type</MenuItem>
                    {props.type.map((item) => (
                        <MenuItem key={item.value} value={item.value}>{item.description}</MenuItem>
                    ))}
                </Select>
                Upload Gallery: <input type="file" accept="image/png, image/jpeg, image/jpg, .svg, application/pdf" onChange={handleUploadGallery} />
            </Grid>
        </Grid>
    );
       
    
}


export default Gallery;