import * as React from 'react';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux'
import * as Models from '../models/Models';
import * as FetchTransactionsStore from '../store/FetchTransactions';
import * as Validator from "../validator/Validator";
import * as Navigation from "../navigation/Navigation";
import { Select, Grid, TextField, Button, MenuItem, Input, InputLabel } from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import moment from 'moment';
import {
    Box,
    Card,
    Dialog,
    Tooltip,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,    
    TableRow,
} from '@mui/material';
import { DialogTitle, DialogContent } from './StyledComponents'
import { Launch as LaunchIcon } from '@mui/icons-material';
import { useChangeState } from '../utils/useChangeState';
import { useGetListCompaniesQuery, useGetListTransactionsStatusQuery, useGetListTransactionsTypeQuery, useGetListsQuery, useGetProductsByCompanyIdQuery, useGetTransactionsQuery } from '../store/apiSlice';
import { RootState, useAppDispatch } from '../store/configureStore';
import { skipToken } from '@reduxjs/toolkit/query';
import { setError } from '../store/ApiInterface';


export interface FetchTransactionsProps {    
    selected_product_id?: number;
    selected_company_id?: number;
}

export const FetchTransactions : React.FC<FetchTransactionsProps> = (props) => {

    const dispatch = useAppDispatch();

    const storeState = useSelector((state: RootState) => state.fetchTransactions)

    const [state, setState] = useState<FetchTransactionsStore.FormData>({
        ...storeState.formData,
        company_id: props.selected_company_id ?? storeState.formData.company_id,
        product_id: props.selected_product_id ?? storeState.formData.product_id,
    });

    const changeState = useChangeState(state, setState);

    const [searchState, setSearchState] = useState<FetchTransactionsStore.FormData>({
        ...storeState.formData,
        company_id: props.selected_company_id ?? storeState.formData.company_id,
        product_id: props.selected_product_id ?? storeState.formData.product_id,
    });


    const { data: transactionType } = useGetListTransactionsTypeQuery({ default_value: 'ALL', default_description: 'All'});
    const { data: transactionStatus } = useGetListTransactionsStatusQuery({ default_value: '0', default_description: 'All'});
    const { data: pageSizeList } = useGetListsQuery('3');
    const { data: companies } = useGetListCompaniesQuery({ default_value: '0', default_description: 'All'});
    const { data: products } = useGetProductsByCompanyIdQuery(state.company_id);
    
    const { data } = useGetTransactionsQuery((transactionType != null && transactionStatus != null && pageSizeList != null && companies != null) ? searchState : skipToken) //asteptam sa vina lista yesNo si pageSize apoi apelam cautarea    


    const handleSearch = (event: any) => {
        event.preventDefault();
        if (handleValidation()){
            setSearchState({ ...state, PageNumber: 0 })
        }
    }

    const handleChangePageSize = (event: any) => {
        if (handleValidation()){
            setState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  })
            setSearchState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  }) //nu avem optiunea de a modifica state-ul si a astepta cu "await" sa fie efectuata modificarea; asa ca apelam schimbarea de pagina in cele 2 state-uri explicit (asta ca sa fie si efectuata apelarea API-ului si sa ramana si in state-ul responsabil de UI)
        }
    }

    const handleChangeCompany = (event: any) => {
        setState({ ...state, company_id: Number(event.target.value), product_id: 0 });
    }

    const handleChangeStartDate = (newValue: Date | null) => {
        setState({ ...state, start_date: utils.formatDate(newValue) });
    }

    const handleChangeEndDate = (newValue: Date | null) => {
        setState({ ...state, end_date: utils.formatDate(newValue) });
    }

    const handleCloseTransactionModal = () => {
        setState((prevState) => ({
            ...prevState,
            show_transaction_modal: false,
            transaction: null,
        }));
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var user_name = state.user_name;

        var StartDate = state.start_date;
        var EndDate = state.end_date;

        if (!Validator.isDateISO(StartDate)) {
            err += "Start Date is invalid!\n";
            result = false;
        }

        if (!Validator.isDateISO(EndDate)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (user_name != null && user_name.length > 100) {
            err += "User Name should not exceed 100 characters!\n";
            result = false;
        }

        if (!result) {
            dispatch(setError(err));
        }
        return result;
    }




    const renderDialog = () => {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                onClose={handleCloseTransactionModal}
                aria-labelledby="customized-dialog-transaction"
                open={state.show_transaction_modal}
            >
                <DialogTitle id="customized-dialog-transaction" onClose={handleCloseTransactionModal}>
                    Transaction
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={5}>
                        <Grid item xs={6}>
                            <Grid container spacing={5}>
                                <Grid item xs={4}>
                                    <strong>Transaction Id:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    {state.transaction?.transaction_id}
                                </Grid>

                                <Grid item xs={4}>
                                    <strong>Type:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    {state.transaction?.type}
                                </Grid>


                                <Grid item xs={4}>
                                    <strong>Order Id:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    {state.transaction?.order_id}
                                </Grid>

                                <Grid item xs={4}>
                                    <strong>Status:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    {state.transaction?.status_name}
                                </Grid>

                                <Grid item xs={4}>
                                    <strong>User Name:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    {state.transaction?.user_name}
                                </Grid>

                                <Grid item xs={4}>
                                    <strong>Created at:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    {moment(state.transaction?.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                </Grid>

                                <Grid item xs={4}>
                                    <strong>Created by:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    {state.transaction?.ins_user}
                                </Grid>

                                {state.transaction?.upd_date ?? (
                                    <>
                                        <Grid item xs={4}>
                                            <strong>Modified at:</strong>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {moment(state.transaction?.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                        </Grid>
                                    </>
                                )}

                                {state.transaction?.upd_user ?? (
                                    <>
                                        <Grid item xs={4}>
                                            <strong>Modified by:</strong>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {state.transaction?.upd_user}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid container spacing={5}>

                                <Grid item xs={4}>
                                    <strong>Product:</strong>
                                </Grid>
                                <Grid item xs={8}>                                        
                                    {state.transaction?.name}
                                </Grid>

                                <Grid item xs={4}>
                                    <strong>Variation:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    {state.transaction?.variation_name}
                                </Grid>

                                <Grid item xs={4}>
                                    <strong>Variation Selected:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    {state.transaction?.variation_title}
                                </Grid>

                                <Grid item xs={4}>
                                    <strong>Sku:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    {state.transaction?.sku}
                                </Grid>

                                <Grid item xs={4}>
                                    <strong>Unit:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    {state.transaction?.unit}
                                </Grid>

                                {state.transaction?.ccy != null && (
                                    <>
                                        <Grid item xs={4}>
                                            <strong>Price:</strong>
                                        </Grid>
                                        <Grid item xs={8}>                                        
                                            {utils.formatPrice({amount: state.transaction?.price, currencyCode: state.transaction?.ccy, locale: 'ro'})}
                                        </Grid>

                                        <Grid item xs={4}>
                                            <strong>Sale Price:</strong>
                                        </Grid>
                                        <Grid item xs={8}>                                        
                                            {utils.formatPrice({amount: state.transaction?.sale_price!, currencyCode: state.transaction?.ccy!, locale: 'ro'})}
                                        </Grid>

                                        <Grid item xs={4}>
                                            <strong>Catalog Price:</strong>
                                        </Grid>
                                        <Grid item xs={8}>                                        
                                            {utils.formatPrice({amount: state.transaction?.catalog_price!, currencyCode: state.transaction?.ccy!, locale: 'ro'})}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }
    

    const renderSearchBox = () => {
        return (
            <Grid container spacing={5}>
                <Grid item xs={2}>
                    <InputLabel shrink id="labelComapny">
                        Company
                    </InputLabel>
                    <Select
                        id="Company"
                        labelId="labelComapny"
                        value={companies? state.company_id : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangeCompany}
                        disabled={props.selected_company_id != null && props.selected_company_id > 0}
                    >
                        {companies?.map(o =>
                            <MenuItem key={o.company_id} value={o.company_id}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>

                <Grid item xs={2}>
                    <InputLabel shrink id="labelProduct">
                        Product
                    </InputLabel>
                    <Select
                        name="product_id"
                        labelId="labelProduct"
                        value={products? state.product_id : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                        disabled={props.selected_product_id != null && props.selected_product_id > 0}
                    >
                        {products?.map(o =>
                            <MenuItem key={o.product_id} value={o.product_id}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>

                

                <Grid item xs={2}>
                    <TextField
                        name="user_name"
                        variant="standard"
                        label="User Name"
                        value={state.user_name}
                        onChange={changeState}
                    />
                </Grid>                      
                
                <Grid item xs={1}>
                    <InputLabel id="labelOrderId">
                        Order Id
                    </InputLabel>
                    <TextField
                        name="order_id"
                        variant="standard"
                        value={state.order_id}
                        onChange={changeState}
                    />
                </Grid>

                <Grid item xs={2}>
                    <InputLabel shrink id="labelTransactionType">
                        Type                                    
                    </InputLabel>
                    <Select
                        name="type"
                        labelId="labelTransactionType"
                        value={transactionType? state.type : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {transactionType?.map(o =>
                            <MenuItem key={o.type} value={o.type}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>

                <Grid item xs={2}>
                    <InputLabel shrink id="labelTransactionStatus">
                        Status
                    </InputLabel>
                    <Select
                        name="status"
                        labelId="labelTransactionStatus"
                        value={transactionStatus? state.status : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {transactionStatus?.map(o =>
                            <MenuItem key={o.status} value={o.status}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>                   
                
                <Grid item xs={1}>
                    <InputLabel shrink id="labelPageSize">
                        Page size
                    </InputLabel>
                    <Select
                        id="PageSize"
                        labelId="labelPageSize"
                        value={pageSizeList? state.PageSize : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangePageSize}
                    >
                        {pageSizeList?.map(o =>
                            <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                        )}
                    </Select>
                </Grid>

                {/* randul 2 */}

                <Grid item xs={2}>
                    <InputLabel id="labelDateStart">
                        Start Date
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            format="dd.MM.yyyy"
                            value={new Date(state.start_date)}
                            onChange={handleChangeStartDate}
                            slotProps={{ textField: { variant: 'standard' } }}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={2}>
                    <InputLabel id="labelDateEnd">
                        End Date
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            format="dd.MM.yyyy"
                            value={new Date(state.end_date)}
                            onChange={handleChangeEndDate}
                            slotProps={{ textField: { variant: 'standard' } }}
                        />
                    </LocalizationProvider>
                </Grid>

                
                <Grid item xs={12}>
                    <Box sx={styled.buttonsBox}>
                        <Button variant="contained" color="primary" onClick={handleSearch}>
                            Search
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        );
    }

    

    const renderSearchResult = useMemo(() => {

        const handleNavigation = (pageNumber: number) => {
            setSearchState((prevState) => ({ ...prevState, PageNumber: pageNumber }));
        }
            
        const handleOpenTransactionModal = (transaction: Models.Transaction) => {
            setState((prevState) => ({
                ...prevState, 
                show_transaction_modal: true,
                transaction: transaction
            }));
        }

        return (
            data &&
                <div>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <fieldset>
                                <legend>Search results</legend>
                                <Card>
                                    <Box>
                                        <Table>
                                            <TableHead>
                                                <TableRow key={0}>
                                                    <TableCell>
                                                        Tran Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Type
                                                    </TableCell>
                                                    <TableCell>
                                                        Order Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Status
                                                    </TableCell>
                                                    <TableCell>
                                                        Name
                                                    </TableCell>
                                                    <TableCell>
                                                        Quantity
                                                    </TableCell>
                                                    <TableCell>
                                                        Price
                                                    </TableCell>
                                                    <TableCell>
                                                        Date
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: "200px" }}>
                                                        
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.Data.map((item) => (
                                                    <TableRow
                                                        hover
                                                        key={item.transaction_id}
                                                    >
                                                        <TableCell>
                                                            {item.transaction_id}
                                                        </TableCell>
                                                        
                                                        <TableCell>
                                                            {item.type}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.order_id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.status_name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.quantity}
                                                        </TableCell>
                                                        <TableCell>
                                                        {utils.formatPrice({amount: item.price, currencyCode: item.ccy, locale: 'ro'})}
                                                        </TableCell>
                                                        <TableCell>
                                                            {moment(item.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Tooltip title="Open transaction">
                                                                <IconButton
                                                                    aria-label="open"
                                                                    size="large"                                                                        
                                                                    onClick={(id) => handleOpenTransactionModal(item)}
                                                                >
                                                                    <LaunchIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </Card>

                                <div className="form-group text-center">
                                    <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.First)} onClick={(id) => handleNavigation(data.First)}>{Navigation.displayNavigationText('First', data.First)}</Button>
                                    <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.Prev)} onClick={(id) => handleNavigation(data.Prev)}>{Navigation.displayNavigationText('Previous', data.Prev)}</Button>
                                    <Button variant="outlined" disabled={true}>{Navigation.displayNavigationText('Current', data.Current)}</Button>
                                    <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.Next)} onClick={(id) => handleNavigation(data.Next)}>{Navigation.displayNavigationText('Next', data.Next)}</Button>
                                    <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.Last)} onClick={(id) => handleNavigation(data.Last)}>{Navigation.displayNavigationText('Last', data.Last)}</Button>
                                </div>
                            </fieldset>
                        </Grid>
                    </Grid>
                </div>
        );
    }, [data])

    return (
        <React.Fragment>
            {props.selected_company_id == null && (<h1 id="tabelLabel">Search Transactions</h1>)}
            {renderDialog()}
            {renderSearchBox()}
            {renderSearchResult}
        </React.Fragment>
    );
}

export default FetchTransactions;