import * as React from 'react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import * as FetchUsersStore from '../store/FetchUsers';
import * as Validator from "../validator/Validator";
import * as Navigation from "../navigation/Navigation";
import { Box, Select, Grid, TextField, Button, MenuItem, Input, InputLabel } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import { useChangeState } from '../utils/useChangeState';
import { useDeleteUserMutation, useGetListCompaniesQuery, useGetListsQuery, useGetListsWithDefaultValueQuery, useGetUsersQuery } from '../store/apiSlice';
import { RootState, useAppDispatch } from '../store/configureStore';
import { skipToken } from '@reduxjs/toolkit/query';
import { setError } from '../store/ApiInterface';


export const FetchUsers : React.FC = (props) => {

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const storeState = useSelector((state: RootState) => state.fetchUsers)
    
    const [state, setState] = useState<FetchUsersStore.FormData>({
        ...storeState.formData,
    });    
    
    const changeState = useChangeState(state, setState);

    const [searchState, setSearchState] = useState<FetchUsersStore.FormData>({
        ...storeState.formData
    });


    const { data: yesNoList } = useGetListsQuery('1');
    const { data: pageSizeList } = useGetListsQuery('3');
    const { data: companies } = useGetListCompaniesQuery({ default_value: '0', default_description: 'All'});
    const { data: userType } = useGetListsWithDefaultValueQuery({ list_id: '5', default_value: '0', default_description: 'All' });

    const { data } = useGetUsersQuery((yesNoList != null && pageSizeList != null && companies != null && userType != null) ? searchState : skipToken) //asteptam sa vina lista yesNo si pageSize apoi apelam cautarea
    const [ deleteData ] = useDeleteUserMutation();
    
    const handleSearch = (event: any) => {
        event.preventDefault();
        if (handleValidation()){
            setSearchState({ ...state, PageNumber: 0 })
        }
    }

    const handleChangePageSize = (event: any) => {
        if (handleValidation()){
            setState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  })
            setSearchState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  }) //nu avem optiunea de a modifica state-ul si a astepta cu "await" sa fie efectuata modificarea; asa ca apelam schimbarea de pagina in cele 2 state-uri explicit (asta ca sa fie si efectuata apelarea API-ului si sa ramana si in state-ul responsabil de UI)
        }
    }

    const handleNewUser = (event: any) => {
        navigate("/user/edit/0");
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var FirstName = state.FirstName;
        var LastName = state.LastName;        
        var UserName = state.UserName;

        if (FirstName != null && FirstName.length > 0 && !Validator.isAlphaNumericAndSpace(FirstName)) {
            err += "First Name should be alpha numetic!\n";
            result = false;
        }
        if (FirstName != null && FirstName.length > 50) {
            err += "First Name should not exceed 50 characters!\n";
            result = false;
        }

        if (LastName != null && LastName.length > 0 && !Validator.isAlphaNumericAndSpace(LastName)) {
            err += "Last Name should be alpha numetic!\n";
            result = false;
        }
        if (LastName != null && LastName.length > 50) {
            err += "Last Name should not exceed 50 characters!\n";
            result = false;
        }

        if (UserName != null && UserName.length > 0 && !Validator.isText(UserName)) {
            err += "User name is not valid!\n";
            result = false;
        }
        if (UserName != null && UserName.length > 100) {
            err += "User name should not exceed 100 characters!\n";
            result = false;
        }

        if (!result) {
            dispatch(setError(err));
        }
        return result;
    }

    const renderSearchBox = () => {
        return (
            <fieldset>
                <legend>Search criteria</legend>
                <Grid container spacing={5}>
                    <Grid item xs={3}>                            
                        <TextField
                            name="FirstName"
                            variant="standard"
                            label="First Name"
                            value={state.FirstName}
                            onChange={changeState}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            name="LastName"
                            variant="standard"
                            label="Last Name"
                            value={state.LastName}
                            onChange={changeState}
                        />
                    </Grid>                        
                    <Grid item xs={3}>
                        <TextField
                            name="UserName"
                            variant="standard"
                            label="User name"
                            value={state.UserName}
                            onChange={changeState}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel shrink id="labelEnabled">
                            Enabled
                        </InputLabel>
                        <Select
                            name="Enabled"
                            labelId="labelEnabled"
                            value={yesNoList? state.Enabled : ''}
                            input={<Input />}
                            MenuProps={utils.MenuProps}
                            onChange={(e) => changeState(e)}
                        >
                            {yesNoList?.map(o =>
                                <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                            )}
                        </Select>
                    </Grid>

                    <Grid item xs={3}>
                        <InputLabel shrink id="labelType">
                            Type
                        </InputLabel>
                        <Select
                            name="Type"
                            labelId="labelType"
                            value={userType? state.Type : ''}
                            input={<Input />}
                            MenuProps={utils.MenuProps}
                            onChange={(e) => changeState(e)}
                        >
                            {userType?.map(o =>
                                <MenuItem key={o.Value} value={o.Value}>{o.Description}</MenuItem>
                            )}
                        </Select>
                    </Grid>                        
                    <Grid item xs={3}>
                        <InputLabel shrink id="labelCompany">
                            Company
                        </InputLabel>
                        <Select
                            name="CompanyId"
                            labelId="labelCompany"
                            value={companies? state.CompanyId : ''}
                            input={<Input />}
                            MenuProps={utils.MenuProps}
                            onChange={(e) => changeState(e)}
                        >
                            {companies?.map(o =>
                                <MenuItem key={o.company_id} value={o.company_id}>{o.name}</MenuItem>
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel shrink id="labelPageSize">
                            Page size
                        </InputLabel>
                        <Select
                            id="PageSize"
                            labelId="labelPageSize"
                            value={pageSizeList? state.PageSize : ''}
                            input={<Input />}
                            MenuProps={utils.MenuProps}
                            onChange={handleChangePageSize}
                        >
                            {pageSizeList?.map(o =>
                                <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styled.buttonsBox}>
                            <Button variant="contained" color="primary" onClick={handleSearch}>
                                Search
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleNewUser} >
                                Create new user
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </fieldset>
        );
    }

    const renderSearchResult = useMemo(() => {

        const handleDelete = (userId: number, userName: string) => {
            if (!window.confirm("Do you want to delete the user: " + userName))
                return;
            else {
                deleteData(userId);
            }
        }
        const handleEdit = (userId: number) => {
            navigate("/user/edit/" + userId);
        }

        const handleNavigation = (pageNumber: number) => {
            setSearchState((prevState) => ({ ...prevState, PageNumber: pageNumber }));
        }

        const columns: GridColDef[] = [
            { field: 'UserName', headerName: 'User Name', flex: 0.1 },
            { field: 'FirstName', headerName: 'First Name', flex: 0.1 },
            { field: 'LastName', headerName: 'Last Name', flex: 0.1 },
            { field: 'Type', headerName: 'Type', flex: 0.1 },
            { field: 'CompanyName', headerName: 'Company Name', flex: 0.1 },
            
            {
                field: 'user_id',
                headerName: 'Edit',
                width: 200,
                renderCell: (params: GridCellParams) => (
                    <span>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(id) => handleEdit(params.row['UserId'] as number)}
                        >
                            Edit
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(id) => handleDelete(params.row['UserId'] as number, params.row['UserName'] || '')}
                        >
                            Delete
                        </Button>
                    </span>
                ),
            },
        ];

        return (
            data &&
                <div>
                    <fieldset>
                        <legend>Search results</legend>
                        <DataGrid autoHeight
                            rows={data.Data}
                            columns={columns}
                            getRowId={(r) => r.UserId}
                            rowCount={data.RowCount}
                            pagination
                            pageSizeOptions={[searchState.PageSize]}
                            paginationMode="server"
                            paginationModel= {{ pageSize: searchState.PageSize, page: searchState.PageNumber }}
                            onPaginationModelChange={(e) => handleNavigation(e.page)}
                        />

                        
                        <div className="form-group text-center">
                            <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.First)} onClick={(id) => handleNavigation(data.First)}>{Navigation.displayNavigationText('First', data.First)}</Button>
                            <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.Prev)} onClick={(id) => handleNavigation(data.Prev)}>{Navigation.displayNavigationText('Previous', data.Prev)}</Button>
                            <Button variant="outlined" disabled={true}>{Navigation.displayNavigationText('Current', data.Current)}</Button>
                            <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.Next)} onClick={(id) => handleNavigation(data.Next)}>{Navigation.displayNavigationText('Next', data.Next)}</Button>
                            <Button variant="outlined" disabled={Navigation.isNavigationDisabled(data.Last)} onClick={(id) => handleNavigation(data.Last)}>{Navigation.displayNavigationText('Last', data.Last)}</Button>
                        </div>
                    </fieldset>
                </div>
        );
    }, [data, deleteData, navigate, searchState.PageSize, searchState.PageNumber])

    return (
        <React.Fragment>
            <h1 id="tabelLabel">Manage users</h1>
            {renderSearchBox()}
            {renderSearchResult}
        </React.Fragment>
    );
}

export default FetchUsers;
